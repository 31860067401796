import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { find } from 'lodash';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import sortBy from 'lodash/sortBy';
import youtubeThumbnail from 'youtube-thumbnail';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import FolderIcon from '@assets/icons/folder.svg';
import NoImage from '@assets/images/no-image.jpeg';
import FsLightbox from 'fslightbox-react';
import AddGalleryVideoModal from '@components/modal/AddGalleryVideoModal';
import LoadingSpinner from '@components/loading/LoadingSpinner';

const Gallery = (props) => {
  const { id, onChangeAction, isValidationForm, onResultValidationForm, onResultNotification, onLoading } = props;
  const { generatePermission } = userPermission();
  const api = new API('v2');

  const [dataPhotos, setDataPhotos] = useState([]);
  const [dataVideos, setDataVideos] = useState({});
  const [selectedSectionVideos, setSelectedSectionVideos] = useState('');
  const [innerViewPhotos, setInnerViewPhotos] = useState([]);
  const [frontViewPhotos, setFrontViewPhotos] = useState([]);
  const [facilityViewPhotos, setFacilityViewPhotos] = useState([]);
  const [profileVideos, setProfileVidoes] = useState([]);
  const [reviewVideos, setReviewVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditPhotos, setIsEditPhotos] = useState(false);
  const [isEditVideos, setIsEditVideos] = useState(false);
  const [hasModalAddVideo, setHasModalAddVideo] = useState(false);
  const [disableFormPhotos, setDisableFormPhotos] = useState(false);
  const [disableFormVideos, setDisableFormVideos] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [isOpenOnMount, setIsOpenOnMount] = useState(false);
  const [galleryLightbox, setGalleryLightbox] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState('front_view');
  const [lightboxSlide, setLightboxSlide] = useState(0);
  const [isDragOverFrontView, setIsDragOverFrontView] = useState(false);
  const [isDragOverInnerView, setIsDragOverInnerView] = useState(false);
  const [isDragOverFacilityView, setIsDragOverFacilityView] = useState(false);
  const [errorPhotosFrontView, setErrorPhotosFrontView] = useState('');
  const [errorPhotosInnerView, setErrorPhotosInnerView] = useState('');
  const [errorPhotosFacilityView, setErrorPhotosFacilityView] = useState('');
  const [loadingPhotosFrontView, setLoadingPhotosFrontView] = useState(false);
  const [loadingPhotosInnerView, setLoadingPhotosInnerView] = useState(false);
  const [loadingPhotosFacilityView, setLoadingPhotosFacilityView] = useState(false);
  const [allowEditPhotoUpload, setAllowEditPhotoUpload] = useState(false);
  const [allowEditPhotoDelete, setAllowEditPhotoDelete] = useState(false);
  const [allowEditPhotoReorder, setAllowEditPhotoReorder] = useState(false);
  const [allowEditVideoUpload, setAllowEditVideoUpload] = useState(false);
  const [allowEditVideoDelete, setAllowEditVideoDelete] = useState(false);
  const [allowEditVideoReorder, setAllowEditVideoReorder] = useState(false);
  const [allowEditPhoto, setAllowEditPhoto] = useState(false);
  const [allowEditVideo, setAllowEditVideo] = useState(false);
  const [allowShowPhoto, setAllowShowPhoto] = useState(false);
  const [allowShowVideo, setAllowShowVideo] = useState(false);

  const allowPermission = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'galleryall'));
  };

  const setAllowPermissionPhoto = () => {
    const showPhoto = allowPermission('galleryimageshow') || allowPermission('galleryimageall');
    const allowPhotoUpload =
      allowPermission('galleryimageeditadd') ||
      allowPermission('galleryimageeditall') ||
      allowPermission('galleryimageall');
    const allowPhotoDelete =
      allowPermission('galleryimageeditremove') ||
      allowPermission('galleryimageeditall') ||
      allowPermission('galleryimageall');
    const allowPhotoReorder =
      allowPermission('galleryimageeditreorder') ||
      allowPermission('galleryimageeditall') ||
      allowPermission('galleryimageall');
    const allowPhoto = allowPhotoUpload || allowPhotoDelete || allowPhotoReorder;

    setAllowShowPhoto(showPhoto);
    setAllowEditPhotoUpload(allowPhotoUpload);
    setAllowEditPhotoDelete(allowPhotoDelete);
    setAllowEditPhotoReorder(allowPhotoReorder);
    setAllowEditPhoto(allowPhoto);
  };

  const setAllowPermissionVideo = () => {
    const showVideo = allowPermission('galleryvideoshow') || allowPermission('galleryvideoall');
    const allowVideoUpload =
      allowPermission('galleryvideoeditadd') ||
      allowPermission('galleryvideoeditall') ||
      allowPermission('galleryvideoall');
    const allowVideoDelete =
      allowPermission('galleryvideoeditremove') ||
      allowPermission('galleryvideoeditall') ||
      allowPermission('galleryvideoall');
    const allowVideoReorder =
      allowPermission('galleryvideoeditreorder') ||
      allowPermission('galleryvideoeditall') ||
      allowPermission('galleryvideoall');
    const allowVideo = allowVideoUpload || allowVideoDelete || allowVideoReorder;

    setAllowShowVideo(showVideo);
    setAllowEditVideoUpload(allowVideoUpload);
    setAllowEditVideoDelete(allowVideoDelete);
    setAllowEditVideoReorder(allowVideoReorder);
    setAllowEditVideo(allowVideo);
  };

  const SortableItemPhoto = SortableElement(({ item, section, keyIndex }) => (
    <div className="box" onClick={() => handlePreviewPhoto(section, keyIndex)}>
      {isEditPhotos && allowEditPhotoDelete && (
        <div className="icon-trash" onClick={() => handleDeletePhoto(section, keyIndex)}></div>
      )}
      <img className={isEditPhotos && allowEditPhotoReorder ? 'all-scroll' : ''} src={item.image_link} />
    </div>
  ));

  const SortableItemVideo = SortableElement(({ item, section, keyIndex }) => {
    const thumbnail = youtubeThumbnail(item.video_link);

    return (
      <div className="box">
        {isEditVideos && allowEditVideoDelete && (
          <div className="icon-trash" onClick={() => handleDeleteVideo(section, keyIndex)}></div>
        )}
        <img className={isEditVideos && allowEditVideoReorder ? 'all-scroll' : ''} src={thumbnail?.high?.url} />
        <h1>{item.title}</h1>
        <h2>
          <a href={item.video_link} target="_blank" rel="noreferrer">
            {item.video_link}
          </a>
        </h2>
      </div>
    );
  });

  function UploadPhotos(props) {
    const { lists, section, dragged, error, process } = props;
    const activePhotos = lists.filter((obj) => !obj.deleted);
    const { getRootProps } = useDropzone({
      multiple: false,
      disabled: disableFormPhotos,
      onDrop: (files) => {
        handleDragOverPhotos(false, section);
        handleDropFilePhotos(files[0], section);
      },
      onDragOver: () => {
        handleErrorNotif('', section);
        handleDragOverPhotos(true, section);
      },
      onDragLeave: () => {
        handleDragOverPhotos(false, section);
      },
      onFileDialogOpen: () => {
        handleErrorNotif('', section);
      },
    });

    return (
      <div>
        {isEditPhotos && allowEditPhotoUpload && activePhotos.length < 10 && (
          <div>
            {!process && (
              <div>
                {dragged && (
                  <div {...getRootProps({ className: 'box-upload drag-over' })}>
                    <img src={FolderIcon}></img>
                    <div>
                      <div className="title mt-3">Release File</div>
                      <div className="detail mt-2">Maximum file size 3MB.</div>
                      <div className="detail">jpeg, jpg, png</div>
                    </div>
                  </div>
                )}
                {!dragged && (
                  <div {...getRootProps({ className: 'box-upload' })}>
                    <img src={FolderIcon}></img>
                    <div>
                      <div className="title mt-3">Click or drag file to this area to upload</div>
                      <div className="detail mt-2">Maximum file size 3MB.</div>
                      <div className="detail">jpeg, jpg, png</div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {process && (
              <div {...getRootProps({ className: 'box-upload' })}>
                <img src={FolderIcon}></img>
                <div>
                  <div className="title mt-3">Please wait...</div>
                  <div className="detail mt-2">Maximum file size 3MB.</div>
                  <div className="detail">jpeg, jpg, png</div>
                </div>
              </div>
            )}

            <div className="invalid-document">{error}</div>
          </div>
        )}
        {!isEditPhotos && activePhotos.length == 0 && (
          <div className="box">
            <img src={NoImage} />
          </div>
        )}
      </div>
    );
  }

  const SortableListPhotos = SortableContainer(({ lists, section }) => {
    return (
      <div className="gallery-container-workshop">
        {lists.map((item, index) => {
          if (!item?.deleted) {
            return (
              <SortableItemPhoto
                disabled={!isEditPhotos || disableFormPhotos || !allowEditPhotoReorder}
                key={Math.random()}
                section={section}
                keyIndex={index}
                index={index}
                item={item}
              />
            );
          }
        })}
        {section == 'front_view' && (
          <UploadPhotos
            lists={lists}
            section={section}
            dragged={isDragOverFrontView}
            error={errorPhotosFrontView}
            process={loadingPhotosFrontView}
          ></UploadPhotos>
        )}

        {section == 'inner_view' && (
          <UploadPhotos
            lists={lists}
            section={section}
            dragged={isDragOverInnerView}
            error={errorPhotosInnerView}
            process={loadingPhotosInnerView}
          ></UploadPhotos>
        )}

        {section == 'facility_view' && (
          <UploadPhotos
            lists={lists}
            section={section}
            dragged={isDragOverFacilityView}
            error={errorPhotosFacilityView}
            process={loadingPhotosFacilityView}
          ></UploadPhotos>
        )}
      </div>
    );
  });

  const SortableListVideos = SortableContainer(({ lists, section }) => {
    const activeVideos = lists.filter((obj) => !obj.deleted);
    return (
      <div className="gallery-container-workshop">
        {lists.map((item, index) => {
          if (!item?.deleted) {
            return (
              <SortableItemVideo
                disabled={!isEditVideos || disableFormVideos || !allowEditVideoReorder}
                key={Math.random()}
                section={section}
                keyIndex={index}
                index={index}
                item={item}
              />
            );
          }
        })}
        <div>
          {isEditVideos && allowEditVideoUpload && activeVideos.length < 5 && (
            <div className="box-upload" onClick={() => handleShowModalAddVideos(section)}>
              <img src={FolderIcon}></img>
              <div>
                <div className="title mt-3">Click this area to upload video</div>
              </div>
            </div>
          )}
          {!isEditVideos && activeVideos.length == 0 && (
            <div className="box">
              <img src={NoImage} />
            </div>
          )}
        </div>
      </div>
    );
  });

  async function getGallery() {
    setLoading(true);

    try {
      const response = await api.get(`v2/intools/workshops/${id}/gallery/`);
      if (response?.data?.data) {
        setDataPhotos(response?.data?.data?.images ?? []);
        setDataVideos(response?.data?.data?.videos ?? {});
      } else {
        setDataPhotos([]);
        setDataVideos({});
      }
      setLoading(false);
    } catch (error) {
      setDataPhotos([]);
      setDataVideos({});
      setLoading(false);
    }
  }

  const handleUploadPhotos = (file, preview, section) => {
    setDisableFormPhotos(true);
    handleLoadingUploadPhotos(true, section);

    if (file?.name) {
      const paramsImagedUpload = new FormData();
      paramsImagedUpload.append('namespace', 'workshops_image');
      paramsImagedUpload.append('identifier', id);
      paramsImagedUpload.append('image', file);

      api
        .post(`v2/intools/image-upload/`, paramsImagedUpload)
        .then((response) => {
          const keyFile = response?.data?.data?.key ?? '';
          if (keyFile !== '') {
            handleAddPhotos(keyFile, preview, section);
            handleLoadingUploadPhotos(false, section);
            setDisableFormPhotos(false);
          } else {
            handleFailUploadPhotos('Gagal upload image', section);
          }
        })
        .catch((e) => {
          const message = `API Error : ${e?.response?.data?.error?.message}`;
          handleFailUploadPhotos(message, section);
        });
    } else {
      handleFailUploadPhotos('Gagal upload image, file tidak diketahui', section);
    }
  };

  const handleFailUploadPhotos = (msg, section) => {
    setDisableFormPhotos(false);
    handleErrorNotif(msg, section);
    handleLoadingUploadPhotos(false, section);
  };

  const handleLoadingUploadPhotos = (status, section) => {
    if (section == 'front_view') {
      setLoadingPhotosFrontView(status);
    }
    if (section == 'inner_view') {
      setLoadingPhotosInnerView(status);
    }
    if (section == 'facility_view') {
      setLoadingPhotosFacilityView(status);
    }
  };

  const handleAddPhotos = (keyFile, preview, section) => {
    const photo = {
      id: null,
      order: null,
      image_link: preview,
      key: keyFile,
      deleted: false,
    };

    if (section == 'front_view') {
      setFrontViewPhotos([...frontViewPhotos, photo]);
    }
    if (section == 'inner_view') {
      setInnerViewPhotos([...innerViewPhotos, photo]);
    }
    if (section == 'facility_view') {
      setFacilityViewPhotos([...facilityViewPhotos, photo]);
    }
  };

  const handleAddVideos = (params) => {
    const videos = {
      id: null,
      order: null,
      video_link: params.link,
      title: params.title,
      deleted: false,
    };

    if (params.section == 'profile') {
      setProfileVidoes([...profileVideos, videos]);
    }
    if (params.section == 'review') {
      setReviewVideos([...reviewVideos, videos]);
    }

    toggleAddVideo();
  };

  const handleActionPhotos = (type) => {
    if (isEditPhotos) {
      if (type == 'cancel') {
        defaultDataPhotos();
        setIsEditPhotos(false);
        handleResultNotification(false);
        if (!isEditVideos) {
          onChangeAction(false, 'gallery');
        }
      } else {
        setDisableFormPhotos(true);
        defaultActionUploadPhotos();
        submitGallery('submit-photo');
      }
    } else {
      setIsEditPhotos(true);
      onChangeAction(true, 'gallery');
    }
  };

  const handleActionVideos = (type) => {
    if (isEditVideos) {
      if (type == 'cancel') {
        defaultDataVideos();
        setIsEditVideos(false);
        handleResultNotification(false);
        if (!isEditPhotos) {
          onChangeAction(false, 'gallery');
        }
      } else {
        setDisableFormVideos(true);
        submitGallery('submit-video');
      }
    } else {
      setIsEditVideos(true);
      onChangeAction(true, 'gallery');
    }
  };

  const handleValidateForm = () => {
    if (isEditPhotos && isEditVideos) {
      defaultActionUploadPhotos();
      setDisableFormPhotos(true);
      setDisableFormVideos(true);
      submitGallery('submit-tab');
    } else if (isEditPhotos) {
      defaultActionUploadPhotos();
      setDisableFormPhotos(true);
      submitGallery('submit-tab');
    } else if (isEditVideos) {
      setDisableFormVideos(true);
      submitGallery('submit-tab');
    }
    onResultValidationForm(true);
  };

  const handleGetPayloadGallery = (type) => {
    let payload = {
      images: [],
      videos: [],
    };
    if (type == 'submit-tab') {
      payload.images = isEditPhotos ? handleGetPayloadPhotos() : [];
      payload.videos = isEditVideos ? handleGetPayloadVideos() : [];
    } else if (isEditVideos && type == 'submit-video') {
      payload.videos = handleGetPayloadVideos();
    } else if (isEditPhotos && type == 'submit-photo') {
      payload.images = handleGetPayloadPhotos();
    }

    return payload;
  };

  const handleGetPayloadPhotos = () => {
    let payload = [];
    let orderFrontView = 0;
    let orderInnerView = 0;
    let orderFacilityView = 0;

    frontViewPhotos?.map((val) => {
      if (val?.id || (!val?.id && !val?.deleted)) {
        orderFrontView += 1;
        payload.push(getObjectPayloadPhotos(val, 'front_view', orderFrontView));
      }
    });

    innerViewPhotos?.map((val) => {
      if (val?.id || (!val?.id && !val?.deleted)) {
        orderInnerView += 1;
        payload.push(getObjectPayloadPhotos(val, 'inner_view', orderInnerView));
      }
    });

    facilityViewPhotos?.map((val) => {
      if (val?.id || (!val?.id && !val?.deleted)) {
        orderFacilityView += 1;
        payload.push(getObjectPayloadPhotos(val, 'facility', orderFacilityView));
      }
    });

    return payload;
  };

  const handleGetPayloadVideos = () => {
    let payload = [];
    let orderProfile = 0;
    let orderReview = 0;

    profileVideos?.map((val) => {
      if (val?.id || (!val?.id && !val?.deleted)) {
        orderProfile += 1;
        payload.push(getObjectPayloadVideos(val, 'profile', orderProfile));
      }
    });

    reviewVideos?.map((val) => {
      if (val?.id || (!val?.id && !val?.deleted)) {
        orderReview += 1;
        payload.push(getObjectPayloadVideos(val, 'review', orderReview));
      }
    });

    return payload;
  };

  const getObjectPayloadPhotos = (data, categoryPhoto, orderPhoto) => {
    let objectPayload = {
      id: data?.id ?? null,
      category: categoryPhoto,
      order: orderPhoto,
      is_deleted: data?.deleted,
    };

    if (data?.key !== '') {
      objectPayload.image_key = data?.key;
    }

    return objectPayload;
  };

  const getObjectPayloadVideos = (data, sectionVideos, orderVideos) => {
    let objectPayload = {
      id: data?.id ?? null,
      section: sectionVideos,
      title: data?.title,
      video_link: data?.video_link,
      order: orderVideos,
      is_deleted: data?.deleted,
    };

    return objectPayload;
  };

  const submitGallery = (type) => {
    const payload = handleGetPayloadGallery(type);
    api
      .put(`v2/intools/workshops/${id}/gallery/`, payload)
      .then((response) => {
        handleResultSubmitGallery(type, true, response);
      })
      .catch((error) => {
        handleResultSubmitGallery(type, false, null);
      });
  };

  const handleResultSubmitGallery = (type, success, response) => {
    let msg = '';

    if (isEditPhotos && type == 'submit-photo') {
      msg = success ? 'Berhasil update foto bengkel' : 'Gagal update foto bengkel';
      if (success) {
        setIsEditPhotos(false);
        setDataPhotos(response?.data?.data?.images ?? []);
      }
      if (!isEditVideos && success) {
        onChangeAction(false, 'gallery');
      }
      setDisableFormPhotos(false);
    }
    if (isEditVideos && type == 'submit-video') {
      msg = success ? 'Berhasil update video bengkel' : 'Gagal update video bengkel';
      if (success) {
        setIsEditVideos(false);
        setDataVideos(response?.data?.data?.videos ?? []);
      }
      if (!isEditPhotos && success) {
        onChangeAction(false, 'gallery');
      }
      setDisableFormVideos(false);
    }
    if (type == 'submit-tab') {
      msg =
        isEditPhotos && isEditVideos && success
          ? 'Berhasil update galeri bengkel'
          : isEditPhotos && isEditVideos && !success
          ? 'Gagal update galeri bengkel'
          : isEditPhotos && success
          ? 'Berhasil update foto bengkel'
          : isEditPhotos && !success
          ? 'Gagal update foto bengkel'
          : isEditVideos && success
          ? 'Berhasil update video bengkel'
          : 'Gagal update video bengkel';

      if (success) {
        setIsEditPhotos(false);
        setIsEditVideos(false);
      }
      setDisableFormPhotos(false);
      setDisableFormVideos(false);
    }

    handleResultNotification(true, success ? 'success' : 'fail', msg);
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const defaultActionUploadPhotos = () => {
    setIsDragOverFacilityView(false);
    setIsDragOverFrontView(false);
    setIsDragOverInnerView(false);
    setErrorPhotosFacilityView('');
    setErrorPhotosFrontView('');
    setErrorPhotosInnerView('');
    setLoadingPhotosFacilityView(false);
    setLoadingPhotosFrontView(false);
    setLoadingPhotosInnerView(false);
  };

  const defaultDataPhotos = () => {
    let frontView = [];
    let innerView = [];
    let facilityView = [];

    if (dataPhotos?.length > 0) {
      dataPhotos?.map((val) => {
        const photo = {
          id: val?.id,
          order: val?.order,
          image_link: val?.image_link,
          key: '',
          deleted: false,
        };

        if (val?.category === 'front_view') frontView.push(photo);
        if (val?.category === 'inner_view') innerView.push(photo);
        if (val?.category === 'facility') facilityView.push(photo);
      });
    }

    frontView = sortBy(frontView, 'order');
    innerView = sortBy(innerView, 'order');
    facilityView = sortBy(facilityView, 'order');

    setFrontViewPhotos(frontView);
    setInnerViewPhotos(innerView);
    setFacilityViewPhotos(facilityView);
    defaultActionUploadPhotos();
  };

  const defaultDataVideos = () => {
    let profile = [];
    let review = [];

    if (dataVideos?.length > 0) {
      dataVideos?.map((val) => {
        const video = {
          id: val?.id,
          order: val?.order,
          video_link: val?.video_link,
          title: val?.title,
          deleted: false,
        };

        if (val?.section === 'profile') profile.push(video);
        if (val?.section === 'review') review.push(video);
      });
    }

    profile = sortBy(profile, 'order');
    review = sortBy(review, 'order');

    setProfileVidoes(profile);
    setReviewVideos(review);
  };

  const handleDropFilePhotos = (data, section) => {
    const file = data;
    const maxSize = 3000;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['jpeg', 'jpg', 'png'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];

    if (nameFileSplit.length == 0) {
      handleErrorNotif('Format file tidak diketahui', section);
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        handleErrorNotif('File tidak sesuai dengan format', section);
      } else {
        if (fileSize == null) {
          handleErrorNotif('Ukuran file tidak diketahui', section);
        } else if (fileSize > maxSize) {
          handleErrorNotif('Ukuran file maksimal 3MB', section);
        } else {
          const previewPhoto = URL.createObjectURL(file);
          handleUploadPhotos(file, previewPhoto, section);
        }
      }
    }
  };

  const handleShowModalAddVideos = (section) => {
    if (isEditVideos && !disableFormVideos) {
      setSelectedSectionVideos(section);
      toggleAddVideo();
    }
  };

  const handleErrorNotif = (error, section) => {
    if (section == 'front_view') {
      setErrorPhotosFrontView(error);
    }
    if (section == 'inner_view') {
      setErrorPhotosInnerView(error);
    }
    if (section == 'facility_view') {
      setErrorPhotosFacilityView(error);
    }
  };

  const handleDragOverPhotos = (status, section) => {
    if (section == 'front_view') {
      setIsDragOverFrontView(status);
    }
    if (section == 'inner_view') {
      setIsDragOverInnerView(status);
    }
    if (section == 'facility_view') {
      setIsDragOverFacilityView(status);
    }
  };

  const handleSortingFrontView = ({ oldIndex, newIndex }) => {
    if (isEditPhotos) {
      const newPositionFrontView = arrayMove(frontViewPhotos, oldIndex, newIndex);
      setFrontViewPhotos(newPositionFrontView);
    }
  };

  const handleSortingInnerView = ({ oldIndex, newIndex }) => {
    if (isEditPhotos) {
      const newPositionInnerView = arrayMove(innerViewPhotos, oldIndex, newIndex);
      setInnerViewPhotos(newPositionInnerView);
    }
  };

  const handleSortingFacilityView = ({ oldIndex, newIndex }) => {
    if (isEditPhotos) {
      const newPositionFacilityView = arrayMove(facilityViewPhotos, oldIndex, newIndex);
      setFacilityViewPhotos(newPositionFacilityView);
    }
  };

  const handleSortingProfile = ({ oldIndex, newIndex }) => {
    if (isEditVideos) {
      const newPositionProfile = arrayMove(profileVideos, oldIndex, newIndex);
      setProfileVidoes(newPositionProfile);
    }
  };

  const handleSortingReview = ({ oldIndex, newIndex }) => {
    if (isEditVideos) {
      const newPositionReview = arrayMove(reviewVideos, oldIndex, newIndex);
      setReviewVideos(newPositionReview);
    }
  };

  const handlePreviewPhoto = (section, index) => {
    if (!isEditPhotos) {
      setGalleryIndex(section);
      setLightboxSlide(index + 1);
      setIsOpenOnMount(true);

      if (section == 'front_view') {
        const previewFrontViewPhotos = frontViewPhotos?.map((image) => image.image_link);
        setGalleryLightbox({ front_view: previewFrontViewPhotos });
      }

      if (section == 'inner_view') {
        const previewInnerViewPhotos = innerViewPhotos?.map((image) => image.image_link);
        setGalleryLightbox({ inner_view: previewInnerViewPhotos });
      }

      if (section == 'facility_view') {
        const previewFacilityViewPhotos = facilityViewPhotos?.map((image) => image.image_link);
        setGalleryLightbox({ facility_view: previewFacilityViewPhotos });
      }

      setShowLightbox(!showLightbox);
    }
  };

  const handleDeletePhoto = (section, index) => {
    if (isEditPhotos && !disableFormPhotos) {
      if (section == 'front_view') {
        frontViewPhotos[index].deleted = true;
        setFrontViewPhotos([...frontViewPhotos]);
      }
      if (section == 'inner_view') {
        innerViewPhotos[index].deleted = true;
        setInnerViewPhotos([...innerViewPhotos]);
      }
      if (section == 'facility_view') {
        facilityViewPhotos[index].deleted = true;
        setFacilityViewPhotos([...facilityViewPhotos]);
      }
    }
  };

  const handleDeleteVideo = (section, index) => {
    if (isEditVideos && !disableFormVideos) {
      if (section == 'profile') {
        profileVideos[index].deleted = true;
        setProfileVidoes([...profileVideos]);
      }
      if (section == 'review') {
        reviewVideos[index].deleted = true;
        setReviewVideos([...reviewVideos]);
      }
    }
  };

  const toggleAddVideo = () => setHasModalAddVideo(!hasModalAddVideo);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (isValidationForm) {
      handleValidateForm();
    }
  }, [isValidationForm]);

  useEffect(() => {
    defaultDataPhotos();
  }, [dataPhotos]);

  useEffect(() => {
    defaultDataVideos();
  }, [dataVideos]);

  useEffect(() => {
    setAllowPermissionPhoto();
    setAllowPermissionVideo();
    getGallery();
  }, []);

  return (
    <React.Fragment>
      <FsLightbox
        toggler={showLightbox}
        sources={galleryLightbox[galleryIndex]}
        type="image"
        openOnMount={isOpenOnMount}
        key={galleryIndex}
        slide={lightboxSlide}
      />
      <AddGalleryVideoModal
        modal={hasModalAddVideo}
        toggle={() => toggleAddVideo()}
        category={selectedSectionVideos}
        onSubmit={handleAddVideos}
      />
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <React.Fragment>
          {allowShowPhoto && (
            <Card className="card-custom">
              <CardBody className="p-0">
                <h3 className="m-0">FOTO</h3>
                <Row className="mt-4">
                  <Col sm={12} md={12}>
                    <h5>Tampak Depan</h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <SortableListPhotos
                      transitionDuration={0}
                      lists={frontViewPhotos}
                      onSortEnd={handleSortingFrontView}
                      axis="xy"
                      distance={1}
                      section="front_view"
                      helperClass="nothing-image"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12} md={12}>
                    <h5>Tampak Dalam</h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <SortableListPhotos
                      transitionDuration={0}
                      lists={innerViewPhotos}
                      onSortEnd={handleSortingInnerView}
                      axis="xy"
                      distance={1}
                      section="inner_view"
                      helperClass="nothing-image"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12} md={12}>
                    <h5>Tampak Umum</h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <SortableListPhotos
                      transitionDuration={0}
                      lists={facilityViewPhotos}
                      onSortEnd={handleSortingFacilityView}
                      axis="xy"
                      distance={1}
                      section="facility_view"
                      helperClass="nothing-image"
                    />
                  </Col>
                </Row>

                {allowEditPhoto && (
                  <Col sm={12} md={12} className="section-action">
                    <Button
                      disabled={disableFormPhotos}
                      className="button-action save"
                      onClick={() => {
                        if (!disableFormPhotos) {
                          handleActionPhotos('save');
                        }
                      }}
                    >
                      {isEditPhotos ? 'Simpan' : 'Edit Data'}
                    </Button>
                    {isEditPhotos && (
                      <Button
                        disabled={disableFormPhotos}
                        className="button-action cancel"
                        onClick={() => {
                          if (!disableFormPhotos) {
                            handleActionPhotos('cancel');
                          }
                        }}
                      >
                        {'Batal'}
                      </Button>
                    )}
                  </Col>
                )}
              </CardBody>
            </Card>
          )}
          {allowShowVideo && (
            <Card className="card-custom">
              <CardBody className="p-0">
                <h3 className="m-0">VIDEO</h3>
                <Row className="mt-4">
                  <Col sm={12} md={12}>
                    <h5>Profil</h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <SortableListVideos
                      transitionDuration={0}
                      lists={profileVideos}
                      onSortEnd={handleSortingProfile}
                      axis="xy"
                      distance={1}
                      section="profile"
                      helperClass="nothing-videos"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12} md={12}>
                    <h5>Review</h5>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <SortableListVideos
                      transitionDuration={0}
                      lists={reviewVideos ?? []}
                      onSortEnd={handleSortingReview}
                      axis="xy"
                      distance={1}
                      section="review"
                      helperClass="nothing-videos"
                    />
                  </Col>
                </Row>
                {allowEditVideo && (
                  <Col sm={12} md={12} className="section-action">
                    <Button
                      disabled={disableFormVideos}
                      className="button-action save"
                      onClick={() => {
                        if (!disableFormVideos) {
                          handleActionVideos('save');
                        }
                      }}
                    >
                      {isEditVideos ? 'Simpan' : 'Edit Data'}
                    </Button>
                    {isEditVideos && (
                      <Button
                        disabled={disableFormVideos}
                        className="button-action cancel"
                        onClick={() => {
                          if (!disableFormVideos) {
                            handleActionVideos('cancel');
                          }
                        }}
                      >
                        {'Batal'}
                      </Button>
                    )}
                  </Col>
                )}
              </CardBody>
            </Card>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Gallery;
