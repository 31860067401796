import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Button, FormGroup, Col, Label } from 'reactstrap';
import API from '@utils/API';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import ActionSwitch from '@components/field/ActionSwitch';
import userPermission from '@utils/userPermission';

const InventoryEdit = (props) => {
  const { modal, toggle, idWorkshop, idInventory, onSubmit } = props;

  const api = new API('v2');
  const { generatePermission } = userPermission();
  const [firstLoad, setFirstLoad] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState();
  const [productOptions, setProductOptions] = useState();
  const [category, setCategory] = useState();
  const [product, setProduct] = useState();
  const [productType, setProductType] = useState('');
  const [productPrice, setProductPrice] = useState();
  const [nettPrice, setNettPrice] = useState();
  const [sellPrice, setSellPrice] = useState();
  const [b2bPrice, setB2bPrice] = useState();
  const [takeRate, setTakeRate] = useState();
  const [useTakeRate, setUseTakeRate] = useState(false);
  const [disableTakeRate, setDisableTakeRate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingFetchData, setLoadingFetchData] = useState(false);

  const allowPermissionB2b = (menu) => {
    return !!(generatePermission('workshop', menu) || generatePermission('workshop', 'inventoryb2ball'));
  };

  const allowB2b = allowPermissionB2b('inventoryb2bshow');

  async function fetchDetailInventory() {
    setLoadingFetchData(true);

    try {
      const response = await api.get(`v2/intools/workshops/${idWorkshop}/inventories/${idInventory}/`);
      defaultData(response?.data?.data ?? {});
      setLoadingFetchData(false);
    } catch (error) {
      defaultData({});
      setLoadingFetchData(false);
    }
  }

  const defaultData = (data) => {
    const categoryData = { value: data?.category_id ?? '', label: data?.category ?? '-' };
    const productData = { value: data?.product_id ?? '', label: data?.product_name ?? '-' };
    const nettPriceData = Helper.formatMoneyOnField('' + data?.nett_price ?? 0, 'Rp ');
    const priceData = Helper.formatMoneyOnField('' + data?.sell_price ?? 0, 'Rp ');
    const b2bPriceData = Helper.formatMoneyOnField('' + data?.base_price ?? 0, 'Rp ');
    setCategoryOptions([categoryData]);
    setProductOptions([productData]);
    setCategory(categoryData);
    setProduct(productData);
    setNettPrice(nettPriceData);
    setSellPrice(priceData);
    setB2bPrice(b2bPriceData);
    setProductType(data?.product_type);
    setProductPrice(data?.product_price ?? 0);
    setUseTakeRate(data?.use_take_rate ? true : false);
    setTakeRate(
      data?.take_rate !== null && data?.take_rate !== undefined && Number(data?.take_rate) >= 0 ? data?.take_rate : null
    );
    setDisableTakeRate(
      data?.take_rate !== null && data?.take_rate !== undefined && Number(data?.take_rate) >= 0 ? false : true
    );
    setFirstLoad(false);
  };

  const handleChangeUseTakeRate = (e) => {
    if (!loading && !disableTakeRate) {
      setUseTakeRate(e?.target?.checked ? true : false);
    }
  };

  const calculateNettPrice = () => {
    if (useTakeRate && takeRate !== null && takeRate !== undefined && Number(takeRate) >= 0) {
      const percentage_take_rate = Number(100 - takeRate) / 100;
      const nett_price = Math.round(percentage_take_rate * Number(sellPrice?.formatMoney ?? 0));

      setNettPrice(Helper.formatMoneyOnField('' + nett_price, 'Rp '));
    } else {
      setNettPrice(Helper.formatMoneyOnField('' + 0, 'Rp '));
    }
  };

  async function submitForm() {
    toggle();
    setLoading(true);

    try {
      const payload = {
        base_price: b2bPrice?.formatMoney ?? 0,
        sell_price: sellPrice?.formatMoney ?? 0,
        nett_price: nettPrice?.formatMoney ?? 0,
        use_take_rate: useTakeRate ? true : false,
        take_rate: takeRate == undefined ? null : takeRate,
      };
      await api.put(`v2/intools/workshops/${idWorkshop}/inventories/${idInventory}/`, payload);
      onSubmit(true);
      setLoading(false);
    } catch (error) {
      onSubmit(false, error?.response?.data?.error?.message ?? '');
      setLoading(false);
    }
  }

  const resetData = () => {
    setProductOptions();
    setCategoryOptions();
    setCategory();
    setProduct();
    setProductPrice();
    setNettPrice();
    setSellPrice();
    setTakeRate();
    setUseTakeRate(false);
    setDisableTakeRate(true);
    setFirstLoad(true);
  };

  const cancel = () => {
    toggle();
  };

  useEffect(() => {
    if (useTakeRate !== undefined && sellPrice !== undefined && !firstLoad) {
      calculateNettPrice();
    }
  }, [useTakeRate, sellPrice]);

  useEffect(() => {
    resetData();
    if (modal) {
      fetchDetailInventory();
    }
  }, [modal]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Edit {productType}</div>
        {!loadingFetchData && (
          <div className="content">
            <FormGroup row>
              <Label lg={4}>Category</Label>
              <SelectField
                colWidth={8}
                name="category"
                placeholder="Category"
                closeMenuOnSelect={true}
                value={category}
                options={categoryOptions}
                disabled={true}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={4} className="label-required">
                {productType} Name
              </Label>
              <SelectField
                colWidth={8}
                name="product"
                placeholder="Product Name"
                closeMenuOnSelect={true}
                value={product}
                options={productOptions}
                disabled={true}
                onChange={(e) => {
                  setProduct(e);
                }}
              />
            </FormGroup>
            {allowB2b && (
              <FormGroup row>
                <Label lg={4}>B2B Sell Price</Label>
                <InputField
                  colWidth={8}
                  inputType={'text'}
                  placeholder="B2B Sell Price"
                  value={b2bPrice?.formatMoneyString ?? ''}
                  onChange={(e) => {
                    let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                    setB2bPrice(convertMoney?.formatMoney > 99999999 ? b2bPrice : convertMoney);
                  }}
                />
              </FormGroup>
            )}
            <FormGroup row className="mb-0">
              <Label lg={4}>
                {takeRate !== null && takeRate !== undefined ? `Take Rate (${takeRate}%)` : `Take Rate`}
              </Label>
              <Col lg={8} className="p-2">
                <ActionSwitch
                  className="action-switch"
                  color="secondary"
                  inputName={`is_take_rate`}
                  isChecked={useTakeRate ? true : false}
                  editableForm={loading || disableTakeRate}
                  onChange={handleChangeUseTakeRate}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>B2C Sell Price</Label>
              <InputField
                colWidth={8}
                inputType={'text'}
                disabled={loading}
                placeholder="B2C Sell Price"
                value={sellPrice?.formatMoneyString ?? ''}
                onChange={(e) => {
                  let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                  setSellPrice(convertMoney?.formatMoney > 99999999 ? sellPrice : convertMoney);
                }}
              />
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>Nett Price</Label>
              <InputField
                colWidth={8}
                inputType={'text'}
                disabled={loading || useTakeRate}
                placeholder="Nett Price"
                value={nettPrice?.formatMoneyString ?? ''}
                onChange={(e) => {
                  if (!loading && !useTakeRate) {
                    let convertMoney = Helper.formatMoneyOnField(e.target.value, 'Rp ');
                    setNettPrice(convertMoney?.formatMoney > 99999999 ? nettPrice : convertMoney);
                  }
                }}
              />
            </FormGroup>
          </div>
        )}

        <div className="action mb-3 mt-3">
          <Button className="button-cancel" onClick={cancel} disabled={loading || loadingFetchData}>
            Batal
          </Button>
          <Button className="button-action" onClick={submitForm} disabled={loading || loadingFetchData}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InventoryEdit;
