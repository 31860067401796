const config = {
  api_url: process.env.REACT_APP_API_URL || 'https://api.backoffice.stg.otoklix.com/',
  api_url_v2: process.env.REACT_APP_API_URL_V2 || 'https://api.stg.otoklix.com/',
  webapp_api_url: process.env.REACT_APP_WEBAPP_API_URL || 'https://api.web.stg.otoklix.com/',
  api_search: process.env.REACT_APP_API_URL_SEARCH || 'https://api.stg.otoklix.com/',
  app_environment: process.env.REACT_APP_ENV || 'staging',
  app_version: process.env.REACT_APP_VERSION || '7.2.1',
  posthog_key: process.env.REACT_APP_PUBLIC_POSTHOG_KEY || 'phc_k9Xkff7spE60WDX0FUM4pIfM5hy4UQmOAKd5OBvc78I',
  posthog_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  url_data_studio:
    process.env.REACT_APP_URL_DATA_STUDIO ||
    'https://lookerstudio.google.com/embed/reporting/145cff6f-14f3-405f-ae65-5b241bc09e0d/page/p_c2uzl7mv6c',
  socket_url_aida:
    process.env.REACT_APP_SOCKET_URL_AIDA || 'wss://otopilot-api-intools.apps.okd.prod.otoklix.com/assistant',
};

export default config;
