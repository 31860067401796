// ComparePriceModal.js
import React, { useState } from 'react';
import { Modal, ModalBody, Button, Row, ModalHeader, ModalFooter } from 'reactstrap';
import ComparePriceContent from '../compare-price/ComparePriceContent';
import closeIcon from '@assets/icons/close.svg';
import '../../styles/compare_price_modal.scss';

const ComparePriceModal = (props) => {
  const [modal, setModal] = useState(false);
  const {
    handleMultipleWorkshopAddCart,
    setSelectedWorkshop,
    cart,
    multipleCalculateCartData,
    selectedWorkshops,
    setSelectedWorkshops,
    tempBookingData,
    setIsSelectedWorkshopPrice,
  } = props;

  const toggle = () => setModal(!modal);

  const hasNonEmptyValue =
    cart &&
    typeof cart === 'object' &&
    Object.values(cart).some((value) => (Array.isArray(value) ? value.length > 0 : !!value));

  const isDisabled = selectedWorkshops.length <= 0 || !hasNonEmptyValue;

  const closeButton = <img src={closeIcon} height={16} alt="Close" onClick={toggle} style={{ cursor: 'pointer' }} />;

  return (
    <div>
      {!isDisabled && (
        <Button id="create-booking" color="blue" onClick={toggle}>
          Perbandingan Harga
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} centered size="xl">
        <ModalHeader
          toggle={toggle}
          style={{ backgroundColor: 'white', height: '60px' }}
          cssModule={{ 'modal-title': 'w-100 text-center' }}
          close={closeButton}
          className="modal-header-compare-workshop modal-header-custom-compare-workshop modal-title-compare-workshop"
        >
          <div className="justify-content-center">
            <p style={{ fontWeight: 700, fontSize: 20 }}>Perbandingan Harga</p>
          </div>
        </ModalHeader>
        <ModalBody className="modal-body-scroll-compare-workshop modal-content-compare-workshop modal-body-compare-workshop">
          <ComparePriceContent
            setIsSelectedWorkshopPrice={setIsSelectedWorkshopPrice}
            tempBookingData={tempBookingData}
            setSelectedWorkshops={setSelectedWorkshops}
            multipleCalculateCartData={multipleCalculateCartData}
            cart={cart}
            handleMultipleWorkshopAddCart={handleMultipleWorkshopAddCart}
            setSelectedWorkshop={setSelectedWorkshop}
            selectedWorkshops={selectedWorkshops}
            toggle={toggle}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ComparePriceModal;
