import Helper from '@helpers/Helper';
import { map } from 'lodash';
import ParseItemCart from '../../utils/ParseItemCart';

export default class EstimationPriceCompareWorkshop {
  static create(tempBookingData, dataSummary, workshopId) {
    const contenHeader = this.headerContent(tempBookingData);
    const contentBody = this.bodyContent(dataSummary, workshopId);
    const contentFooter = this.footerContent();

    return contenHeader + contentBody + contentFooter;
  }

  static headerContent(dataBooking) {
    // const splitTime = dataBooking?.booking_time ? dataBooking?.booking_time.split('.') : [];
    // const bookingTime = splitTime?.length == 2 ? `${splitTime[0]}:${splitTime[1]}` : '';
    // let bookingSchedule = dataBooking?.booking_date ?? '';
    // bookingSchedule += bookingTime ? ' - ' + bookingTime : '';
    let carType = dataBooking?.carDetails?.car_details?.car_model?.brand?.name
      ? dataBooking?.carDetails?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += dataBooking?.carDetails?.car_details?.car_model?.model_name
      ? dataBooking?.carDetails?.car_details?.car_model?.model_name + ' '
      : '';
    carType += dataBooking?.carDetails?.car_details?.variant ? dataBooking?.carDetails?.car_details?.variant : '';

    let headerText = '';
    headerText += `*Estimasi Biaya Pengerjaan*\n\n`;
    headerText += `*Kode Booking* : ${dataBooking?.bookingCode ?? '-'}`;
    headerText += `\n*Jadwal Booking* : ${!dataBooking?.bookingDateTime ? '-' : dataBooking?.bookingDateTime}`;
    headerText += `\n*Pilihan Lokasi* : ${dataBooking?.workshop?.name ?? '-'}`;
    headerText += `\n*Gmaps Link* : ${dataBooking?.workshop?.gmaps_link ?? '-'}`;
    headerText += `\n*Nama Customer* : ${dataBooking?.customer?.username ?? '-'}`;
    headerText += `\n*Jenis Mobil* : ${!carType ? '-' : carType}`;
    headerText += `\n*Nomor Plat* : ${dataBooking?.carDetails?.license_plate ?? '-'}`;

    return headerText;
  }

  static bodyContent(dataSummary, workshopId) {
    let bodyText = `\n`;
    const priceDetail = ParseItemCart.parsePriceDetail(dataSummary, workshopId);
    const packages = ParseItemCart.parsePackage(dataSummary, workshopId);
    const productsServices = ParseItemCart.parseProductService(dataSummary, workshopId);
    const products = productsServices.product;
    const services = productsServices.service;

    console.log(services, 'serrvvv');

    if (packages?.length > 0) {
      bodyText += '\n\n*Paket*';
      var subtotalPackage = 0;
      map(packages, (item) => {
        subtotalPackage += item?.subtotal ?? 0;
        bodyText += `\n\n${item?.name ?? '-'} Rp ${Helper.formatMoney(item?.subtotal ?? 0)}`;
        const voucherAmount = item?.subtotal_voucher > 0 ? `Rp ${Helper.formatMoney(item?.subtotal_voucher)}` : '';
        const voucherData = `(${item?.package_items?.promo_code} ${
          item?.package_items?.voucher_percentage
            ? `${item?.package_items?.voucher_percentage}% ${voucherAmount}`
            : `Rp ${Helper.formatMoney(item?.package_items?.voucher_amount)}`
        })`;
        if (item?.package_items?.promo_code) {
          bodyText += `\n${voucherData}`;
        }
        map(item?.package_items, (subitem) => {
          bodyText += `\n${subitem?.qty ?? '-'} x ${subitem?.name ?? '-'}`;
        });
        bodyText += `${item?.package_items?.note ? '\nKeterangan : ' + item?.package_items?.note ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Paket Rp ${Helper.formatMoney(subtotalPackage)}*`;
    }

    if (products?.items?.length > 0) {
      bodyText += '\n\n*Produk*';
      map(products?.items, (item) => {
        bodyText += `\n\n${item?.name ?? '-'} Rp ${Helper.formatMoney(item?.final_sell_price ?? 0)}`;
        bodyText += `\n${item?.qty ?? '-'} x Rp ${Helper.formatMoney(
          item?.markup_price ?? 0
        )} - Rp ${Helper.formatMoney(item?.discount_amount ?? 0)}`;
        const voucherAmount = item?.voucher_amount > 0 ? `Rp ${Helper.formatMoney(item?.voucher_amount)}` : '';
        const voucherData = `(${item?.promo_code} ${
          item?.voucher_percentage
            ? `${item?.voucher_percentage}% ${voucherAmount}`
            : `Rp ${Helper.formatMoney(item?.voucher_amount)}`
        })`;
        if (item?.promo_code) {
          bodyText += `\n${voucherData}`;
        }
        bodyText += `${item?.remark ? '\nKeterangan : ' + item?.remark ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Produk Rp ${Helper.formatMoney(products?.subtotal)}*`;
    }

    if (services?.items?.length > 0) {
      bodyText += '\n\n*Jasa*';
      map(services?.items, (item) => {
        bodyText += `\n\n${item?.name ?? '-'} Rp ${Helper.formatMoney(item?.final_sell_price ?? 0)}`;
        bodyText += `\n${item?.qty ?? '-'} x Rp ${Helper.formatMoney(
          item?.markup_price ?? 0
        )} - Rp ${Helper.formatMoney(item?.discount_amount ?? 0)}`;
        const voucherAmount = item?.voucher_amount > 0 ? `Rp ${Helper.formatMoney(item?.voucher_amount)}` : '';
        const voucherData = `(${item?.promo_code} ${
          item?.voucher_percentage
            ? `${item?.voucher_percentage}% ${voucherAmount}`
            : `Rp ${Helper.formatMoney(item?.voucher_amount)}`
        })`;
        if (item?.promo_code) {
          bodyText += `\n${voucherData}`;
        }
        bodyText += `${item?.remark ? '\nKeterangan : ' + item?.remark ?? '' : ''}`;
      });
      bodyText += `\n*Subtotal Jasa Rp ${Helper.formatMoney(services?.subtotal)}*`;
    }

    bodyText += `\n\n*Subtotal Rp ${Helper.formatMoney(priceDetail?.price_before_tax ?? 0)}*`;
    bodyText += `\n*Total Diskon Rp ${Helper.formatMoney(priceDetail?.discount ?? 0)}*`;
    bodyText += `\n*PPN Rp ${Helper.formatMoney(priceDetail?.tax ?? 0)}*`;
    bodyText += `\n*PPH Rp ${Helper.formatMoney(priceDetail?.service_tax ?? 0)}*`;
    bodyText += `\n----------------------------`;
    bodyText += `\n*Total Bayar Rp ${Helper.formatMoney(priceDetail?.price_after_tax ?? 0)}*`;
    bodyText += `\n----------------------------`;

    return bodyText;
  }

  static footerContent() {
    let footerText = '';
    footerText += `\n\n\n*Syarat & Ketentuan:*`;
    footerText += `\n1. Biaya pengerjaan yang tercantum merupakan taksiran dan dapat berubah sesuai dengan hasil pengecekan di Bengkel.`;
    footerText += `\n2. Jika pada Surat Perintah Kerja atau Work Order mencantumkan harga pada estimasi, maka Otoklix akan menunggu penerbitan SPK maksimal 2 hari dari tanggal estimasi diberikan.`;
    footerText += `\n3. Jika dalam waktu 14 hari setelah SPK terbit dan belum ada kedatangan dari unit kendaraan maka Otoklix berhak menerbitkan invoice tanpa kedatangan customer.`;
    footerText += `\n4. Apabila Perusahaan memutuskan untuk membatalkan pengerjaan setelah dilakukan pengecekan, maka biaya yang timbul dari proses pengecekan akan ditagihkan kepada Perusahaan.`;
    footerText += `\n5. Pengerjaan yang diperintahkan oleh Perusahaan kepada Bengkel di luar estimasi dan tanpa sepengetahuan Otoklix menjadi tanggung jawab penuh Perusahaan dengan Bengkel.`;
    footerText += `\n6. Harap tidak meninggalkan barang berharga di dalam mobil. Otoklix tidak bertanggung jawab atas segala kehilangan dan/atau kerusakan barang berharga yang terjadi saat pengerjaan.`;
    footerText += `\n7. Syarat & ketentuan selengkapnya mengenai pembayaran servis diatur dalam invoice resmi.`;
    footerText += `\n8. Dokumen ini bersifat rahasia dan tidak boleh disebarluaskan kepada pihak manapun.`;

    return footerText;
  }

  static createWorkshopPrice(tempBookingData, dataSummary, workshopId) {
    const contentHeader = this.headerContentWorkshop(tempBookingData);
    const contentBody = this.bodyContentWorkshop(dataSummary, workshopId);
    const contentFooter = this.footerContentWorkshop();

    return contentHeader + contentBody + contentFooter;
  }

  static headerContentWorkshop(data) {
    let headerText = '';
    let carType = data?.carDetails?.car_details?.car_model?.brand?.name
      ? data?.carDetails?.car_details?.car_model?.brand?.name + ' '
      : '';
    carType += data?.carDetails?.car_details?.car_model?.model_name
      ? data?.carDetails?.car_details?.car_model?.model_name + ' '
      : '';
    carType += data?.carDetails?.car_details?.variant ? data?.carDetails?.car_details?.variant : '';

    headerText += `*Konfirmasi Stock, Layanan & Harga*\n\n`;
    headerText += `*Kode Booking* : ${data?.bookingCode ?? '-'}`;
    headerText += `\n*Jadwal Booking* : ${!data?.bookingDateTime ? '-' : data?.bookingDateTime}`;
    headerText += `\n*Bengkel* : ${data?.workshop?.name ?? '-'}`;
    headerText += '\n';
    headerText += `\n*Jenis Mobil* : ${carType ?? '-'}`;
    headerText += `\n*Nomor Plat* : ${data?.carDetails?.license_plate ?? '-'}`;
    headerText += '\n';
    headerText += `\nOtoklix tidak bertanggung jawab atas pengerjaan penggantian sparepart atau servis diluar permintaan pada Surat Perintah Kerja Otoklix.`;
    headerText += '\n';
    headerText += `\n_Mohon untuk dicek harganya (sebelum PPN) dan dilengkapi jika masih kosong oleh pihak Bengkel :_`;

    return headerText;
  }

  static bodyContentWorkshop(data, workshopId) {
    const allItems = ParseItemCart.parseItems(data, workshopId);

    let bodyText = `\n`;
    var i = 1;
    allItems.forEach((item) => {
      bodyText += `\n ${i}. ${item?.name} (qty ${item?.qty}) : ${
        item?.is_wi
          ? `Rp ${Helper.formatMoney(item?.subtotal)}`
          : !item?.is_wi && item?.is_override
          ? `Rp ${Helper.formatMoney(item?.subtotal)}`
          : item?.package_id
          ? `Rp ${Helper.formatMoney(item?.subtotal)}`
          : item?.is_custom
          ? `Rp ${Helper.formatMoney(item?.subtotal)}`
          : 'Rp ?'
      }`;
      item?.items?.forEach((item_detail) => {
        bodyText += `\n\t${item_detail?.name} (qty ${item_detail?.qty})`;
      });
      if (item?.note && item?.note !== '') {
        bodyText += `\n${item?.product_id ? item?.note : ''}`;
      }
      bodyText += `\n`;
      i++;
    });

    return bodyText;
  }

  static footerContentWorkshop() {
    let footerText = '';
    footerText += `\n\nOtoklix tidak bertanggung jawab atas pengerjaan penggantian sparepart atau servis diluar permintaan pada Surat Perintah Kerja Otoklix.`;

    return footerText;
  }
}
