import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Collapse, Col, Row, Spinner } from 'reactstrap';
import otobuddyIcon from '@assets/icons/otobuddy.svg';
import SideButton from '@components/chat-aida/SideButton';
import InputChat from '@components/chat-aida/InputChat';
import { resetAuth, getUsername } from '@reducers/Auth';
import moment from 'moment';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import BubbleChat from './BubbleChat';
import config from '../../utils/config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const WrapperChat = (props) => {
  const { handleOpenChat } = props;
  const dispatch = useDispatch();

  const username = useSelector(getUsername);

  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState(null);
  const [socketData, setSocketData] = useState();
  const [connected, setConnected] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messageList, setMesageList] = useState([]);
  const [inputHeight, setInputHeight] = useState(120);

  const formattedTime = (timeStamp) => {
    const momentObj = moment.utc(timeStamp);
    const adjustedMoment = momentObj.add(7, 'hours');
    const formattedTime = adjustedMoment.format('HH:mm');
    return formattedTime;
  };

  const formattedDate = (timeStamp) => {
    const momentObj = moment(timeStamp);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    if (momentObj.isSame(today, 'd')) {
      return 'Today';
    } else if (momentObj.isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      const formattedDate = momentObj.format('DD MMM YYYY');
      return formattedDate;
    }
  };

  const scrollToBottom = () => {
    const element = document.getElementById('bottom-chat');
    if (element) {
      element.scrollIntoView({ behavior: 'instant' });
    }
  };

  const jwtToken = cookies.get('intools_jwt_token');
  const checkTokenExpiration = (token) => {
    const decodedToken = jwt_decode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();
    if (expirationTime < currentTime) {
      return true;
    } else {
      return false;
    }
  };

  const connectWebSocket = () => {
    if (socket !== null) {
      socket.on('connect', () => {
        setSocketData(socket);
        setConnected(socket?.connected);
        console.log('Connected to WebSocket server');
      });

      socket.on('error', (data) => {
        console.log('error', data);
      });

      socket.on('typing', (data) => {
        if (data?.state === 'BEGIN') {
          setIsTyping(true);
        } else {
          setIsTyping(false);
        }
      });

      socket.on('ready', (data) => {
        setLoading(false);
        if (data?.chat_histories.length > 0) {
          setMesageList(data?.chat_histories);
        }
      });
    }
  };

  const checkDisconnected = () => {
    if (socket !== null) {
      if (socket?.connected) {
        setConnected(socket?.connected);
      }
      socket.on('disconnect', () => {
        console.log('Disconnect to WebSocket server');
      });
    }
  };

  const toggleCollapse = () => {
    setCollapse(!collapse);
    handleOpenChat(!collapse);
    scrollToBottom();
  };

  const sendMessage = (e) => {
    socket.emit('message', e);
    const tempChatPayload = {
      from_me: true,
      text: `<pre>${e}</pre>`,
      sender: username,
      sent_on: new Date(),
    };
    setMesageList((prevMessages) => [...prevMessages, tempChatPayload]);
  };

  const onSend = (e) => {
    sendMessage(e);
  };

  const receivedMessage = () => {
    if (socket !== null) {
      socket.on('message', (data) => {
        setMesageList((prevMessages) => [...prevMessages, data]);
      });
    }
  };

  const renderDate = (date) => {
    return <span className="d-flex justify-content-center chat-date">{date}</span>;
  };

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('intools_tok_c');
    dispatch(resetAuth());
  };

  const checkJwtToken = (token) => {
    if (token === undefined) {
      handleLogout();
    } else {
      const expired = checkTokenExpiration(token);
      if (expired) {
        handleLogout();
      }
    }
  };

  const disconnectWs = () => {
    if (socket !== null) {
      socket.disconnect();
      setSocket(null);
      setConnected(false);
      setLoading(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
    if (!collapse && !socket !== null) disconnectWs();
  }, [collapse]);

  useEffect(() => {
    scrollToBottom();
    connectWebSocket();
  }, [messageList]);

  useEffect(() => {
    receivedMessage();
    connectWebSocket();
  }, [socket]);

  useEffect(() => {
    checkDisconnected();
  }, [socketData, socket]);

  useEffect(() => {
    scrollToBottom();
  }, [isTyping]);

  useEffect(() => {
    checkJwtToken(jwtToken);
  }, [jwtToken]);

  useEffect(() => {
    if (collapse && !connected) {
      setLoading(true);
      const getSocket = io(config.socket_url_aida, {
        path: '/ws',
        transports: ['websocket'],
        auth: {
          jwt: jwtToken,
        },
      });
      setSocket(getSocket);
    }
  }, [collapse, connected]);

  useEffect(() => {
    connectWebSocket();
  }, []);

  let groupedData = {};
  messageList.forEach((item) => {
    const date = formattedDate(item.sent_on);
    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date].push(item);
  });

  return (
    <div className="pt-2 wrapper-chat-aida" style={{ width: collapse ? '35%' : '0%', transition: '0.5s' }}>
      <Collapse isOpen={collapse} horizontal={true}>
        <Card className="card-aida">
          <CardHeader className="text-white d-flex">
            <img src={otobuddyIcon} height={23} width={23} alt="AIDA" className="mr-3" />
            Otopilot
            {connected ? <div className="aida-connected" /> : <div className="aida-disconnected" />}
          </CardHeader>
          <CardBody>
            <Row
              className="body-chat-aida"
              style={{ height: `calc(100% - ${inputHeight}px)`, maxHeight: '280px !important' }}
            >
              {loading ? (
                <>
                  <Col md={12} className="d-flex align-items-center justify-content-center">
                    <Spinner />
                  </Col>
                </>
              ) : (
                <>
                  {Object.entries(groupedData).map((data, key) => (
                    <>
                      <Col md={12} className="imessage">
                        {renderDate(data[0])}
                        {data[1].map((item) => (
                          <BubbleChat
                            key={item?.uid}
                            type={item.from_me ? 'human' : 'ai'}
                            message={item.text}
                            timeStamp={formattedTime(item?.sent_on)}
                          />
                        ))}
                        {isTyping && data[0] === 'Today' && (
                          <BubbleChat type={'ai'} isTyping={true} useTimeStamp={false} />
                        )}
                      </Col>
                    </>
                  ))}
                  <Col md={12} id="bottom-chat" style={{ width: '100%' }} />
                </>
              )}
            </Row>
            <InputChat
              onSend={(e) => onSend(e)}
              loading={loading}
              inputHeight={(e) => {
                if (+e > 35) {
                  const calcHeight = 80 + +e;
                  if (calcHeight > 220) {
                    setInputHeight(220);
                  } else {
                    setInputHeight(calcHeight);
                  }
                } else {
                  setInputHeight(120);
                }
              }}
            />
          </CardBody>
        </Card>
      </Collapse>
      <SideButton onClick={() => toggleCollapse()} isOpen={collapse} />
    </div>
  );
};

export default WrapperChat;
