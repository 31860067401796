import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Alert, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import BookingDetailPackageForms from './BookingDetailPackageForms';
import BookingDetailItemForms from './BookingDetailItemForms';
import BookingDetailCustomForms from './BookingDetailCustomForms';
import API from '@utils/API';
import { map } from 'lodash';
import WarningIcon from '@assets/icons/warning.svg';
import { useSelector } from 'react-redux';

const BookingDetailServicesTabForms = (props) => {
  const {
    selectedWorkshop,
    selectedCar,
    selectedUser,
    accountType,
    handleChangeDetailOrder,
    detailOrder,
    disableAll,
    isECommerce,
    isFleetMode,
    errorCart,
    allowBookingCustomForm,
    isDuplicateBooking,
    handleResultNotification,
    markUpNDiscount,
    calculatePromo,
    promoPackageStatus,
    handleChangePromoPackageStatus,
    selectedWorkshops,
  } = props;
  const api = new API('v2');

  const [activeTab, setActiveTab] = useState('part');
  const [categories, setCategories] = useState([]);
  const [hasModalCustomItem, setHasModalCutsomItem] = useState(false);

  const showPackageTab = selectedWorkshop || selectedWorkshops.length > 0;
  const showItemsTab = selectedUser?.isB2B || (!selectedUser?.isB2B && (selectedCar || isECommerce));
  const showCustomTab = true;

  const getCategories = async (activetab = activeTab) => {
    await api
      .get(
        `v2/intools/categories/inventory/?type=${typeGenerator(activetab)}&workshop_id=${selectedWorkshop?.id ?? ''}`
      )
      .then((res) => {
        const transformedData = map(res.data.data, (item) => {
          return {
            value: item.category_id ?? item.id,
            label: item.name,
          };
        });

        setCategories(transformedData);
      })
      .catch((err) => console.log(err));
  };

  const typeGenerator = (type) => {
    switch (type) {
      case 'package':
        return 'package';
      case 'item':
        return 'items';
      default:
        break;
    }
  };

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
    if (tab !== 'custom') getCategories(tab);
  };

  const handleHasModalCustomItem = () => setHasModalCutsomItem(true);

  const handleSubmitCustom = (payload) => {
    handleChangeDetailOrder(payload);
    setHasModalCutsomItem(false);
  };

  useEffect(() => {
    if (showPackageTab) {
      setActiveTab('package');
      getCategories('package');
    } else if ((showItemsTab && !isFleetMode) || (showItemsTab && isFleetMode && selectedUser?.id)) {
      setActiveTab('item');
      getCategories('item');
    } else if (showCustomTab || hasModalCustomItem) {
      setActiveTab('custom');
      getCategories('custom');
    } else {
      setActiveTab('');
      getCategories();
    }
  }, [showPackageTab, showItemsTab, showCustomTab, selectedWorkshop, selectedUser]);

  return (
    <Col lg={9}>
      {allowBookingCustomForm && (
        <BookingDetailCustomForms
          selectedWorkshop={selectedWorkshop}
          selectedCar={selectedCar}
          selectedUser={selectedUser}
          handleChangeDetailOrder={(payload) => handleSubmitCustom(payload)}
          detailOrder={detailOrder}
          disableAll={disableAll}
          tab={activeTab}
          markUpNDiscount={markUpNDiscount}
          toggle={() => setHasModalCutsomItem(false)}
          mode={'modal'}
          hasModalCustom={hasModalCustomItem}
        />
      )}

      {showPackageTab || showItemsTab ? (
        <>
          <Nav tabs className="booking-service-detail-tabs">
            {showPackageTab && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'package' })}
                  onClick={() => toggleActiveTab('package')}
                >
                  Package
                </NavLink>
              </NavItem>
            )}
            {showItemsTab && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'item' })}
                  onClick={() => toggleActiveTab('item')}
                >
                  Item
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </>
      ) : null}

      {!selectedCar && isDuplicateBooking && !isECommerce && activeTab !== 'custom' ? (
        <Alert color="danger" className="d-flex align-items-center">
          <img src={WarningIcon} width={15} className="mr-1" />
          Pilih mobil untuk mengisi form
        </Alert>
      ) : null}

      {showPackageTab && (
        <TabContent activeTab={activeTab}>
          <TabPane tabId="package">
            <BookingDetailPackageForms
              selectedWorkshops={selectedWorkshops}
              detailOrder={detailOrder}
              categories={categories}
              selectedWorkshop={selectedWorkshop}
              selectedCar={selectedCar}
              selectedUser={selectedUser}
              accountType={accountType}
              handleChangeDetailOrder={handleChangeDetailOrder}
              disableAll={disableAll}
              tab={activeTab}
              isDuplicateBooking={isDuplicateBooking}
              calculatePromo={calculatePromo}
              promoPackageStatus={promoPackageStatus}
              handleChangePromoPackageStatus={handleChangePromoPackageStatus}
            />
          </TabPane>
        </TabContent>
      )}
      {showItemsTab && (
        <>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="item">
              <BookingDetailItemForms
                selectedWorkshops={selectedWorkshops}
                categories={categories}
                selectedWorkshop={selectedWorkshop}
                selectedCar={selectedCar}
                selectedUser={selectedUser}
                accountType={accountType}
                handleChangeDetailOrder={handleChangeDetailOrder}
                disableAll={disableAll}
                isECommerce={isECommerce}
                isFleetMode={isFleetMode}
                tab={activeTab}
                handleResultNotification={handleResultNotification}
                markUpNDiscount={markUpNDiscount}
                onClickModalCustom={handleHasModalCustomItem}
                allowBookingCustomForm={allowBookingCustomForm}
                calculatePromo={calculatePromo}
                promoPackageStatus={promoPackageStatus}
                handleChangePromoPackageStatus={handleChangePromoPackageStatus}
              />
            </TabPane>
          </TabContent>
        </>
      )}

      {errorCart && <span className="text-danger">{errorCart}</span>}
    </Col>
  );
};

export default BookingDetailServicesTabForms;
