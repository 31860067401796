import React, { useState, useEffect } from 'react';
import { DISCOUNT_OPTIONS } from '@utils/Constants';
import { Row, Col, Card, CardBody, CardHeader, Form, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { map, findIndex, filter } from 'lodash';
import API from '@utils/API';
import Helper from '@helpers/Helper';
import DeleteIcon from '@assets/icons/delete.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import BookingReviseFormPackage from '@components/booking/BookingReviseFormPackage';
import BookingReviseFormItem from '@components/booking/BookingReviseFormItem';
import BookingReviseFormCustom from '@components/booking/BookingReviseFormCustom';
import ConfirmationModal from '@components/modal/ConfirmationModal';
import CustomModal from '@components/modal/CustomModal';
import EditableInputField from '@components/field/EditableInputField';

function BookingReviseForm(props) {
  const {
    data,
    bookingCode,
    isSubmit,
    saveAsDraft,
    disabled,
    isFleetMode,
    onChangeForm,
    onSubmitRevise,
    onResultNotification,
    pkp,
  } = props;

  const api = new API('v2');
  const apiSearch = new API('search');

  const [dataForm, setDataForm] = useState([]);
  const [activeItems, setActiveItems] = useState(0);
  const [markUpProduct, setMarkUpProduct] = useState(0);
  const [markUpService, setMarkUpService] = useState(0);
  const [discProduct, setDiscProduct] = useState(0);
  const [discService, setDiscService] = useState(0);
  const [takeRateProduct, setTakeRateProduct] = useState(0);
  const [takeRateService, setTakeRateService] = useState(0);
  const [defaultOptionsItem, setDefaultOptionsItem] = useState([]);
  const [categoryPackageOptions, setCategoryPackageOptions] = useState([]);
  const [categoryItemOptions, setCategoryItemOptions] = useState([]);
  const [categoryCustomOptions, setCategoryCustomOptions] = useState([]);
  const [disableButtonModal, setDisableButtonModal] = useState(false);
  const [hasConfirmationModal, setHasConfirmationModal] = useState(false);
  const [hasAddModal, setHasAddModal] = useState(false);
  const [hasDeleteReasonModal, setHasDeleteReasonModal] = useState(false);
  const [deletedItemIndex, setHasDeletedItemIndex] = useState(0);
  const [itemTypeSelected, setItemTypeSelected] = useState('');
  const [internalNotes, setInternalNotes] = useState('');
  const [errorInternalNotes, setErrorInternalNotes] = useState('');
  const [promoStatus, setPromoStatus] = useState({});

  const initDefaultDataForm = () => {
    let defaultDataForm = [];
    const pa = data?.items?.packages?.package_items;
    const pr = data?.items?.products?.product_items;
    const se = data?.items?.services?.service_items;
    const packages = pa?.length > 0 ? pa : [];
    const products = pr?.length > 0 ? pr : [];
    const services = se?.length > 0 ? se : [];
    const itemGroups = [...products, ...services];
    const items = filter(itemGroups, (x) => x.is_custom == false);
    const customs = filter(itemGroups, (x) => x.is_custom == true);

    map(packages, (item) => defaultDataForm.push(parsingDataPackage(item)));
    map(items, (item) => defaultDataForm.push(parsingDataProductAndService(item)));
    map(customs, (item) => defaultDataForm.push(parsingDataCustom(item)));

    setDataForm(defaultDataForm);
  };

  const handleDisableButtonOverrideNetprice = (isOverride, netPrice) => !isOverride && netPrice !== 0;

  const defaultItemProduct = {
    service_type: 'item',
    title: 'Item',
    deleted: false,
    id: null,
    category: null,
    sub_category_id: null,
    sub_category: '',
    item: null,
    item_options: defaultOptionsItem,
    qty: Number(0),
    is_override_sell_price: false,
    is_override_nett_price: false,
    sell_price: Helper.formatMoneyOnField('0'),
    nett_price: Helper.formatMoneyOnField('0'),
    is_splited_tax: false,
    error_category: '',
    error_item: '',
    error_qty: '',
    error_sell_price: '',
    disable_qty: false,
    disable_category: false,
    disable_item: false,
    disable_sell_price: true,
    disable_nett_price: true,
    is_new_data: true,
    voucher_code: '',
  };

  const parsingDataPackage = (item) => {
    const dataPromoStatus = {
      color: 'success',
      message: `Berhasil menggunakan promo ${item?.item_details?.[0]?.voucher_code}`,
      source: 'revise',
    };
    const campaignId = item?.item_details?.[0]?.campaign_id ?? null;
    const dataPromo = {
      campaign_id: campaignId,
      campaign_name: item?.item_details?.[0]?.campaign_name ?? '-',
      voucher_code: item?.item_details?.[0]?.voucher_code,
      status: 'success',
    };
    const voucherCode = item?.item_details?.[0]?.voucher_code ?? null;
    const voucherPercentage = item?.item_details?.[0]?.voucher_percentage ?? null;
    const voucherAmount = item?.item_details?.[0]?.voucher_amount ?? null;
    const dataPackage = {
      service_type: 'package',
      title: 'Paket',
      deleted: false,
      id: item?.booking_package_id ?? null, //booking_package_id
      category: {
        value: item?.category?.id ?? null,
        label: item?.category?.name ?? null,
      },
      package: {
        value: item?.booking_package_id, //temp //package_id
        label: item?.name ?? '',
      },
      package_options: [],
      components: parsingDataComponents(item?.item_details ?? [], 'init'),
      error_category: '',
      error_package: '',
      disable_category: true,
      disable_package: true,
      note: item?.note,
      remarks: item?.remark,
      package_id: item?.package_id,
      booking_package_id: item?.booking_package_id,
      package_details: parsingDataComponents(item?.item_details ?? [], 'init'),
      subtotal: item?.subtotal,
      is_approve: item?.is_approve,
      is_draft_change: item?.is_draft_change,
      voucher_amount: voucherAmount,
      voucher_code: voucherCode,
      voucher_percentage: voucherPercentage,
      promo_status: voucherCode && campaignId ? dataPromoStatus : {},
      promo_data: campaignId ? dataPromo : {},
    };
    return dataPackage;
  };

  const parsingDataProductAndService = (item) => {
    const isProduct = Helper.readIsProduct(item?.product_type ?? '');
    const isService = Helper.readIsService(item?.product_type ?? '');
    const dataPromoStatus = {
      color: 'success',
      message: `Berhasil menggunakan promo ${item?.voucher_code}`,
      source: 'revise',
    };
    const campaignId = item?.campaign_id ?? null;
    const dataPromo = {
      campaign_id: campaignId,
      campaign_name: item?.campaign_name ?? '-',
      voucher_code: item?.voucher_code,
      status: 'success',
    };
    let dataProduct = {
      product_id: item?.product_id ?? null,
      service_type: isProduct ? 'product' : isService ? 'service' : 'item',
      title: isProduct ? 'Produk' : isService ? 'Jasa' : 'Item',
      deleted: false,
      id: item?.booking_product_id ?? null, //booking_product_id
      booking_product_id: item?.booking_product_id ?? null,
      booking_package_id: item?.booking_package_id ?? null,
      booking_detail_id: item?.booking_details_id ?? null,
      is_fbo: item?.is_fbo ? true : false,
      category: {
        value: item?.item_category?.id ?? null,
        label: item?.item_category?.name ?? null,
      },
      sub_category_id: item?.item_subcategory?.id ?? null,
      sub_category: item?.item_subcategory?.name ?? '',
      qty: Number(item?.qty) ?? 0,
      is_override: item?.is_overide_sell_price ? true : false,
      is_override_sell_price: item?.is_overide_sell_price ? true : false,
      is_override_nett_price: item?.is_overide_nett_price ? true : false,
      is_wi: item?.attr?.is_wi,
      nett_price: Helper.formatMoneyOnField(
        `${item?.is_splited_tax ? item?.workshop_price_after_tax : item?.workshop_price ?? 0}`,
        'IDR '
      ),
      sell_price: Helper.formatMoneyOnField(`${item?.sell_price ?? 0}`, 'IDR '),
      take_rate: item?.take_rate_otoklix,
      workshop_price: item?.workshop_price,
      error_category: '',
      error_qty: '',
      error_sell_price: '',
      disable_category: true,
      disable_qty: item?.is_qty_modifier ? true : false,
      disable_sell_price: item?.is_overide_sell_price == true ? false : true,
      disable_nett_price: item?.is_overide_nett_price == true && item?.is_fbo == false ? false : true,
      is_approve: item?.is_approve,
      is_draft_change: item?.is_draft_change,
      note: item?.note ?? '',
      loading_hit_default_price: false,
      hit_default_price: false,
      default_price: null,
      loading_hit_default_nett_price: false,
      hit_default_nett_price: false,
      default_nett_price: null,
      is_refresh_nett_price: false,
      is_splited_tax: !!item?.is_splited_tax,
      remarks: item?.remark,
      disabled_override_net_price: handleDisableButtonOverrideNetprice(
        item?.is_overide_nett_price ? true : false,
        item?.is_splited_tax ? item?.workshop_price_after_tax : item?.workshop_price ?? 0
      ),
      draft_id: item?.draft_id,
      product_type: item?.product_type ?? null,
      voucher_amount: item?.voucher_amount ?? null,
      voucher_code: item?.voucher_code ?? null,
      voucher_percentage: item?.voucher_percentage ?? null,
      promo_status: item?.voucher_code && campaignId ? dataPromoStatus : {},
      promo_data: campaignId ? dataPromo : {},
    };

    if (isProduct || isService) {
      dataProduct.item = {
        value: item?.product_id ? `${item?.product_id}||0` : null, //product_id
        label: item?.name ?? '',
        price: item?.sell_price ?? 0,
        is_fbo: item?.is_fbo ? true : false,
        attr: item?.attr,
      };
      dataProduct.item_options = [];
      dataProduct.error_item = '';
      dataProduct.disable_item = true;
    }

    return dataProduct;
  };

  const parsingDataCustom = (item) => {
    const discountOptions = [{ value: 0, label: '0%' }];
    const isProduct = Helper.readIsProduct(item?.product_type ?? '');
    const isService = Helper.readIsService(item?.product_type ?? '');
    discountOptions.push(DISCOUNT_OPTIONS);

    const dataCustom = {
      service_type: 'custom',
      title: 'Custom',
      deleted: false,
      id: item?.product_id ?? null,
      booking_product_id: item?.booking_product_id ?? null,
      booking_package_id: item?.booking_package_id ?? null,
      booking_detail_id: item?.booking_details_id ?? null,
      is_fbo: item?.is_fbo ? true : false,
      type: isProduct ? 'product' : isService ? 'service' : '',
      name: item?.name ? item?.name.toUpperCase() : '',
      category: {
        value: item?.item_category?.id ?? null,
        label: item?.item_category?.name ?? null,
      },
      qty: Number(item?.qty) ?? 0,
      workshop_price: Helper.formatMoneyOnField(
        `${item?.is_splited_tax ? item?.workshop_price_after_tax : item?.workshop_price ?? 0}`,
        'IDR '
      ),
      nett_price: Helper.formatMoneyOnField(`${item?.nett_price ?? 0}`, 'IDR '),
      markup:
        item?.markup_percentage >= 0
          ? {
              value: item?.markup_percentage,
              label: `${item?.markup_percentage}%`,
            }
          : null,
      markup_options: Helper.generatePercentage(0, 100),
      customer_price: Helper.formatMoneyOnField(`${item?.sell_price ?? 0}`, 'IDR '),
      discount:
        item?.discount_percentage >= 0
          ? {
              value: item?.discount_percentage,
              label: `${item?.discount_percentage}%`,
            }
          : null,
      is_approve: item?.is_approve,
      is_draft_change: item?.is_draft_change,
      note: item?.note ?? '',
      discount_options: discountOptions,
      error_type: '',
      error_name: '',
      error_category: '',
      error_qty: '',
      error_workshop_price: '',
      error_nett_price: '',
      error_customer_price: '',
      error_discount: '',
      error_markup: '',
      disable_type: true,
      disable_category: true,
      disable_customer_price: item?.markup_percentage > 0 ? true : false,
      is_splited_tax: !!item?.is_splited_tax,
      disabled_override_net_price: handleDisableButtonOverrideNetprice(
        item?.is_overide_nett_price ? true : false,
        item?.is_splited_tax ? item?.workshop_price_after_tax : item?.workshop_price ?? 0
      ),
    };

    return dataCustom;
  };

  const parsingDataComponents = (items, type) => {
    let dataComponent = [];

    if (items?.length > 0) {
      if (type === 'init') {
        items?.forEach((item) => {
          let componentData = {
            value: item?.component_id ?? null, //component_id
            label: item?.component_name ?? 'Component',
            prompt: item?.component_prompt ?? '',
            is_disabled: true,
            package_detail_id: item?.value?.package_detail_id
              ? item?.value?.package_detail_id
              : item?.package_detail_id,
            error_component: '',
            product_options: [
              {
                value: item?.product_id ?? null, //product_id
                label: item?.name ?? '',
                price: item?.sell_price ?? 0,
                qty: item?.qty ?? 0,
                is_fbo: item?.is_fbo ? true : false,
                nett_price: item?.nett_price ?? 0,
                category: item?.product_category?.id ?? null,
                product_type: item?.product_type ?? '',
                booking_product_id: item?.booking_product_id ?? null,
                booking_package_id: item?.booking_package_id ?? null,
                booking_detail_id: item?.booking_details_id ?? null,
              },
            ],
            product: {
              value: item?.product_id ?? null, //product_id
              label: item?.name ?? '',
              price: item?.sell_price ?? 0,
              qty: item?.qty ?? 0,
              is_fbo: item?.is_fbo ? true : false,
              nett_price: item?.nett_price ?? 0,
              category: item?.product_category?.id ?? null,
              product_type: item?.product_type ?? '',
              booking_product_id: item?.booking_product_id ?? null,
              booking_package_id: item?.booking_package_id ?? null,
              booking_detail_id: item?.booking_details_id ?? null,
              package_detail_id: item?.package_detail_id ?? null,
            },
          };
          dataComponent.push(componentData);
        });
      } else {
        items?.forEach((item) => {
          let componentData = {
            value: item?.value?.package_details_id ?? null,
            label: item?.value?.name ?? 'Component',
            prompt: item?.prompt ?? '',
            is_disabled: true,
            error_component: '',
            voucher_amount: item?.voucher_amount ?? null,
            voucher_code: item?.voucher_code ?? null,
            voucher_percentage: item?.voucher_percentage ?? null,
            package_detail_id: item?.value?.package_details_id,
            product: {
              value: item?.value?.package_details_id ?? null,
              label: item?.value?.name ?? '',
              price: item?.value?.price ?? 0,
              qty: +item?.value?.qty ?? 0,
              category: item?.value?.service_category?.id ?? null,
              product_type: item?.value?.product_type ?? '',
              booking_product_id: item?.value?.product_id ?? null,
              booking_package_id: item?.booking_package_id ?? null,
              booking_detail_id: item?.value?.package_details_id ?? null,
            },
          };
          dataComponent.push(componentData);
        });
      }
    }

    return dataComponent;
  };

  async function fetchCustomerConfig() {
    try {
      const response = await api.get(`v2/intools/customers/details/?customer_id=${data?.customer?.id}`);
      setMarkUpProduct(response?.data?.data?.product_markup ?? 0);
      setMarkUpService(response?.data?.data?.service_markup ?? 0);
      setDiscProduct(response?.data?.data?.product_discount ?? 0);
      setDiscService(response?.data?.data?.service_discount ?? 0);
    } catch (error) {
      setMarkUpProduct(0);
      setMarkUpService(0);
      setDiscProduct(0);
      setDiscService(0);
    }
  }

  async function fetchWorkshopTakeRate() {
    try {
      const response = await api.get(`v2/intools/workshops/${data?.workshop?.id}/agreement/`);
      setTakeRateProduct(response?.data?.data?.product_take_rate ?? 0);
      setTakeRateService(response?.data?.data?.service_take_rate ?? 0);
    } catch (error) {
      setTakeRateProduct(0);
      setTakeRateService(0);
    }
  }

  async function fetchCategoryPackage() {
    let listCategory = [];
    try {
      const response = await api.get(
        `v2/intools/categories/inventory/?type=package&workshop_id=${data?.workshop?.id ?? ``}`
      );
      if (response?.data?.data) {
        listCategory = map(response?.data?.data, (item) => {
          return {
            value: item?.category_id,
            label: item?.name,
          };
        });
        setCategoryPackageOptions(listCategory);
      }
    } catch (error) {
      setCategoryPackageOptions(listCategory);
    }
  }

  async function fetchPackage(idCategory, index) {
    let listPackage = [];
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const optionsPackage = [];
      if (optionsPackage) {
        listPackage = map(optionsPackage, (item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        dataForm[index].package_options = listPackage;

        setDataForm([...dataForm]);
      }
    } catch (error) {
      dataForm[index].package_options = listPackage;
      setDataForm([...dataForm]);
    }
  }

  async function fetchComponent(idPackage, index) {
    let listComponent = [];
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      const optionsPackageDetails = [];
      if (optionsPackageDetails?.components?.length > 0) {
        map(optionsPackageDetails?.components, (component) => {
          let listProduct = [];
          if (component?.products?.length > 0) {
            map(component?.products, (product) => {
              listProduct.push({
                value: product?.id,
                label: product?.name ?? '',
                price: product?.price,
                qty: product?.qty ?? 0,
                product_type: product?.product_type ?? '',
              });
            });
          }
          listComponent.push({
            value: component?.id,
            label: component?.name,
            prompt: component?.prompt,
            is_disabled: false,
            error_component: '',
            product_options: listProduct,
            product: listProduct?.length == 1 ? listProduct[0] : null,
          });
        });
        dataForm[index].components = listComponent;

        setDataForm([...dataForm]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCategoryItem() {
    let listCategory = [];
    try {
      const response = await api.get(
        `v2/intools/categories/inventory/?type=items&workshop_id=${data?.workshop?.id ?? ``}`
      );
      if (response?.data?.data?.length > 0) {
        listCategory = map(response?.data?.data, (item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategoryItemOptions(listCategory);
      }
    } catch (error) {
      setCategoryItemOptions(listCategory);
    }
  }

  async function fetchCategoryCustom() {
    let listCategory = [];
    try {
      const response = await api.get(`v2/intools/products/categories/`);
      if (response?.data?.data?.categories?.length > 0) {
        map(response?.data?.data?.categories, (item) => {
          if (!isNaN(+item?.id)) {
            listCategory.push({ value: item?.id, label: item?.name });
          }
        });
        setCategoryCustomOptions(listCategory);
      }
    } catch (error) {
      setCategoryCustomOptions(listCategory);
    }
  }

  async function fetchItem(idCategory, query, isTriggerDefault = false, index) {
    let listItem = [];
    let params = {
      category_id: idCategory,
      customer_id: data?.customer?.id,
      is_ecommerce: data?.booking_source?.source_type == 'ecommerce' ? true : false,
      is_enterprise_customer: true,
      q: query,
    };

    if (data?.user_car?.car_details?.id) {
      params.variant_car_id = data?.user_car?.car_details?.id;
    }
    try {
      const url = data?.workshop?.slug
        ? `v2/intools/products/items/workshop/${data?.workshop?.slug}`
        : `v2/intools/products/items/master/`;
      const response = await apiSearch.get(url, { params });
      if (response?.data?.data?.length > 0) {
        listItem = map(response?.data?.data, (item, idx) => {
          return {
            value: item?.product_id ? `${item?.product_id}||${idx}` : null,
            label: item?.display_name,
            ...item,
          };
        });
      }
      if (isTriggerDefault) {
        setDefaultOptionsItem(listItem);
      } else {
        dataForm[index].item_options = listItem;
        setDataForm([...dataForm]);
      }
    } catch (error) {
      if (isTriggerDefault) {
        setDefaultOptionsItem(listItem);
      } else {
        dataForm[index].item_options = listItem;
        setDataForm([...dataForm]);
      }
    }
  }

  async function fetchUpdatedSellPrice(index) {
    const payload = {
      category_id: dataForm[index]?.category?.value,
      customer_id: data?.customer?.id,
      is_ecommerce: false,
      is_enterprise_customer: true,
      products: [
        {
          is_fbo: dataForm[index]?.is_fbo ?? false,
          product_id: generateProductId(dataForm[index]?.item?.value),
        },
      ],
      type: generateProductType(dataForm[index]?.service_type),
      variant_car_id: data?.user_car?.car_details?.id ?? '',
    };
    await api
      .post(`v2/intools/products/workshop/${data?.workshop?.slug}`, payload)
      .then((response) => {
        let defaultPrice = 0;
        if (response?.data?.data?.length && response?.data?.data?.length !== 0) {
          defaultPrice = response?.data?.data[0]?.price ?? 0;
          dataForm[index].hit_default_price = true;
          dataForm[index].default_price = defaultPrice;
        } else {
          dataForm[index].hit_default_price = false;
          dataForm[index].default_price = null;
        }

        dataForm[index].sell_price = Helper.formatMoneyOnField(Helper.formatMoney(defaultPrice ?? 0), 'IDR ');
        dataForm[index].disable_sell_price = true;
        dataForm[index].loading_hit_default_price = false;
        setDataForm([...dataForm]);
      })
      .catch((e) => {
        dataForm[index].hit_default_price = false;
        dataForm[index].default_price = null;
        dataForm[index].sell_price = Helper.formatMoneyOnField(Helper.formatMoney(0), 'IDR ');
        dataForm[index].disable_sell_price = true;

        dataForm[index].loading_hit_default_price = false;
        setDataForm([...dataForm]);
      });
  }

  async function fetchUpdatedNettPrice(index, is_override) {
    const payload = {
      category_id: dataForm[index]?.category?.value,
      customer_id: data?.customer?.id,
      is_ecommerce: false,
      is_enterprise_customer: true,
      products: [
        {
          is_fbo: dataForm[index]?.is_fbo ?? false,
          product_id: generateProductId(dataForm[index]?.item?.value),
        },
      ],
      type: generateProductType(dataForm[index]?.service_type),
      variant_car_id: data?.user_car?.car_details?.id ?? '',
    };
    await api
      .post(`v2/intools/products/workshop/${data?.workshop?.slug}`, payload)
      .then((response) => {
        let defaultNettPrice = 0;
        if (response?.data?.data?.length && response?.data?.data?.length !== 0) {
          defaultNettPrice = response?.data?.data[0]?.nett_price ?? 0;
          dataForm[index].hit_default_nett_price = true;
          dataForm[index].default_nett_price = defaultNettPrice;
        } else {
          dataForm[index].hit_default_nett_price = false;
          dataForm[index].default_nett_price = null;
        }

        dataForm[index].nett_price = Helper.formatMoneyOnField(Helper.formatMoney(defaultNettPrice ?? 0), 'IDR ');
        dataForm[index].disable_nett_price = defaultNettPrice > 0 ? true : !is_override;
        dataForm[index].is_override_nett_price = defaultNettPrice > 0 ? false : is_override;
        dataForm[index].loading_hit_default_nett_price = false;
        setDataForm([...dataForm]);
      })
      .catch((e) => {
        dataForm[index].hit_default_nett_price = false;
        dataForm[index].default_nett_price = null;
        dataForm[index].nett_price = Helper.formatMoneyOnField(Helper.formatMoney(0), 'IDR ');
        dataForm[index].disable_nett_price = true;

        dataForm[index].loading_hit_default_nett_price = false;
        setDataForm([...dataForm]);
      });
  }

  const handleChangeCategoryPackage = (value, index) => {
    dataForm[index].category = value;
    dataForm[index].error_category = validateCategoryPackage(value?.value);
    dataForm[index].package = null;
    dataForm[index].disable_package = false;
    dataForm[index].components = [];
    setDataForm([...dataForm]);
    fetchPackage(value?.value, index);
  };

  const handleChangePackagePackage = (value, index) => {
    dataForm[index].package = value;
    dataForm[index].error_package = validatePackage(value?.value);
    dataForm[index].components = [];
    setDataForm([...dataForm]);
    fetchComponent(value?.value, index);
  };

  const handleChangeComponentPackage = (value, index, indexComponent) => {
    dataForm[index].components[indexComponent].product = value;
    dataForm[index].components[indexComponent].error_component = validateComponent(
      value?.value,
      dataForm[index]?.components[indexComponent]?.label ?? ''
    );
    setDataForm([...dataForm]);
  };

  const handleChangeListItem = (value, index) => {
    dataForm[index].item_options = value;
    setDataForm([...dataForm]);
  };

  const handleChangePromoCode = (value, index) => {
    dataForm[index].voucher_code = value;
    setDataForm([...dataForm]);
  };

  const handleChangeCategoryItem = (value, index) => {
    dataForm[index].category = value;
    dataForm[index].error_category = false;
    dataForm[index].item = null;
    dataForm[index].error_item = '';
    dataForm[index].qty = 0;
    dataForm[index].error_qty = '';
    dataForm[index].is_override = false;
    dataForm[index].sell_price = Helper.formatMoneyOnField('0', 'IDR ');
    dataForm[index].nett_price = Helper.formatMoneyOnField('0', 'IDR ');
    dataForm[index].error_sell_price = '';
    dataForm[index].error_nett_price = '';
    dataForm[index].disable_sell_price = true;
    dataForm[index].disable_nett_price = true;
    dataForm[index].voucher_code = '';
    setDataForm([...dataForm]);
    fetchItem(value?.value, '', false, index);
  };

  const handleChangeItem = (value, index) => {
    const isProduct = Helper.readIsProduct(value?.type ?? '');
    const isService = Helper.readIsService(value?.type ?? '');
    const isPackage = value?.service_type === 'package';
    const serviceType = isPackage ? 'package' : isProduct ? 'product' : isService ? 'service' : 'item';

    const categoriesIndex = findIndex(categoryItemOptions, (val) => {
      return val?.value === value?.product_category_id;
    });

    // if (categoriesIndex !== -1) {
    if (value?.is_new_data) {
      dataForm[index].category = value?.category;
    } else {
      dataForm[index].category = categoryItemOptions[categoriesIndex];
    }
    // }
    if (isPackage) {
      dataForm[index].id = value?.id;
      dataForm[index].item = value;
      dataForm[index].package = {
        value: value?.id,
        label: value?.name ?? '',
      };
      dataForm[index].package_id = value?.id;
      dataForm[index].booking_package_id = null;
      dataForm[index].package_details = parsingDataComponents(value?.package_details ?? [], 'add');
      dataForm[index].components = parsingDataComponents(value?.package_details ?? [], 'add');
      dataForm[index].subtotal = value?.subtotal ?? 0;
      dataForm[index].voucher_code = value?.voucher_code;
    } else {
      (dataForm[index].product_id = value?.product_id ?? null),
        (dataForm[index].sub_category_id = value?.product_subcategory_id);
      dataForm[index].sub_category = value?.product_subcategory_name;
      dataForm[index].service_type = serviceType;
      dataForm[index].title = isPackage ? 'Paket' : isProduct ? 'Produk' : isService ? 'Jasa' : 'Item';
      dataForm[index].item = value;
      dataForm[index].error_item = validateItem(value?.value, serviceType);
      dataForm[index].qty = value?.qty_modifier?.qty ?? 0;
      dataForm[index].error_qty = '';
      dataForm[index].disable_qty = value?.qty_modifier?.is_applied ? true : false;
      dataForm[index].is_override_sell_price = dataForm[index].is_override_sell_price;
      dataForm[index].is_override_nett_price = false;
      dataForm[index].nett_price = Helper.formatMoneyOnField(Helper.formatMoney(value?.nett_price ?? 0), 'IDR ');
      dataForm[index].sell_price = Helper.formatMoneyOnField(Helper.formatMoney(value?.price ?? 0), 'IDR ');
      dataForm[index].error_sell_price = '';
      dataForm[index].error_nett_price = '';
      dataForm[index].disable_sell_price = dataForm[index].is_override_sell_price ? false : true;
      dataForm[index].disable_nett_price = dataForm[index].is_override_nett_price ? false : true;
      dataForm[index].is_wi = value?.attr?.is_wi;
      dataForm[index].attr = value?.attr;
      dataForm[index].voucher_code = value?.voucher_code;
    }
    setDataForm([...dataForm]);
  };

  const handleChangeRemarksItem = (value, index) => {
    const remarks = value?.target?.value ?? '';
    dataForm[index].note = remarks;
    dataForm[index].remarks = remarks;
    dataForm[index].error_remarks = validateRemarksItem(remarks);
    setDataForm([...dataForm]);
  };

  const handleChangeQtyItem = (value, index) => {
    const qty = value?.target?.value ?? 0;
    dataForm[index].qty = qty;
    dataForm[index].error_qty = validateQtyItem(qty);
    setDataForm([...dataForm]);
  };

  const handleChangeOverrideSellPriceItem = (index) => {
    const is_override = !dataForm[index].is_override_sell_price;
    dataForm[index].is_override_sell_price = is_override;
    dataForm[index].error_sell_price = '';

    if (is_override) {
      dataForm[index].disable_sell_price = false;
      setDataForm([...dataForm]);
    } else {
      if (dataForm[index].is_new_data) {
        dataForm[index].sell_price = Helper.formatMoneyOnField(
          Helper.formatMoney(dataForm[index].item?.price ?? 0),
          'IDR '
        );
        dataForm[index].disable_sell_price = true;
        setDataForm([...dataForm]);
      } else if (!dataForm[index].is_new_data && dataForm[index].hit_default_price) {
        dataForm[index].sell_price = Helper.formatMoneyOnField(
          Helper.formatMoney(dataForm[index].default_price ?? 0),
          'IDR '
        );
        dataForm[index].disable_sell_price = true;
        setDataForm([...dataForm]);
      } else if (!dataForm[index].is_new_data && !dataForm[index].hit_default_price) {
        dataForm[index].loading_hit_default_price = true;
        setDataForm([...dataForm]);
        fetchUpdatedSellPrice(index);
      }
    }
  };

  const handleChangeOverrideNettPriceItem = (index) => {
    const is_override = !dataForm[index].is_override_nett_price;
    dataForm[index].is_override_nett_price = is_override;
    dataForm[index].error_nett_price = '';
    dataForm[index].is_refresh_nett_price = true;

    if (!dataForm[index].is_new_data && !dataForm[index].hit_default_nett_price) {
      dataForm[index].loading_hit_default_nett_price = true;
      setDataForm([...dataForm]);
      fetchUpdatedNettPrice(index, is_override);
    } else {
      if (is_override) {
        dataForm[index].disable_nett_price = false;
        setDataForm([...dataForm]);
      } else {
        if (dataForm[index].is_new_data) {
          dataForm[index].nett_price = Helper.formatMoneyOnField(
            Helper.formatMoney(dataForm[index].item?.nett_price ?? 0),
            'IDR '
          );
          dataForm[index].disable_nett_price = true;
          setDataForm([...dataForm]);
        } else if (!dataForm[index].is_new_data && dataForm[index].hit_default_nett_price) {
          dataForm[index].nett_price = Helper.formatMoneyOnField(
            Helper.formatMoney(dataForm[index].nett_price ?? 0),
            'IDR '
          );
          dataForm[index].disable_nett_price = true;
          setDataForm([...dataForm]);
        }
      }
    }
  };

  const handleChangeSellPriceItem = (value, index) => {
    const is_override = dataForm[index].is_override;
    const sell_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].sell_price = sell_price;
    dataForm[index].error_sell_price = validatePriceItem(sell_price, is_override, 'Sell');

    setDataForm([...dataForm]);
  };

  const handleChangeNettPriceItem = (value, index) => {
    const is_override = dataForm[index].is_override;
    const nett_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].nett_price = nett_price;
    dataForm[index].error_nett_price = validatePriceItem(nett_price, is_override, 'Nett');

    setDataForm([...dataForm]);
  };

  const handleChangeTypeCustom = (value, index) => {
    dataForm[index].type = value;
    dataForm[index].name = '';
    dataForm[index].category = null;
    dataForm[index].is_splited_tax = '';
    dataForm[index].error_type = validateTypeCustom(value);
    dataForm[index].error_name = '';
    dataForm[index].error_category = '';

    setDataForm([...dataForm]);
  };

  const handleChangeSplitedTax = (value, index) => {
    dataForm[index].is_splited_tax = value;

    setDataForm([...dataForm]);
  };

  const handleChangeNameCustom = (value, index) => {
    const valueName = value?.target?.value !== ' ' ? value.target.value.replace(/  +/g, ' ') : dataForm[index].name;
    const labelName =
      dataForm[index].type == 'product' ? 'Product Name' : dataForm[index].type == 'service' ? 'Service Name' : 'Name';
    dataForm[index].name = valueName.toUpperCase();
    dataForm[index].error_name = validateNameCustom(valueName, labelName);

    setDataForm([...dataForm]);
  };

  const handleChangeCategoryCustom = (value, index) => {
    dataForm[index].category = value;
    dataForm[index].error_category = validateCategoryCustom(value?.value);

    setDataForm([...dataForm]);
  };

  const handleChangeQtyCustom = (value, index) => {
    const qty = value?.target?.value ?? 0;
    dataForm[index].qty = qty;
    dataForm[index].error_qty = validateQtyCustom(qty);

    setDataForm([...dataForm]);
  };

  const handleChangeWorkshopPriceCustom = (value, index) => {
    const workshop_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].workshop_price = workshop_price;
    dataForm[index].error_workshop_price = validateWorkshopPriceCustom(workshop_price);

    setDataForm([...dataForm]);
  };

  const handleChangeMarkUpCustom = (value, index) => {
    dataForm[index].markup = value;
    dataForm[index].error_markup = validateMarkUpCustom(value?.value);

    setDataForm([...dataForm]);
  };

  const handleChangeCustomerPriceCustom = (value, index) => {
    const customer_price = Helper.formatMoneyOnField(value?.target?.value ?? '0', 'IDR ');

    dataForm[index].customer_price = customer_price;
    dataForm[index].error_customer_price = validateCustomerPriceCustom(customer_price);

    setDataForm([...dataForm]);
  };

  const handleChangeDiscountCustom = (value, index) => {
    dataForm[index].discount = value;
    dataForm[index].error_discount = validateDiscountCustom(value?.value);

    setDataForm([...dataForm]);
  };

  const handleResetItem = (index) => {
    dataForm[index] = defaultItemProduct;
    setDataForm([...dataForm]);
  };

  const validateCategoryPackage = (value) => {
    const errorMsg = !value ? 'Pilih Kategori terlebih dahulu' : '';

    return errorMsg;
  };

  const validatePackage = (value) => {
    const errorMsg = !value ? 'Pilih Paket terlebih dahulu' : '';

    return errorMsg;
  };

  const validateComponent = (value, label) => {
    const errorMsg = !value ? 'Pilih ' + label + ' terlebih dahulu' : '';

    return errorMsg;
  };

  const validateItem = (value, serviceType) => {
    const errorMsg = !value ? `Pilih item terlebih dahulu` : serviceType == 'item' ? 'Tipe item tidak diketahui' : '';

    return errorMsg;
  };

  const validateRemarksItem = (value) => {
    const errorMsg = value?.length > 100 ? 'Maksimal keterangan 100 karakter' : '';

    return errorMsg;
  };

  const validateQtyItem = (value) => {
    const errorMsg =
      value == 0
        ? 'Qty Item harus diisi'
        : value < 1
        ? 'Minimal Qty Item 1'
        : value > 999
        ? 'Maksimal Qty Item 999'
        : '';

    return errorMsg;
  };

  const validatePriceItem = (value, is_override, priceType) => {
    const errorMsg =
      value?.formatMoney > 999999999 && is_override ? `Maksimal ${priceType ?? ''} Price Item IDR 999.999.999` : '';

    return errorMsg;
  };

  const validateTypeCustom = (value) => {
    const errorMsg = value !== 'product' && value !== 'service' ? 'Pilih Tipe Flagging terlebih dahulu' : '';

    return errorMsg;
  };

  const validateNameCustom = (value, label) => {
    const errorMsg =
      value.length > 100
        ? 'Format ' + label + ' maksimal 100 karakter'
        : value.length == 0
        ? label + ' harus diisi'
        : '';

    return errorMsg;
  };

  const validateQtyCustom = (value) => {
    const errorMsg =
      value == 0 ? 'Jumlah harus diisi' : value < 1 ? 'Minimal Jumlah 1' : value > 999 ? 'Maksimal Jumlah 999' : '';

    return errorMsg;
  };

  const validateCategoryCustom = (value) => {
    const errorMsg = !value ? 'Pilih Kategori terlebih dahulu' : '';

    return errorMsg;
  };

  const validateWorkshopPriceCustom = (value) => {
    const errorMsg = value?.formatMoney > 999999999 ? 'Maksimal Harga Jual Bengkel IDR 999.999.999' : '';

    return errorMsg;
  };

  const validateMarkUpCustom = (value) => {
    const errorMsg = value >= 0 ? '' : 'Pilih Mark Up terlebih dahulu';

    return errorMsg;
  };

  const validateCustomerPriceCustom = (value) => {
    const errorMsg = value?.formatMoney > 999999999 ? 'Maksimal Harga Jual Customer IDR 999.999.999' : '';

    return errorMsg;
  };

  const validateDiscountCustom = (value) => {
    const errorMsg = value >= 0 ? '' : 'Pilih Diskon terlebih dahulu';

    return errorMsg;
  };

  const calculateActiveItems = () => {
    const itemActive = dataForm.filter((obj) => !obj.deleted);

    setActiveItems(itemActive?.length ?? 0);
  };

  const handleDeleteItem = (index, addCustom = false) => {
    if (!disabled && !disabledForm()) {
      if (activeItems == 1 && !addCustom) {
        handleResultNotification(true, 'fail', 'Minimal 1 item Paket/Produk/Jasa/Custom');
      } else {
        if (dataForm[index]?.is_new_data) {
          dataForm?.splice(index, 1);
        } else {
          setHasDeleteReasonModal(true);
          setHasDeletedItemIndex(index);
        }
        setDataForm([...dataForm]);
      }
    }
  };

  const deleteItemConfirmation = (index) => {
    dataForm[index].deleted = true;
    dataForm[index].delete_notes = internalNotes ?? '';

    setDataForm([...dataForm]);
    setHasDeleteReasonModal(false);
    setInternalNotes('');
  };

  const deleteItemCancel = () => {
    setHasDeleteReasonModal(false);
    setInternalNotes('');
    setHasDeletedItemIndex('');
  };

  const handleAddItemCustom = (index) => {
    handleDeleteItem(index, true);
    handleAddItem('custom');
  };

  const handleAddItem = (type) => {
    setItemTypeSelected('');
    if (!disabled) {
      if (activeItems > 49) {
        handleResultNotification(true, 'fail', 'Item sudah melebihi batas, maksimal 50 item');
      } else {
        const item =
          type == 'package'
            ? handleDataPackage()
            : type == 'item'
            ? handleDataItem()
            : type == 'custom'
            ? handleDataCustom()
            : {};
        if (item?.service_type !== undefined) {
          setDataForm([...dataForm, item]);
        }
      }
    }
  };

  const handleDataPackage = () => {
    const itemPackage = {
      service_type: 'package',
      title: 'Paket',
      deleted: false,
      id: null,
      category: null,
      package: null,
      package_options: [],
      components: [],
      error_category: '',
      error_package: '',
      disable_category: false,
      disable_package: true,
      is_new_data: true,
    };

    return itemPackage;
  };

  const handleDataItem = () => {
    return defaultItemProduct;
  };

  const handleDataCustom = () => {
    const discountOptions = [{ value: 0, label: '0%' }];
    discountOptions.push(DISCOUNT_OPTIONS);

    const itemCustom = {
      service_type: 'custom',
      title: 'Custom',
      deleted: false,
      id: null,
      type: '',
      name: '',
      category: null,
      qty: Number(0),
      workshop_price: Helper.formatMoneyOnField('0', 'IDR '),
      markup: null,
      markup_options: Helper.generatePercentage(0, 100),
      customer_price: Helper.formatMoneyOnField('0', 'IDR '),
      discount: null,
      discount_options: discountOptions,
      error_type: '',
      error_name: '',
      error_category: '',
      error_qty: '',
      error_workshop_price: '',
      error_customer_price: '',
      error_discount: '',
      error_markup: '',
      disable_type: false,
      disable_category: false,
      is_new_data: true,
      voucher_code: '',
    };

    return itemCustom;
  };

  const validateFormPackage = (item, index, passValidate) => {
    let passValidatePackage = passValidate;

    dataForm[index].error_category = validateCategoryPackage(item?.category?.value);
    dataForm[index].error_package = validatePackage(item?.package?.value);

    passValidatePackage =
      validateCategoryPackage(item?.category?.value) !== '' || validatePackage(item?.package?.value) !== ''
        ? false
        : passValidatePackage;

    item.components.forEach((itemComponent, indexComponent) => {
      dataForm[index].components[indexComponent].error_component = validateComponent(
        itemComponent?.product?.value,
        itemComponent?.label
      );

      passValidatePackage =
        validateComponent(itemComponent?.product?.value, itemComponent?.label) !== '' ? false : passValidatePackage;
    });

    return passValidatePackage;
  };

  const validateFormItem = (item, index, passValidate) => {
    let passValidateItem = passValidate;

    const errorItem = validateItem(item?.item?.value, item?.service_type);
    const errorRemarksItem = validateRemarksItem(item?.remarks);
    const errorQtyItem = validateQtyItem(item?.qty);
    const errorSellPriceItem = validatePriceItem(item?.sell_price, item?.is_override_sell_price, 'Sell');
    const errorNettPriceItem = validatePriceItem(item?.nett_price, item?.is_override_nett_price, 'Nett');

    dataForm[index].error_item = errorItem;
    dataForm[index].error_remarks = errorRemarksItem;
    dataForm[index].error_qty = errorQtyItem;
    dataForm[index].error_sell_price = errorSellPriceItem;
    dataForm[index].error_nett_price = errorNettPriceItem;

    passValidateItem =
      errorItem || errorQtyItem || errorRemarksItem || errorSellPriceItem || errorNettPriceItem
        ? false
        : passValidateItem;

    return passValidateItem;
  };

  const validateFormCustom = (item, index, passValidate) => {
    let passValidateCustom = passValidate;
    const labelName = item?.type == 'product' ? 'Product Name' : item?.type == 'service' ? 'Service Name' : 'Name';

    dataForm[index].error_type = validateTypeCustom(item?.type);
    dataForm[index].error_name = validateNameCustom(item?.name, labelName);
    dataForm[index].error_category = validateCategoryCustom(item?.category?.value);
    dataForm[index].error_qty = validateQtyCustom(item?.qty);
    dataForm[index].error_workshop_price = validateWorkshopPriceCustom(item?.workshop_price);
    dataForm[index].error_customer_price = validateCustomerPriceCustom(item?.customer_price);
    dataForm[index].error_discount = validateDiscountCustom(item?.discount?.value);
    dataForm[index].error_markup = validateMarkUpCustom(item?.markup?.value);

    passValidateCustom =
      validateTypeCustom(item?.type) !== '' ||
      validateNameCustom(item?.name, labelName) !== '' ||
      validateCategoryCustom(item?.category?.value) !== '' ||
      validateQtyCustom(item?.qty) !== '' ||
      validateWorkshopPriceCustom(item?.workshop_price) !== '' ||
      validateCustomerPriceCustom(item?.customer_price) !== '' ||
      validateDiscountCustom(item?.discount?.value) !== '' ||
      validateMarkUpCustom(item?.markup?.value) !== ''
        ? false
        : passValidateCustom;

    return passValidateCustom;
  };

  const validateProcess = () => {
    let passValidate = true;
    dataForm.forEach((item, index) => {
      if (item?.service_type == 'package' && !item?.deleted) {
        passValidate = validateFormPackage(item, index, passValidate);
      } else if (
        (item?.service_type == 'product' || item?.service_type == 'service' || item?.service_type == 'item') &&
        !item?.deleted
      ) {
        passValidate = validateFormItem(item, index, passValidate);
      } else if (item?.service_type == 'custom' && !item?.deleted) {
        passValidate = validateFormCustom(item, index, passValidate);
      }
    });

    return passValidate;
  };

  const rejectedItem = () => {
    let total = 0;
    dataForm?.map((item) => {
      item?.is_approve == false && !item?.deleted && !item?.is_new_data ? (total += 1) : null;
    });

    return total > 0 ? true : false;
  };

  const validationForm = () => {
    if (isSubmit) {
      if (!validateProcess()) {
        handleResultNotification(true, 'fail', 'Mohon lengkapi form');
        onSubmitRevise(false);
      } else if (rejectedItem() && isFleetMode && !saveAsDraft) {
        handleResultNotification(true, 'fail', 'Mohon hapus item yang telah ditolak oleh customer');
        onSubmitRevise(false);
      } else {
        setDisableButtonModal(false);
        setHasConfirmationModal(true);
      }
    }
  };

  const handleConfirmationModal = () => {
    setDisableButtonModal(true);
    setHasConfirmationModal(false);
    const payload = handleGetPayload();
    onSubmitRevise(true, payload);
  };

  const handleAddModal = (type) => {
    handleAddItem(type);
    setHasAddModal(false);
  };

  const toggleConfirmationModal = () => {
    setHasConfirmationModal(!hasConfirmationModal);
    if (onSubmitRevise) onSubmitRevise(false);
  };

  const generateProductType = (productType) => {
    const resultProductType =
      productType == 'part' || productType == 'product' || productType == 'Produk'
        ? 'part'
        : productType == 'work' || productType == 'service' || productType == 'Jasa'
        ? 'work'
        : '';

    return resultProductType;
  };

  const generateTakeRate = (productType) => {
    const typeProduct = generateProductType(productType);

    return typeProduct == 'part' ? takeRateProduct : typeProduct == 'work' ? takeRateService : 0;
  };

  const generateMarkUp = (productType) => {
    const typeProduct = generateProductType(productType);

    return typeProduct == 'part' ? markUpProduct : typeProduct == 'work' ? markUpService : 0;
  };

  const generateDiscount = (productType) => {
    const typeProduct = generateProductType(productType);

    return typeProduct == 'part' ? discProduct : typeProduct == 'work' ? discService : 0;
  };

  const generateProductId = (productValue) => {
    const value = productValue ?? '';
    const splitArray = value.split('||');

    return !isNaN(splitArray[0]) ? Number(splitArray[0]) : null;
  };

  const handleGetPayload = () => {
    let payload = {
      items: [],
      packages: [],
      promo_codes: {
        selected_items: [],
      },
      ...pkp,
    };

    dataForm.forEach((item) => {
      if (item?.service_type === 'package') {
        const packageDetails =
          +item?.package_details?.length > 0
            ? item?.package_details.map((itemDetails) => itemDetails?.product?.booking_detail_id)
            : [];

        const packageDetailsDraft =
          +item?.package_details?.length > 0
            ? item?.package_details.map(
                (itemDetails) => itemDetails?.product?.booking_detail_id ?? itemDetails?.product?.package_detail_id
              )
            : [];
        if (item?.voucher_code && item?.voucher_code !== '' && item?.voucher_code !== null) {
          payload.promo_codes.selected_items.push({
            package_id: item?.package_id,
            promo_code: item?.voucher_code,
          });
        }

        payload.packages.push({
          package_id: item?.package_id,
          booking_package_id: item?.booking_package_id ?? null,
          package_details:
            item?.deleted || item?.booking_package_id
              ? saveAsDraft
                ? packageDetailsDraft
                : []
              : saveAsDraft
              ? packageDetailsDraft
              : packageDetails,
          is_deleted: item?.deleted ? true : false,
          is_approve: item?.is_approve ? true : false,
          deleted_reason: item?.delete_notes ?? null,
          notes: item?.note ?? '',
          remark: item?.remarks ?? '',
        });
      } else if (item?.service_type === 'product' || item?.service_type === 'service') {
        if (item?.voucher_code && item?.voucher_code !== '' && item?.voucher_code !== null) {
          payload.promo_codes.selected_items.push({
            product_id: item?.item?.value ? generateProductId(item?.item?.value) : null,
            promo_code: item?.voucher_code,
          });
        }

        let objItems = {
          booking_product_id: item?.booking_product_id ?? null,
          booking_package_id: item?.booking_package_id ?? null,
          booking_detail_id: item?.booking_detail_id ?? null,
          product_id: item?.item?.value ? generateProductId(item?.item?.value) : null,
          name: item?.item?.label ?? '',
          product_type: generateProductType(item?.service_type),
          product_category: item?.category?.value ?? null,
          product_subcategory_id: item?.sub_category_id ?? null,
          is_fbo: item?.item?.is_fbo ? true : false,
          is_deleted: item?.deleted ? true : false,
          is_approve: item?.is_approve ? true : false,
          deleted_reason: item?.delete_notes ?? '',
          is_overide_sell_price: item?.is_override_sell_price ? true : false,
          is_overide_nett_price: item?.is_override_nett_price ? true : false,
          qty: Number(item?.qty) ?? 0,
          nett_price: !!item?.is_splited_tax
            ? Helper.countDPP(item?.nett_price?.formatMoney ?? 0)
            : item?.nett_price?.formatMoney ?? 0,
          sell_price: item?.sell_price?.formatMoney ?? 0,
          take_rate_percentage: item?.take_rate ?? null,
          markup_percentage: generateMarkUp(item?.service_type),
          discount_percentage: generateDiscount(item?.service_type),
          is_refresh_nett_price: item?.is_refresh_nett_price ? true : false,
          is_splited_tax: !!item?.is_splited_tax,
          is_workshop_inventory: item?.is_wi,
          workshop_price: !!item?.is_splited_tax
            ? Helper.countDPP(item?.nett_price?.formatMoney ?? 0)
            : item?.nett_price?.formatMoney ?? 0 ?? 0,
          workshop_price_after_tax: !!item?.is_splited_tax ? item?.nett_price?.formatMoney ?? 0 : 0,
          remark: item?.remarks ?? '',
          draft_id: item?.draft_id,
        };
        if (item?.workshop_price !== undefined) {
          objItems.workshop_price = item?.workshop_price ?? 0;
        }
        payload.items.push(objItems);
      } else if (item?.service_type == 'custom') {
        if (item?.voucher_code && item?.voucher_code !== '' && item?.voucher_code !== null) {
          payload.promo_codes.selected_items.push({
            product_id: item?.item?.value ? generateProductId(item?.item?.value) : null,
            promo_code: item?.voucher_code,
          });
        }
        const ws_price = item?.workshop_price?.formatMoney ?? 0;
        payload.items.push({
          booking_product_id: item?.booking_product_id ?? null,
          booking_package_id: item?.booking_package_id ?? null,
          booking_detail_id: item?.booking_detail_id ?? null,
          product_id: item?.id ?? null,
          name: item?.name ?? '',
          product_type: generateProductType(item?.type),
          product_category: item?.category?.value ?? null,
          is_fbo: item?.is_fbo ? true : false,
          is_deleted: item?.deleted ? true : false,
          is_approve: item?.is_approve ? true : false,
          deleted_reason: item?.delete_notes ?? '',
          is_overide_sell_price: false,
          is_splited_tax: !!item?.is_splited_tax,
          qty: Number(item?.qty) ?? 0,
          nett_price: Helper.readNettPriceCustom(
            generateTakeRate(item?.type),
            !!item?.is_splited_tax ? Helper.countDPP(ws_price) : ws_price
          ),
          workshop_price: !!item?.is_splited_tax ? Helper.countDPP(ws_price) : ws_price ?? 0,
          workshop_price_after_tax: !!item?.is_splited_tax ? ws_price : 0,
          sell_price: item?.customer_price?.formatMoney ?? 0,
          take_rate_percentage: generateTakeRate(item?.type),
          markup_percentage: item?.markup?.value ?? 0,
          discount_percentage: item?.discount?.value ?? 0,
        });
      }
    });

    return payload;
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const disabledForm = () => {
    let isDisabled = false;
    if (
      data?.booking_type == 'b2b' &&
      (data?.booking_status?.slug == 'service-done' || data?.booking_status?.slug == 'finish')
    ) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const calculatePromo = async (items, type) => {
    const params = {
      whole_cart_promo: '',
      user_car_id: data?.user_car?.id,
      workshop_slug_name: data?.workshop?.slug,
      customer_id: data?.customer?.id,
      packages: [],
      product_id: [],
      service_id: [],
      custom_products_filled: false,
      customer_settlement_price: 0,
      tax_calculation: [],
      promo_context: {
        whole_cart: '',
      },
    };
    if (items?.package_id && type === 'package') {
      params.promo_context.package = items?.package_id ? items : {};
    }

    if (items && type === 'item') {
      params.promo_context.item = items ?? {};
    }

    await api
      .post('v2/intools/promos/calculate', params)
      .then((response) => {
        const data = response?.data?.data;
        if (data) {
          const dataPromoStatus = {
            index: items?.index,
            color: 'success',
            message: `Berhasil menggunakan promo ${data.promo_code} - ${data.promo_name}`,
            source: items?.source,
          };
          setPromoStatus(dataPromoStatus);
          return true;
        }
      })
      .catch((error) => {
        const dataPromoStatus = {
          index: items?.index,
          color: 'danger',
          message: error?.response?.data?.error?.message,
          source: 'revise',
        };
        setPromoStatus(dataPromoStatus);
        return false;
      });
  };

  useEffect(() => {
    if (+promoStatus?.index > -1) {
      let tempDataForm = [...dataForm];
      tempDataForm[promoStatus?.index].promo_status = promoStatus;
      setDataForm([...tempDataForm]);
    }
  }, [promoStatus]);

  useEffect(() => {
    calculateActiveItems();
    onChangeForm(dataForm);
  }, [dataForm]);

  useEffect(() => {
    validationForm();
  }, [isSubmit]);

  useEffect(() => {
    initDefaultDataForm();
    if (data?.booking_id) {
      fetchWorkshopTakeRate();
      fetchCustomerConfig();
      fetchCategoryPackage();
      fetchCategoryItem();
      fetchCategoryCustom();
      fetchItem('', '', true);
    }
  }, [data?.id]);

  return (
    <React.Fragment>
      <ConfirmationModal
        modal={hasConfirmationModal}
        isDisabled={disableButtonModal}
        toggle={toggleConfirmationModal}
        header={`Yakin ingin menyimpan ${saveAsDraft ? `sebagai draft ` : ``} perubahan booking "${bookingCode}" ?`}
        onConfirm={() => {
          handleConfirmationModal();
        }}
        toggleClose={toggleConfirmationModal}
      />
      <CustomModal
        modalHeader={`Tambah`}
        modal={hasAddModal}
        modalFooter={
          <>
            <Button
              color="danger"
              outline
              size="lg"
              onClick={() => {
                setItemTypeSelected('');
                setHasAddModal(false);
              }}
            >
              Batalkan
            </Button>
            <Button
              color="primary"
              size="lg"
              disabled={itemTypeSelected === ''}
              onClick={() => handleAddModal(itemTypeSelected)}
            >
              Pilih
            </Button>
          </>
        }
        toggle={() => setHasAddModal(false)}
      >
        <Row className="mt-3">
          <Col>
            <Button
              color="primary"
              className="w-100"
              outline={itemTypeSelected !== 'item' || itemTypeSelected === ''}
              size="lg"
              onClick={() => setItemTypeSelected('item')}
            >
              Item
            </Button>
          </Col>
          <Col>
            <Button
              color="primary"
              className="w-100"
              outline={itemTypeSelected !== 'package' || itemTypeSelected === ''}
              size="lg"
              onClick={() => setItemTypeSelected('package')}
            >
              Package
            </Button>
          </Col>
        </Row>
      </CustomModal>
      <CustomModal
        modalHeader={`Delete Reason: ${
          dataForm[deletedItemIndex]?.item?.label ?? dataForm[deletedItemIndex]?.name ?? ''
        }`}
        modal={hasDeleteReasonModal}
        modalFooter={
          <>
            <Button color="danger" outline size="lg" onClick={() => deleteItemCancel()}>
              Kembali
            </Button>
            <Button color="primary" size="lg" onClick={() => deleteItemConfirmation(deletedItemIndex)}>
              Konfirmasi
            </Button>
          </>
        }
        toggle={() => setHasDeleteReasonModal(false)}
      >
        <div className="text-center">
          <EditableInputField
            labelWidth={0}
            colWidth={12}
            placeholder={'delete reason'}
            inputName="reason"
            inputType="textarea"
            value={internalNotes}
            onChange={(e) => {
              let msg = e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : internalNotes;

              setInternalNotes(msg);
              if (msg.length > 2000) {
                setErrorInternalNotes('Format Internal Notes maksimal 2000 karakter');
              } else {
                setErrorInternalNotes('');
              }
            }}
            disabledFormText={false}
            formText={internalNotes.length + ' / 2000 characters'}
            invalid={errorInternalNotes !== '' ? true : false}
            errorMessage={errorInternalNotes}
          />
        </div>
      </CustomModal>
      <Card>
        <CardHeader className="text-white mt-0">Pengisian Data</CardHeader>
        <CardBody>
          <Row className="mt-3">
            <Col>
              <Form>
                {map(dataForm, (item, index) => {
                  if (!item?.deleted) {
                    return (
                      <ListGroup key={index}>
                        <ListGroupItem
                          key={index}
                          className={
                            isFleetMode && item?.is_approve !== undefined && item?.is_approve !== null
                              ? item?.is_approve == false
                                ? 'container-item-revise border-revise-rejected'
                                : 'container-item-revise border-revise-approved'
                              : 'container-item-revise'
                          }
                        >
                          <div className="title-item">{item?.title}</div>
                          <div className="close-wrapper" onClick={() => handleDeleteItem(index)}>
                            <img className="icon" src={DeleteIcon} />
                          </div>
                          {item?.service_type == 'package' && (
                            <BookingReviseFormPackage
                              data={item}
                              index={index}
                              disabled={disabled}
                              categoryOptions={categoryPackageOptions}
                              isFleetMode={isFleetMode}
                              onChangeItem={handleChangeItem}
                              onChangeRemarks={handleChangeRemarksItem}
                              onChangeCategory={handleChangeCategoryPackage}
                              onChangePackage={handleChangePackagePackage}
                              onChangeComponent={handleChangeComponentPackage}
                              onChangePromoCode={handleChangePromoCode}
                              accountType={data?.booking_type}
                              selectedCar={data?.user_car}
                              selectedWorkshop={data?.workshop}
                              calculatePromo={calculatePromo}
                            ></BookingReviseFormPackage>
                          )}
                          {(item?.service_type == 'service' ||
                            item?.service_type == 'product' ||
                            item?.service_type == 'item') && (
                            <BookingReviseFormItem
                              headerData={data}
                              data={item}
                              index={index}
                              disabled={disabled}
                              categoryOptions={categoryItemOptions}
                              isFleetMode={isFleetMode}
                              onChangeCategory={handleChangeCategoryItem}
                              onChangeItem={handleChangeItem}
                              onChangeRemarks={handleChangeRemarksItem}
                              onChangeQty={handleChangeQtyItem}
                              onChangeOverrideSellPrice={handleChangeOverrideSellPriceItem}
                              onChangeOverrideNettPrice={handleChangeOverrideNettPriceItem}
                              onChangeSellPrice={handleChangeSellPriceItem}
                              onChangeNettPrice={handleChangeNettPriceItem}
                              onChangeSplitedTax={handleChangeSplitedTax}
                              onChangeListItem={handleChangeListItem}
                              onChangePromoCode={handleChangePromoCode}
                              onResetItem={handleResetItem}
                              handleAddItem={() => handleAddItemCustom(index)}
                              calculatePromo={calculatePromo}
                            ></BookingReviseFormItem>
                          )}
                          {item?.service_type == 'custom' && (
                            <BookingReviseFormCustom
                              data={item}
                              index={index}
                              takeRateProduct={takeRateProduct}
                              takeRateService={takeRateService}
                              disabled={disabled}
                              categoryOptions={categoryCustomOptions}
                              isFleetMode={isFleetMode}
                              onChangeType={handleChangeTypeCustom}
                              onChangeName={handleChangeNameCustom}
                              onChangeCategory={handleChangeCategoryCustom}
                              onChangeQty={handleChangeQtyCustom}
                              onChangeWorkshopPrice={handleChangeWorkshopPriceCustom}
                              onChangeMarkUp={handleChangeMarkUpCustom}
                              onChangeCustomerPrice={handleChangeCustomerPriceCustom}
                              onChangeDiscount={handleChangeDiscountCustom}
                              onChangeSplitedTax={handleChangeSplitedTax}
                              markUp={item?.type === 'product' ? markUpProduct : markUpService}
                              discount={item?.type === 'product' ? discProduct : discService}
                            ></BookingReviseFormCustom>
                          )}
                        </ListGroupItem>
                      </ListGroup>
                    );
                  }
                })}

                <Row style={{ marginTop: '10px' }}>
                  <Col className="text-right">
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-add-item-package mr-2"
                      disabled={disabled || disabledForm()}
                      onClick={() => handleAddItem('package')}
                    >
                      <img src={PlusFill} className="mr-2" />
                      Paket
                    </Button>
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-add-item-package mr-2"
                      disabled={disabled || disabledForm()}
                      onClick={() => handleAddItem('item')}
                    >
                      <img src={PlusFill} className="mr-2" />
                      Item
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default BookingReviseForm;
