import React, { useState, useMemo, useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'reactstrap';
import Helper from '@helpers/Helper';
import '../../styles/compare_price_content.scss';
import IconCopy from '@assets/icons/copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import EstimationPriceCompareWorkshop from '../../helpers/text/EstimationPriceCompareWorkshop';

const ComparePriceContent = (props) => {
  const {
    handleMultipleWorkshopAddCart,
    setSelectedWorkshop,
    toggle,
    cart,
    multipleCalculateCartData,
    selectedWorkshops,
    setSelectedWorkshops,
    tempBookingData,
    setIsSelectedWorkshopPrice,
  } = props;
  const [wiProductObj, setWiProductObj] = useState({});

  const handleCopyClipBoard = (workshopId) => {
    const workshop = selectedWorkshops.find((ws) => ws.id == workshopId);
    const estimationText = EstimationPriceCompareWorkshop.create(
      { ...tempBookingData, workshop: workshop },
      multipleCalculateCartData,
      workshopId
    );

    return estimationText;
  };

  const handleCopyClipBoardWorkshop = (workshopId) => {
    const workshop = selectedWorkshops.find((ws) => ws.id == workshopId);
    const estimationText = EstimationPriceCompareWorkshop.createWorkshopPrice(
      { ...tempBookingData, workshop: workshop },
      multipleCalculateCartData,
      workshopId
    );

    return estimationText;
  };

  const onSubmit = (workshopId) => {
    const itemObj = {
      package: [],
      product: [],
      service: [],
    };

    if (cart.product && Array.isArray(cart.product)) {
      cart.product.forEach((product) => {
        const workshopProducts = wiProductObj[workshopId] || {};
        if (workshopProducts[product.name.product_id] || workshopProducts[product.name.id]) {
          if (product?.formType === 'custom') {
            itemObj.product.push(product);
          } else if (product.name.product_id !== 'mock_item') {
            itemObj.product.push({
              ...product,
              is_wi: workshopProducts[product.name.product_id].is_wi,
              price: workshopProducts[product.name.product_id].price,
              final_sell_price: workshopProducts[product.name.product_id].final_sell_price,
              total_price: workshopProducts[product.name.product_id].total_price,
              nettPrice: workshopProducts[product.name.product_id].nett_price,
              sellPrice: {
                ...product.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.product_id].price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.product_id].price,
              },
              nettPrice: {
                ...product.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[product.name.product_id].nett_price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[product.name.product_id].nett_price,
              },
              name: {
                ...product.name,
                price: workshopProducts[product.name.product_id].price,
                nett_price: workshopProducts[product.name.product_id].nett_price,
                calculated_price: workshopProducts[product.name.product_id].total_price,
                attr: {
                  ...product.name.attr,
                  is_wi: workshopProducts[product.name.product_id].is_wi,
                },
              },
            });
          }
        }
      });
    }

    if (cart.service && Array.isArray(cart.service)) {
      cart.service.forEach((service) => {
        const workshopProducts = wiProductObj[workshopId] || {};
        if (workshopProducts[service.name.product_id] || workshopProducts[service.name.id]) {
          if (service.formType === 'custom') {
            itemObj.service.push(service);
          } else if (service.name.product_id !== 'mock_item') {
            itemObj.service.push({
              ...service,
              is_wi: workshopProducts[service.name.product_id].is_wi,
              price: workshopProducts[service.name.product_id].price,
              final_sell_price: workshopProducts[service.name.product_id].final_sell_price,
              total_price: workshopProducts[service.name.product_id].total_price,
              sellPrice: {
                ...service.sellPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[service.name.product_id].price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[service.name.product_id].price,
              },
              nettPrice: {
                ...service.nettPrice,
                formatIsMoney: true,
                formatMoney: workshopProducts[service.name.product_id].nett_price,
                // formatMoneyString: "IDR 0",
                isNoPriceAvailable: false,
                value: workshopProducts[service.name.product_id].nett_price,
              },
              name: {
                ...service.name,
                price: workshopProducts[service.name.product_id].price,
                nett_price: workshopProducts[service.name.product_id].nett_price,
                calculated_price: workshopProducts[service.name.product_id].total_price,
                attr: {
                  ...service.name.attr,
                  is_wi: workshopProducts[service.name.product_id].is_wi,
                },
              },
            });
          }
        }
      });
    }

    if (cart.package && Array.isArray(cart.package)) {
      cart.package.forEach((pkg) => {
        if (pkg.name && pkg.name.workshop_id === workshopId) {
          const updatedPackage = {
            ...pkg,
            workshop_id: workshopId,
          };
          itemObj.package.push(updatedPackage);
        }
      });
    }

    const workshop = selectedWorkshops.find((workshop) => workshop.id === workshopId);
    setSelectedWorkshops([]);
    setSelectedWorkshop(workshop);
    handleMultipleWorkshopAddCart(itemObj);
    setSelectedWorkshops([workshop]);
    setIsSelectedWorkshopPrice(true);

    toggle();
  };

  const groupedData = useMemo(() => {
    return multipleCalculateCartData?.reduce((acc, item) => {
      const { workshop_id, workshop_name } = item;

      acc[workshop_id] = acc[workshop_id] || {
        workshop_name,
        workshop_id,
        items: [],
        // Customer Data
        customer_tax: item.customer.tax,
        customer_dpp: item.customer.price_before_tax,
        customer_pph: item.customer.service_tax,
        customer_total: item.customer.total_amount,
        // Workshop Data
        workshop_tax: item.workshop.tax,
        workshop_dpp: item.workshop.price_before_tax,
        workshop_pph: item.workshop.service_tax,
        workshop_total: item.workshop.total_amount,
      };

      const addItem = (source, targetKey) => {
        if (source.items) {
          source.items.forEach((sourceItem) => {
            if (sourceItem?.product_id !== 'mock_item') {
              const groupKey = sourceItem.package_id ? sourceItem.package_name : sourceItem.name;
              let existingGroup = acc[workshop_id].items.find((i) => i.groupKey === groupKey);

              if (!existingGroup) {
                existingGroup = {
                  groupKey,
                  package_name: sourceItem.package_id ? sourceItem.package_name : null,
                  items: [],
                  customer_final_sell_price: 0, // Ensure this is initialized
                  workshop_final_sell_price: 0, // Ensure this is initialized
                  isWi: sourceItem?.is_wi,
                  isPackage: 'package_id' in sourceItem,
                  isCustom: sourceItem?.is_custom,
                  isOverrideNett: sourceItem?.is_override_nett,
                  isOverrideSell: sourceItem?.is_override_sell,
                };
                acc[workshop_id].items.push(existingGroup);
              }

              setWiProductObj((prev) => ({
                ...prev,
                [workshop_id]: {
                  ...(prev[workshop_id] || {}),
                  [sourceItem.product_id]: {
                    name: sourceItem.name,
                    is_wi: sourceItem.is_wi,
                    price: sourceItem.price,
                    final_sell_price: sourceItem.final_sell_price,
                    total_price: sourceItem.total_price,
                    nett_price: sourceItem.nett_price,
                    isOverrideNett: sourceItem?.is_override_nett,
                    isOverrideSell: sourceItem?.is_override_sell,
                  },
                },
              }));

              if (targetKey === 'customer_final_sell_price') {
                existingGroup.customer_final_sell_price =
                  (existingGroup.customer_final_sell_price || 0) + (sourceItem.final_sell_price || 0);
              } else if (targetKey === 'workshop_final_sell_price') {
                existingGroup.workshop_final_sell_price =
                  (existingGroup.workshop_final_sell_price || 0) + (sourceItem.total_workshop_price || 0);
              }

              const itemIndex = existingGroup.items.findIndex((i) => i.name === sourceItem.name);
              if (itemIndex !== -1) {
                existingGroup.items[itemIndex][targetKey + 'Item'] = sourceItem.final_sell_price;
              } else {
                existingGroup.items.push({
                  name: sourceItem.name,
                  [targetKey + 'Item']: sourceItem.final_sell_price,
                });
              }
            }
          });
        }
      };

      addItem(item.customer, 'customer_final_sell_price');
      addItem(item.workshop, 'workshop_final_sell_price');

      return acc;
    }, {});
  }, [multipleCalculateCartData]);

  const shouldDisableButton = (workshopId) => {
    const workshop = groupedData[workshopId];
    if (!workshop) return false;

    if (workshop.items.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const shouldDisableCopyButton = (workshopId) => {
    const workshop = groupedData[workshopId];
    const isDisable = workshop.items.some(
      (item) => !item.isWi && !item.isOverrideSell && item.package_name == null && !item.isCustom
    );
    return isDisable;
  };

  const allUniqueItems = Object.values(groupedData)
    .flatMap((workshop) => workshop.items)
    .reduce((uniqueItems, item) => {
      if (!uniqueItems.find((ui) => ui.groupKey === item.groupKey)) {
        uniqueItems.push(item);
      }
      return uniqueItems;
    }, []);

  return (
    <Container fluid className="p-2">
      <Row>
        <Col>
          <span className="font-weight-bold h5">Harga Ke Customer</span>
        </Col>
      </Row>
      <div className="table-responsive">
        <Table bordered className="mt-2 equal-width-columns">
          <thead>
            <tr>
              <th className="bg-light">Paket/Item</th>
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allUniqueItems.map((group) => (
              <React.Fragment key={group.groupKey}>
                <tr>
                  <td className="align-middle">
                    {group.package_name ? (
                      <>
                        <strong>{group.package_name}</strong>
                        <ul className="list-unstyled ms-3">
                          {group.items.map((item) => (
                            <li key={item.name}> - {item.name}</li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <strong>{group.groupKey}</strong>
                    )}
                  </td>
                  {Object.values(groupedData).map((workshop) => {
                    const matchingGroup = workshop.items.find((wg) => wg.groupKey === group.groupKey);
                    var finalPrice = matchingGroup
                      ? `Rp. ${Helper.formatMoney(matchingGroup.customer_final_sell_price)}`
                      : '-';

                    finalPrice =
                      matchingGroup?.isWi ||
                      matchingGroup?.isPackage ||
                      matchingGroup?.isCustom ||
                      matchingGroup?.isOverrideSell
                        ? finalPrice
                        : '-';
                    return (
                      <td key={workshop.workshop_id} className="text-right">
                        {finalPrice}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td className="bg-light fw-bold">DPP</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  {'Rp. ' + Helper.formatMoney(workshop.customer_dpp ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="bg-light fw-bold">PPN</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  {'Rp. ' + Helper.formatMoney(workshop.customer_tax ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="bg-light fw-bold">PPH</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right" style={{ color: 'red' }}>
                  {'- Rp. ' + Helper.formatMoney(workshop.customer_pph ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="bg-light fw-bold">Total</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  {'Rp. ' + Helper.formatMoney(workshop.customer_total ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td></td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  <Row>
                    <CopyToClipboard
                      onCopy={() => {
                        handleCopyClipBoard(workshop.workshop_id);
                      }}
                      text={handleCopyClipBoard(workshop.workshop_id)}
                    >
                      <Button
                        // onClick={() => {
                        //   handleCopyClipBoard(workshop.workshop_id);
                        // }}
                        color="purple"
                        disabled={
                          shouldDisableButton(workshop.workshop_id) || shouldDisableCopyButton(workshop.workshop_id)
                        }
                        className="button-action save w-100"
                      >
                        <img src={IconCopy} className="mr-2 mb-1" />
                        <span>Salin Estimasi Harga</span>
                      </Button>
                    </CopyToClipboard>
                  </Row>
                </td>
              ))}
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className="bg-light"></th>
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </tfoot>
        </Table>
      </div>

      <Row className="mt-2">
        <Col>
          <span className="font-weight-bold h5">Harga Ke Bengkel</span>
        </Col>
      </Row>
      <div className="table-responsive">
        <Table bordered className="mt-2 equal-width-columns">
          <thead>
            <tr>
              <th className="bg-light">Paket/Item</th>
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allUniqueItems.map((group) => (
              <React.Fragment key={group.groupKey}>
                <tr>
                  <td className="align-middle">
                    {group.package_name ? (
                      <>
                        <strong>{group.package_name}</strong>
                        <ul className="list-unstyled ms-3">
                          {group.items.map((item) => (
                            <li key={item.name}>- {item.name}</li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <strong>{group.groupKey}</strong>
                    )}
                  </td>
                  {Object.values(groupedData).map((workshop) => {
                    const matchingGroup = workshop.items.find((wg) => wg.groupKey === group.groupKey);

                    var finalPrice = matchingGroup
                      ? `Rp. ${Helper.formatMoney(matchingGroup.workshop_final_sell_price)}`
                      : '-';

                    finalPrice =
                      matchingGroup?.isWi ||
                      matchingGroup?.isPackage ||
                      matchingGroup?.isCustom ||
                      matchingGroup?.isOverrideNett
                        ? finalPrice
                        : '-';
                    return (
                      <td key={workshop.workshop_id} className="text-right">
                        {finalPrice}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td className="bg-light fw-bold">DPP</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  {'Rp. ' + Helper.formatMoney(workshop.workshop_dpp ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="bg-light fw-bold">PPN</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  {'Rp. ' + Helper.formatMoney(workshop.workshop_tax ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td className="bg-light fw-bold">Total</td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  {'Rp. ' + Helper.formatMoney(workshop.workshop_total ?? 0)}
                </td>
              ))}
            </tr>
            <tr>
              <td></td>
              {Object.values(groupedData).map((workshop) => (
                <td key={workshop.workshop_id} className="text-right">
                  <Row>
                    <CopyToClipboard
                      onCopy={() => {
                        handleCopyClipBoardWorkshop(workshop.workshop_id);
                      }}
                      text={handleCopyClipBoardWorkshop(workshop.workshop_id)}
                    >
                      <Button
                        // onClick={() => {
                        //   handleCopyClipBoard(workshop.workshop_id);
                        // }}
                        color="purple"
                        disabled={shouldDisableButton(workshop.workshop_id)}
                        className="button-action save w-100 mb-2"
                      >
                        <img src={IconCopy} className="mr-2 mb-1" />
                        <span>Konfirmasi Harga Bengkel</span>
                      </Button>
                    </CopyToClipboard>
                    <Button
                      onClick={() => {
                        onSubmit(workshop.workshop_id);
                      }}
                      disabled={shouldDisableButton(workshop.workshop_id)}
                      className="button-action save w-100"
                    >
                      Pilih Bengkel
                    </Button>
                  </Row>
                </td>
              ))}
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className="bg-light"></th>
              {Object.values(groupedData).map((workshop) => (
                <th key={workshop.workshop_id} className="bg-light text-center">
                  {workshop.workshop_name}
                </th>
              ))}
            </tr>
          </tfoot>
        </Table>
      </div>
    </Container>
  );
};

export default ComparePriceContent;
