import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Label, Row } from 'reactstrap';
import SelectAsyncField from '@components/field/SelectAsyncField';
import InputField from '@components/field/InputField';
import SelectField from '@components/field/SelectField';
import { HourOptions } from '@utils/Constants';
import GMap from '../maps/Gmap';
import GMapsModal from '../modal/GMapsModal';
import Helper from '@helpers/Helper';
import { debounce } from 'lodash';

const BookingCustomerDetailForms = (props) => {
  const {
    accountType,
    mapValue,
    onClickCreateNewUser,
    handleClickMap,
    handleClickPUDO,
    handleClickECommerce,
    isPUDO,
    isECommerce,
    isFleetMode,
    onLoadUserDetail,
    handleChangeUser,
    disableCar,
    loadUserCar,
    handleChangeCar,
    disableAll,
    disableTime,
    selectedUser,
    loadingSearchUser,
    selectedCar,
    loadingSearchCar,
    defaultCarOptions,
    bookingSourceOptions,
    handleChangeBookingSource,
    selectedBookingSource,
    disableECommerce,
    handleChangeCarMilleage,
    carMilleage,
    errorCarMilleage,
    handleChangeAddressDetail,
    detailAddress,
    handleChangeFreshDeskLink,
    freshDeskLink,
    handleChangeDate,
    selectedDate,
    handleChangeTime,
    selectedTime,
    errorUser,
    errorCar,
    errorDetailAddress,
    errorBookingSource,
    errorDate,
    errorTime,
    errorMap,
    isOpen,
    toggle,
    lat,
    lng,
    handleMap,
    selectedWorkshop,
    isB2BDraft,
  } = props;

  const [maps, setMaps] = useState();
  const [hasGMapModal, setHasGMapModal] = useState(false);

  const handleClickMaps = () => {
    if (!disableAll) toggleGMapModal();
  };

  const toggleGMapModal = () => setHasGMapModal(!hasGMapModal);

  useEffect(() => {
    if (hasGMapModal) setMaps(mapValue);
  }, [hasGMapModal]);

  return (
    <>
      <Card>
        <CardHeader className="text-white mt-0">Detail Customer</CardHeader>
        <CardBody>
          <FormGroup row>
            <Label lg={3} className="label-required">
              No. Telepon - Customer
            </Label>
            <SelectAsyncField
              id="name-phone"
              colWidth={9}
              selectName="user"
              isDisabled={false || isFleetMode || isB2BDraft}
              value={selectedUser}
              loadOptions={debounce(onLoadUserDetail, 600)}
              getOptionLabel={(e) =>
                e.isB2B ? (
                  <span>
                    <span className="dropdown-tag">B2B</span>
                    {e.label}
                  </span>
                ) : (
                  e.label
                )
              }
              getOptionValue={(e) => e.value}
              onChange={handleChangeUser}
              defaultOptions={[]}
              isCreate
              textLabelCreate="User Baru"
              onClickCreate={() => onClickCreateNewUser('new-user')}
              placeholder="Pilih Customer..."
              invalid={!!errorUser}
              errorMessage={errorUser}
              isLoading={loadingSearchUser}
            />
          </FormGroup>

          {accountType === 'b2c' && (
            <FormGroup row>
              <Label lg={3}>E-Commerce</Label>
              <Col lg={9}>
                <div className="d-flex align-items-center h-100">
                  <input
                    id="ecommerce"
                    type="checkbox"
                    onClick={handleClickECommerce}
                    value={isECommerce}
                    checked={isECommerce}
                    disabled={isPUDO || disableECommerce}
                  />
                </div>
              </Col>
            </FormGroup>
          )}

          <FormGroup row>
            <Label lg={3} className={!isECommerce || (isECommerce && selectedWorkshop) ? 'label-required' : ''}>
              No. Plat Mobil
            </Label>
            <SelectAsyncField
              id="license-plate"
              colWidth={9}
              isDisabled={disableCar || isFleetMode || isB2BDraft}
              value={selectedCar || ''}
              defaultOptions={defaultCarOptions}
              getOptionLabel={(e) =>
                `${e?.license_plate} ${e?.car_details?.car_model?.model_name} - ${Helper.getFuelTypeName(
                  e?.car_details?.fuel
                )}`
              }
              getOptionValue={(e) => e?.id}
              loadOptions={loadUserCar}
              onChange={handleChangeCar}
              isCreate
              textLabelCreate="Mobil Baru"
              onClickCreate={() => onClickCreateNewUser('new-car')}
              placeholder="Pilih Mobil..."
              invalid={!!errorCar}
              errorMessage={errorCar}
              isLoading={loadingSearchCar}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>KM Mobil</Label>
            <InputField
              id="mileage"
              colWidth={9}
              disabled={disableAll || isFleetMode}
              value={carMilleage?.formatMoneyString}
              prepend="KM"
              onChange={handleChangeCarMilleage}
              placeholder="Ex: 20000 KM"
              invalid={!!errorCarMilleage}
              errorMessage={errorCarMilleage}
            />
          </FormGroup>

          {/* <FormGroup row>
            <Label lg={3}>Otoklix Pick Up (PUDO)</Label>
            <Col lg={9}>
              <div className="d-flex align-items-center h-100">
                <input
                  type="checkbox"
                  onClick={handleClickPUDO}
                  value={isPUDO}
                  checked={isPUDO}
                  disabled={isECommerce || disableAll}
                />
              </div>
            </Col>
          </FormGroup> */}

          <div className="gmap-wrapper">
            <GMap
              disabled={disableAll || isFleetMode}
              labelClassName={isPUDO ? 'label-required' : ''}
              label="Lokasi"
              labelWidth={3}
              colWidth={9}
              isDraggable={false}
              handleMap={handleMap}
              latitude={mapValue?.latitude}
              longitude={mapValue?.longitude}
              handleClickMap={handleClickMap}
              handleMapsModal={handleClickMaps}
              withButtonModal
              withAutoComplete
              labelInput={mapValue?.label}
              formText={mapValue?.street_address}
              mapHeight="88px"
              draggable={false}
              errorMessage={errorMap}
            />
          </div>

          <FormGroup row className="mt-4">
            <Label lg={3} className={isPUDO ? 'label-required' : ''}>
              Detail Alamat Lokasi Mobil
            </Label>
            <InputField
              id="address"
              colWidth={9}
              inputType="textarea"
              disabled={disableAll || isFleetMode}
              value={detailAddress}
              onChange={handleChangeAddressDetail}
              placeholder="Ex: Jl. Radio Dalam"
              invalid={!!errorDetailAddress && isPUDO}
              errorMessage={errorDetailAddress}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3} className="label-required">
              Booking Source
            </Label>
            <SelectField
              id="booking-source"
              colWidth={9}
              disabled={disableAll || isFleetMode || isB2BDraft}
              value={selectedBookingSource}
              options={bookingSourceOptions}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              onChange={handleChangeBookingSource}
              placeholder="Pilih Booking Source..."
              invalid={!!errorBookingSource}
              errorMessage={errorBookingSource}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>Freshdesk ticket link</Label>
            <InputField
              id="freshdesk"
              colWidth={9}
              disabled={disableAll || isFleetMode}
              value={freshDeskLink}
              onChange={handleChangeFreshDeskLink}
              placeholder="Ex: freshdesk.com"
            />
          </FormGroup>

          <Row>
            <Col>
              <FormGroup row>
                <Label lg={6} className={!isECommerce || (isECommerce && selectedWorkshop) ? 'label-required' : ''}>
                  Tanggal
                </Label>
                <InputField
                  id="booking_date"
                  colWidth={6}
                  inputType="date"
                  placeholder="Tanggal"
                  disabled={disableAll || isFleetMode}
                  onChange={handleChangeDate}
                  value={selectedDate || ''}
                  invalid={!!errorDate}
                  errorMessage={errorDate}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup row>
                <Label lg={3} className={!isECommerce || (isECommerce && selectedWorkshop) ? 'label-required' : ''}>
                  Jam
                </Label>
                <SelectField
                  id="booking-hour"
                  colWidth={9}
                  options={HourOptions}
                  placeholder="hh:mm"
                  disabled={disableTime}
                  onChange={handleChangeTime}
                  value={selectedTime || ''}
                  invalid={!!errorTime}
                  errorMessage={errorTime}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <GMapsModal
        isOpen={hasGMapModal}
        toggle={toggleGMapModal}
        lat={maps?.latitude}
        lng={maps?.longitude}
        labelInput={maps?.label}
        handleMap={handleMap}
        mapHeight={'500px'}
      />
    </>
  );
};

export default BookingCustomerDetailForms;
