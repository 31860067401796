import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Skeleton from '@components/loading/Skeleton';
import InputField from '@components/field/InputField';
import Divider from '@components/divider/Divider';
import DropDownIndicatorInactive from '@assets/icons/chevron-down.svg';
import { Link } from 'react-router-dom';
import CloseIcon from '@assets/icons/close.svg';
import PlusFill from '@assets/icons/plus-fill-blue.svg';
import FilePurpleFill from '@assets/icons/file-purple.svg';
import TrashIcon from '@assets/icons/trash.svg';
import BookingDetailServicesTabForms from './BookingDetailServicesTabForms';
import BookingCreateDetailOrder from './BookingCreateDetailOrder';
import Helper from '@helpers/Helper';
import newTabIcon from '@assets/icons/new-tab.svg';

const BookingDetailServices = (props) => {
  const {
    toggleHasModalChooseWorkshop,
    selectedUser,
    selectedWorkshop,
    selectedCar,
    accountType,
    handleAddCart,
    handleChangeSPKName,
    spkNumber,
    handleChangeSPKFile,
    handleDeleteSPKFile,
    spkFile,
    checkPromoCode,
    calculatePromo,
    handleChangePromo,
    promo,
    promoStatus,
    promoPackageStatus,
    promoPackageStatusEdit,
    promoCodeResult,
    promoData,
    handleChangeOtopoints,
    useOtopoints,
    userOtopoints,
    usedOtopoints,
    userTotalOtopointsAvailable,
    disableOtopoints,
    handleChangeInternalNotes,
    internalNotes,
    disableAll,
    isFleetMode,
    isECommerce,
    errorWorkshop,
    errorCart,
    isSuccessSubmit,
    isUpdatingCart,
    dataCart,
    errorSPKNumber,
    errorSPKFile,
    errorInternalNotes,
    allowBookingCustomForm,
    handleChangeWorkshop,
    isDuplicateBooking,
    processRemoveCart,
    handleResultNotification,
    validateCart,
    markUpNDiscount,
    loadingCalculate,
    loadingUploadSPK,
    handleChangePromoPackageStatus,
    handleChangePromoPackageStatusEdit,
    multipleWorkshopCart,
    setMultipleWorkshopCart,
    selectedWorkshops,
    cart = {},
  } = props;

  const [detailOrder, setDetailOrder] = useState({
    package: [],
    product: [],
    service: [],
  });

  const handleChangeDetailOrder = (value, from = 'cartPage') => {
    if (from === 'comparePricePage') {
      setDetailOrder(value);
      handleAddCart(value);
    } else {
      setDetailOrder({ ...detailOrder, [value?.type]: [...detailOrder[value?.type], value] });
    }

    let e = { target: { checked: false, value: '' } };
    handleChangePromo(e);
    if (promo) checkPromoCode('order');
  };

  const handleEditDetailOrder = (orderType, index, value) => {
    let newDetailData = { ...detailOrder };
    const item = newDetailData[orderType][index];

    if (item?.formType === 'custom' && item?.type !== value?.type) {
      newDetailData[orderType].splice(index, 1);
      newDetailData[value?.type].push(value);
    } else {
      newDetailData[orderType][index] = value;
    }

    setDetailOrder(newDetailData);
  };

  const handleDeleteDetailOrder = (type, index) => {
    let newDetailData = { ...detailOrder };
    newDetailData[type].splice(index, 1);

    let e = { target: { checked: false, value: '' } };
    handleChangePromo(e);
    validateCart(newDetailData);
    setDetailOrder(newDetailData);

    if (promo) checkPromoCode('order');
  };

  const handleOpenFinder = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => handleChangeSPKFile(e);
    input.click();
  };

  const renderNominalOtopoints = () => {
    const nominal = usedOtopoints;
    if (userOtopoints === nominal) return 'Semua';

    return Helper.formatMoney(nominal);
  };
  useEffect(() => {
    const hasNonEmptyValue = Object.values(multipleWorkshopCart).some((value) =>
      Array.isArray(value) ? value.length > 0 : !!value
    );

    if (hasNonEmptyValue) {
      setDetailOrder({
        package: [],
        product: [],
        service: [],
      });
      handleChangeDetailOrder(multipleWorkshopCart, 'comparePricePage');
      setMultipleWorkshopCart({
        package: [],
        product: [],
        service: [],
      });

      let e = { target: { checked: false, value: '' } };
      if (detailOrder?.package?.length < 1 && detailOrder?.product?.length < 1 && detailOrder?.service?.length < 1) {
        if (useOtopoints) handleChangeOtopoints(e);
        if (promo) handleChangePromo(e);
      }
    }
  }, [detailOrder, multipleWorkshopCart]);

  useEffect(() => {
    handleAddCart(detailOrder);

    let e = { target: { checked: false, value: '' } };
    if (detailOrder?.package?.length < 1 && detailOrder?.product?.length < 1 && detailOrder?.service?.length < 1) {
      if (useOtopoints) handleChangeOtopoints(e);
      if (promo) handleChangePromo(e);
    }
  }, [detailOrder]);

  useEffect(() => {
    if (processRemoveCart) {
      setDetailOrder({
        package: [],
        product: [],
        service: [],
      });
    }
  }, [processRemoveCart]);

  useEffect(() => {
    const hasNonEmptyValue = Object.values(cart).some((value) => (Array.isArray(value) ? value.length > 0 : !!value));

    if (hasNonEmptyValue) {
      setDetailOrder({
        package: [],
        product: [],
        service: [],
      });
      handleChangeDetailOrder(cart, 'comparePricePage');

      let e = { target: { checked: false, value: '' } };
      if (detailOrder?.package?.length < 1 && detailOrder?.product?.length < 1 && detailOrder?.service?.length < 1) {
        if (useOtopoints) handleChangeOtopoints(e);
        if (promo) handleChangePromo(e);
      }
    }
  }, [selectedWorkshops]);

  const disabledOtopoints =
    disableOtopoints ||
    disableAll ||
    userOtopoints < 1 ||
    isECommerce ||
    (detailOrder?.package?.length < 1 && detailOrder?.product?.length < 1 && detailOrder?.service?.length < 1);

  return (
    <Card>
      <CardHeader className="text-white mt-0">Detail Servis</CardHeader>
      <CardBody>
        {selectedUser?.isB2B && (
          <>
            <FormGroup row>
              <Label lg={3}>No. SPK</Label>
              <InputField
                id="spk-number"
                colWidth={9}
                onChange={handleChangeSPKName}
                value={spkNumber}
                placeholder="Ex: SPK/123.123..."
                disabled={disableAll}
                invalid={!!errorSPKNumber}
                errorMessage={errorSPKNumber}
              />
            </FormGroup>

            <FormGroup row>
              <Label lg={3}>SPK Documents</Label>
              <Col lg={9} className="d-flex justify-content-between align-items-center">
                {loadingUploadSPK ? (
                  <span>Loading ...</span>
                ) : spkFile ? (
                  <div>
                    <img src={FilePurpleFill} alt="" width={24} height={24} />
                    <span className="mx-2">{Helper.decreaseSPKName(spkFile?.name ?? '')}</span>
                    <img
                      src={TrashIcon}
                      alt=""
                      style={{ padding: 8, border: '1px solid #D6D8E7', borderRadius: 4, height: 'fit-content' }}
                      onClick={handleDeleteSPKFile}
                    />
                  </div>
                ) : null}
                <Button
                  id="spk-document"
                  color="primary"
                  size="lg"
                  className="btn-add-item"
                  style={{ marginBottom: '10px' }}
                  onClick={handleOpenFinder}
                  disabled={loadingUploadSPK || disableAll}
                >
                  <img src={PlusFill} className="mr-2" />
                  Browse
                </Button>
              </Col>
              <Row className="justify-content-end w-100">
                <Col lg={9} style={{ paddingLeft: 20 }}>
                  <span className="text-danger">{errorSPKFile}</span>
                </Col>
              </Row>
            </FormGroup>
          </>
        )}

        <Row className="justify-content-end">
          <BookingDetailServicesTabForms
            selectedWorkshops={selectedWorkshops}
            selectedWorkshop={selectedWorkshop}
            selectedCar={selectedCar}
            selectedUser={selectedUser}
            accountType={accountType}
            handleChangeDetailOrder={handleChangeDetailOrder}
            detailOrder={detailOrder}
            disableAll={disableAll}
            isECommerce={isECommerce}
            isFleetMode={isFleetMode}
            errorCart={errorCart}
            allowBookingCustomForm={allowBookingCustomForm}
            isDuplicateBooking={isDuplicateBooking}
            handleResultNotification={handleResultNotification}
            markUpNDiscount={markUpNDiscount}
            calculatePromo={calculatePromo}
            promoPackageStatus={promoPackageStatus}
            handleChangePromoPackageStatus={handleChangePromoPackageStatus}
          />
        </Row>

        <Divider />

        <Row className="justify-content-end">
          <BookingCreateDetailOrder
            selectedWorkshops={selectedWorkshops}
            data={detailOrder}
            selectedWorkshop={selectedWorkshop}
            selectedCar={selectedCar}
            selectedUser={selectedUser}
            accountType={accountType}
            isECommerce={isECommerce}
            disableAll={disableAll}
            handleEditDetailOrder={handleEditDetailOrder}
            handleDeleteDetailOrder={handleDeleteDetailOrder}
            handleResultNotification={handleResultNotification}
            markUpNDiscount={markUpNDiscount}
            calculatePromo={calculatePromo}
            promoPackageStatusEdit={promoPackageStatusEdit}
            handleChangePromoPackageStatusEdit={handleChangePromoPackageStatusEdit}
          />
        </Row>

        <Divider />

        {accountType !== 'b2b' && (selectedWorkshop || selectedWorkshops.length == 1) && (
          <>
            <Row>
              <Col>
                <FormGroup row>
                  <Label lg={3}>Kode Promo</Label>
                  <InputField
                    id="promo-code"
                    colWidth={7}
                    onChange={handleChangePromo}
                    value={promo}
                    placeholder="Ex: PROMOINTOOLS"
                    // prepend={
                    //   <Button id="promo-code-check" color="purple" onClick={checkPromoCode}>
                    //     Check
                    //   </Button>
                    // }
                    valid={!!promoStatus && promoStatus?.color === 'success'}
                    disabled={disableAll}
                    invalid={!!promoStatus && promoStatus?.color === 'danger'}
                    errorMessage={promoStatus?.color === 'danger' ? promoStatus?.message : ''}
                  />
                  <Col lg={2}>
                    <Button id="promo-code-check" color="purple" onClick={() => checkPromoCode('cart')}>
                      Pakai
                    </Button>
                  </Col>
                  <Col lg={3}></Col>
                  <Col lg={9} className="mt-2">
                    {promoData?.campaign_id && (
                      <span>
                        Detail campaign <span className="promo-name">{promoData?.campaign_name ?? ''}</span> dapat
                        diakses disini{' '}
                        <span>
                          <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id}`} target="_blank">
                            <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                          </Link>
                        </span>
                      </span>
                    )}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label lg={3}>Otopoints</Label>
                  <Col lg={9}>
                    <div style={{ padding: '10px 0px', display: 'flex', alignItems: 'center' }}>
                      {loadingCalculate ? (
                        <Skeleton width={14} height={14} />
                      ) : (
                        <Input
                          id="otopoints"
                          className="pointer-cursor"
                          type="checkbox"
                          onChange={handleChangeOtopoints}
                          checked={useOtopoints}
                          disabled={disabledOtopoints}
                        />
                      )}{' '}
                      <Label check style={{ marginLeft: 24 }}>
                        {loadingCalculate ? (
                          <Skeleton width={264} height={14} />
                        ) : (
                          `${Helper.formatMoney(userOtopoints)} Points (Pakai ${renderNominalOtopoints()} OtoPoints)`
                        )}
                      </Label>
                    </div>

                    {isECommerce && (
                      <Alert className="my-1" color="info">
                        Tidak bisa memakai Otopoints jika E-Commerce ter-<i>checklist</i>
                      </Alert>
                    )}
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            <Divider />
          </>
        )}

        <FormGroup row>
          <Label lg={3}>Catatan (Internal)</Label>
          <InputField
            id="internal-note"
            colWidth={9}
            inputType="textarea"
            placeholder="Ex: Tambahan di sini..."
            onChange={handleChangeInternalNotes}
            value={internalNotes}
            disabled={disableAll}
            invalid={!!errorInternalNotes}
            errorMessage={errorInternalNotes}
          />
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default BookingDetailServices;
