import React, { useState, useRef, useEffect } from 'react';
import { Button, FormGroup, Input, InputGroup } from 'reactstrap';
import sendIcon from '@assets/icons/send.svg';

const InputChat = (props) => {
  const { onSend, loading, inputHeight } = props;
  const textAreaRef = useRef(null);

  const [message, setMessage] = useState('');
  const [heightInput, setHeightInput] = useState(35);

  const handleSend = () => {
    onSend(message);
    setMessage('');
  };

  const styleInput = {
    lineHeight: 1.4,
    height: heightInput,
    minHeight: heightInput,
    resize: 'none',
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault();
        textAreaRef.current.value += '\n';
        setMessage(message + '\n');
      } else {
        e.preventDefault();
        handleSend();
      }
    }
  };

  const getCalcHeight = (val) => {
    const calc = +val * 20;
    if (+val === 1) {
      return 35;
    }
    if (+val > 1 && +val < 7) {
      return calc;
    }
    if (+val > 6) {
      return 140;
    }
  };

  useEffect(() => {
    const countLine = message.split('\n').length;
    const calcHeight = getCalcHeight(countLine);
    setHeightInput(calcHeight);
    inputHeight(calcHeight);
  }, [message]);

  return (
    <div className="wrapper-input-chat">
      <FormGroup>
        <InputGroup className="align-items-center">
          <Input
            placeholder="Message"
            type="textarea"
            ref={textAreaRef}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            value={message}
            disabled={loading}
            style={styleInput}
          />
          <Button onClick={() => handleSend()} type="submit" disabled={loading} className="send-btn">
            <img src={sendIcon} height={16} alt="" />
          </Button>
        </InputGroup>
      </FormGroup>
    </div>
  );
};

export default InputChat;
