import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import InputField from '../field/InputField';
import SelectField from '../field/SelectField';
import API from '@utils/API';
import { isEmpty, findIndex, debounce } from 'lodash';
import Helper from '@helpers/Helper';
import WarningIcon from '@assets/icons/warning.svg';
import SelectAsyncField from '../field/SelectAsyncField';
import OverridePriceIcon from '@assets/icons/override-price.svg';
import SoldOut from '@assets/images/sold-out.png';
import Divider from '@components/divider/Divider';
import InfoIcon from '@assets/icons/info.svg';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import newTabIcon from '@assets/icons/new-tab.svg';

const BookingDetailItemEditForms = (props) => {
  const {
    selectedData,
    modal,
    toggle,
    categories,
    selectedWorkshop,
    selectedCar,
    selectedUser,
    accountType,
    isECommerce,
    disableAll,
    handleChangeDetailOrder,
    handleResultNotification,
    calculatePromo,
    markUpNDiscount,
    promoPackageStatusEdit,
    handleChangePromoPackageStatusEdit,
  } = props;
  const api = new API('v2');
  const apiSearch = new API('search');

  const [products, setProducts] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [errorProduct, setErrorProduct] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [subCategoryProduct, setSubCategoryProduct] = useState();
  const [errorCategory, setErrorCategory] = useState('');
  const [remarks, setRemarks] = useState('');
  const [errorRemarks, setErrorRemarks] = useState('');
  const [qty, setQty] = useState(0);
  const [errorQty, setErrorQty] = useState('');
  const [sellPrice, setSellPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: 'IDR 0',
  });
  const [nettPrice, setNettPrice] = useState({
    formatIsMoney: false,
    formatMoney: 0,
    formatMoneyString: 'IDR 0',
  });
  const [errorSellPrice, setErrorSellPrice] = useState('');
  const [errorNettPrice, setErrorNettPrice] = useState('');
  const [hasOverrideSellPrice, setHasOverrideSellPrice] = useState(false);
  const [hasOverrideNettPrice, setHasOverrideNettPrice] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promostatus, setPromostatus] = useState({});
  const [promoData, setPromoData] = useState({});
  const [updatePromoStatus, setUpdatePromoStatus] = useState(false);
  const [isSplitedTax, setSplitedTax] = useState(false);

  const handleOverride = () => {
    let canOverride = false;
    if (true) {
      if (!selectedProduct?.attr?.is_fbo) {
        if ((selectedProduct?.nett_price == 0 && selectedProduct?.attr?.is_wi) || !selectedProduct?.attr?.is_wi) {
          canOverride = true;
        }
      }
    }

    return canOverride;
  };

  const fetchProductList = async (selectedCategories, query = '', source = '') => {
    let url = selectedWorkshop
      ? `v2/intools/products/items/workshop/${selectedWorkshop?.slug}`
      : 'v2/intools/products/items/master/';
    const params = {
      is_enterprise_customer: selectedUser?.isB2B === 'b2b' ? true : false,
      variant_car_id: selectedCar?.car_details?.id,
      category_id: selectedCategories,
      customer_id: selectedUser?.id,
      is_ecommerce: isECommerce,
      q: query,
    };
    let data = [];

    if (source === 'category-filter') setLoadingProduct(true);

    await apiSearch
      .get(url, { params })
      .then((res) => {
        data = res.data.data;
        setProducts(data);
        if (source === 'category-filter') setLoadingProduct(false);
      })
      .catch((err) => {
        const errRes = err?.response?.data?.error?.message ?? '';
        setProducts(data);
        if (source === 'category-filter') setLoadingProduct(false);
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal, ${errRes}`,
        });
      });

    return data ?? [];
  };

  const _loadSuggestions = (query, callback) => {
    let url = selectedWorkshop
      ? `v2/intools/products/items/workshop/${selectedWorkshop?.slug}`
      : 'v2/intools/products/items/master/';
    const params = {
      is_enterprise_customer: selectedUser?.isB2B === 'b2b' ? true : false,
      variant_car_id: selectedCar?.car_details?.id,
      category_id: selectedCategory?.value,
      customer_id: selectedUser?.id,
      is_ecommerce: isECommerce,
      q: query,
    };
    let data = [];

    return apiSearch
      .get(url, { params })
      .then((res) => {
        data = res.data.data;
        callback(res.data.data);
        setProducts(data);
      })
      .catch((err) => {
        const errRes = err?.response?.data?.error?.message ?? '';
        setProducts(data);
        handleResultNotification({
          status: true,
          type: 'fail',
          message: `Gagal, ${errRes}`,
        });
      });
  };

  const getProductList = (query) => {
    const res = fetchProductList(selectedCategory?.value, query, 'search-filter');

    return res;
  };

  const getProductListWithCategories = (category) => {
    fetchProductList(category, '', 'category-filter');
  };

  const handleChangeCategories = (value) => {
    setSelectedCategory(value);
    setSubCategoryProduct();
    if (value) {
      getProductListWithCategories(value?.value);
      setErrorCategory('');
      setSelectedProduct();
      handleChangeQty(0);
      handleChangeSellPrice(0);
      handleChangeNettPrice(0);
      setHasOverrideSellPrice(false);
      setHasOverrideNettPrice(false);
      setPromoCode('');
      setPromoData({});
      handleChangePromoPackageStatusEdit({});
      setUpdatePromoStatus(true);
    }
  };

  const handleChangeProducts = (value) => {
    setSelectedProduct(value);
    setSubCategoryProduct();
    setRemarks('');

    if (value) {
      const hasQtyModifier = value.qty_modifier.is_applied;

      if (errorProduct) setErrorProduct('');

      if (hasQtyModifier) {
        handleChangeQty(value.qty_modifier.qty);
      } else {
        handleChangeQty(1);
      }

      const categoriesIndex = findIndex(categories, (val) => {
        return val?.value === value?.product_category_id;
      });

      if (categoriesIndex !== -1) {
        setSelectedCategory(categories[categoriesIndex]);
      }

      setSubCategoryProduct(value?.product_subcategory_name);
      handleChangeSellPrice(value.price);
      handleChangeNettPrice(value.nett_price);
      setHasOverrideSellPrice(false);
      setHasOverrideNettPrice(false);
      setPromoCode('');
      setPromoData({});
      handleChangePromoPackageStatusEdit({});
      setUpdatePromoStatus(true);
    }
  };

  const handleChangeRemarks = (value) => {
    setRemarks(value);

    if (value?.length > 100) {
      setErrorRemarks('Keterangan maksimal 100 karakter');
    } else {
      setErrorRemarks('');
    }
  };

  const handleChangeQty = (value) => {
    const qtyValue = value > 999 ? 999 : value;

    try {
      const quantity = qtyValue;

      setQty(quantity);
    } catch {
      setQty(qtyValue);
    }

    if (value > 999) {
      setErrorQty('Maksimal jumlah yang dapat dipilih adalah 999');
    } else {
      setErrorQty('');
    }
  };

  const handleChangeOverrideSellPrice = (value) => {
    setHasOverrideSellPrice(value);
    if (!value) handleChangeSellPrice(selectedProduct?.price ?? 0);
  };

  const handleChangeOverrideNettPrice = (value) => {
    setHasOverrideNettPrice(value);
    setSplitedTax(false);
    if (!value) handleChangeNettPrice(selectedProduct?.nett_price ?? 0);
  };

  const handleChangeSellPrice = (value) => {
    const sellPriceValue =
      Helper.removeMoneyFormat(value < 0 ? 0 : value) > 99999999999
        ? 99999999999
        : Helper.removeMoneyFormat(value < 0 ? 0 : value);

    try {
      const sellprice = Helper.formatMoneyOnField(sellPriceValue, 'IDR ');

      setSellPrice({ ...sellprice, isNoPriceAvailable: value < 0 ? true : false });
    } catch {
      setSellPrice({
        formatIsMoney: false,
        formatMoney: sellPriceValue,
        formatMoneyString: `IDR ${Helper.formatMoney(sellPriceValue)}`,
        isNoPriceAvailable: value < 0 ? true : false,
      });
    }

    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorSellPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorSellPrice('');
    }
  };

  const handleChangeNettPrice = (value) => {
    const nettPriceValue =
      Helper.removeMoneyFormat(value < 0 ? 0 : value) > 99999999999
        ? 99999999999
        : Helper.removeMoneyFormat(value < 0 ? 0 : value);

    try {
      const nettprice = Helper.formatMoneyOnField(nettPriceValue, 'IDR ');

      setNettPrice({ ...nettprice, isNoPriceAvailable: value < 0 ? true : false });
    } catch {
      setNettPrice({
        formatIsMoney: false,
        formatMoney: nettPriceValue,
        formatMoneyString: `IDR ${Helper.formatMoney(nettPriceValue)}`,
        isNoPriceAvailable: value < 0 ? true : false,
      });
    }

    if (Helper.removeMoneyFormat(value) > 99999999999) {
      setErrorNettPrice(`Maksimal harga yang dapat ditentukan adalah IDR ${Helper.formatMoney(99999999999)}`);
    } else {
      setErrorNettPrice('');
    }
  };

  const handleChangeSplitedTaxCheckBox = () => setSplitedTax(!isSplitedTax);

  const validate = () => {
    let status = true;
    if (!selectedProduct) {
      setErrorProduct('Item harus diisi!');
      status = false;
    } else {
      if (handleDetectProductType(selectedProduct?.type) === '') {
        setErrorProduct('Tipe Item tidak diketahui');
        status = false;
      }
    }

    if (remarks?.length > 100) {
      setErrorRemarks('Keterangan maksimal 100 karakter');
      status = false;
    }

    if (qty < 1) {
      setErrorQty('Minimal jumlah yang dapat dipilih adalah 1');
      status = false;
    } else if (qty > 999) {
      setErrorQty('Maksimal jumlah yang dapat dipilih adalah 999');
      status = false;
    }

    return status;
  };

  const handleDetectProductType = (productType) => {
    const isProduct = Helper.readIsProduct(productType ?? '');
    const isService = Helper.readIsService(productType ?? '');

    return isProduct ? 'product' : isService ? 'service' : '';
  };

  const setDefaultData = () => {
    const productSelected = selectedData?.name ?? null;
    productSelected?._sim ? (productSelected._sim.content = '') : null;

    setSelectedCategory(selectedData?.categories ?? null);
    setSelectedProduct(productSelected);
    setSubCategoryProduct();
    setSubCategoryProduct(productSelected?.product_subcategory_name);
    handleChangeRemarks(selectedData?.remarks ?? '');
    handleChangeQty(selectedData?.qty ?? 0);
    handleChangeSellPrice(selectedData?.sellPrice?.formatMoney ?? 0);
    handleChangeNettPrice(selectedData?.nettPrice?.formatMoney ?? 0);
    setHasOverrideSellPrice(selectedData?.isOverrideSell ?? false);
    setHasOverrideNettPrice(selectedData?.isOverrideNett ?? false);
    fetchProductList(selectedData?.categories?.value ?? '', '', 'search-filter');
    setSplitedTax(selectedData?.isSplitedTax);
    handleChangePromoCode(selectedData?.promoData?.voucher_code ?? '');
    setPromoCode(selectedData?.promoData?.voucher_code ?? '');
    setPromoData(selectedData?.promoData);
    setPromostatus(selectedData?.promoStatus);
    setUpdatePromoStatus(false);
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    const payload = {
      name: selectedProduct,
      categories: selectedCategory,
      remarks: remarks,
      qty,
      sellPrice: { ...sellPrice, value: sellPrice?.formatMoney },
      nettPrice: { ...nettPrice, value: nettPrice?.formatMoney },
      isOverrideSell: hasOverrideSellPrice,
      isOverrideNett: hasOverrideNettPrice,
      type: handleDetectProductType(selectedProduct?.type),
      isSplitedTax: isSplitedTax,
      priceBeforeTax: Helper.countDPP(nettPrice?.formatMoney ?? 0),
      is_wi: selectedProduct?.attr?.is_wi,
      promoData: promostatus?.color == 'success' ? promoData : {},
      promoStatus: promostatus?.color == 'success' ? promostatus : {},
    };
    await handleChangeDetailOrder(payload);
    toggle();
    resetField();
  };

  const resetField = () => {
    handleChangeCategories();
    handleChangeProducts();
    handleChangeQty(0);
    handleChangeRemarks();
    setHasOverrideSellPrice(false);
    setHasOverrideNettPrice(false);
    handleChangeSellPrice(0);
    setSubCategoryProduct();
    setErrorCategory('');
    setErrorProduct('');
    setErrorRemarks('');
    setPromoCode('');
    setPromoData({});
    handleChangePromoPackageStatusEdit({});
  };

  const cancel = () => {
    toggle();
  };

  const handleChangePromoCode = (value) => {
    setPromoCode(value?.toUpperCase());
    setPromoData({});
    setPromostatus({});
    handleChangePromoPackageStatusEdit({});
  };

  const checkPromoCode = () => {
    if (selectedUser?.isB2B && !disableAll && promoData?.voucher_code != promoCode && promoCode) {
      const price = +selectedProduct?.price;
      const qty = +selectedProduct?.qty_modifier?.qty;
      const markup = selectedProduct?.type === 'part' ? markUpNDiscount?.markUpProduct : markUpNDiscount?.markUpService;
      const discount = selectedProduct?.type === 'part' ? markUpNDiscount?.discProduct : markUpNDiscount?.discService;
      const discountAmount = price * (+discount / 100);
      const totalPrice = qty * (price + price * (+markup / 100));

      const items = {
        promo_code: promoCode,
        product_id: selectedProduct?.product_id,
        qty: qty,
        price: price,
        markup_percentage: markup,
        discount_percentage: discount,
        discount_amount: discountAmount,
        total_price: totalPrice,
        source: 'edit',
      };
      calculatePromo(items, 'item');
      getPromoDetail();
      setUpdatePromoStatus(true);
    }
  };

  const getPromoDetail = () => {
    if (selectedUser?.isB2B && !disableAll && promoData?.voucher_code != promoCode) {
      api
        .get(`v2/intools/campaigns/voucher-campaign/${promoCode}/`)
        .then((response) => {
          const data = response?.data?.data ?? {};
          const promo = {
            campaign_id: data?.campaign_id,
            campaign_name: data?.campaign_name,
            voucher_code: data?.voucher_code,
            status: 'success',
          };
          setPromoData(promo);
        })
        .catch((err) => {
          const errMessage = err?.response?.data?.error?.message ?? 'promo code may not be blank';
          const promo = {
            status: 'danger',
            message: errMessage,
          };
          setPromoData(promo);
        });
    }
  };

  useEffect(() => {
    if (updatePromoStatus) {
      setPromostatus(promoPackageStatusEdit);
    }
  }, [updatePromoStatus, promoPackageStatusEdit]);

  useEffect(() => {
    if (modal) {
      setDefaultData();
    }
  }, [modal, categories]);

  useEffect(() => {
    if (selectedProduct) {
      const element = document.getElementsByName(selectedProduct?.product_id)[0];
      element ? element.setAttribute('data-tooltip-content', '') : null;
    }
  }, [selectedProduct]);

  return (
    <Modal className="modal-dialog-centered modal-form" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="body mt-3">
        <div className="header">Edit Item</div>
        <div className="content">
          <FormGroup row>
            <Label lg={3}>Kategori</Label>
            <SelectField
              id="item-category-edit"
              colWidth={9}
              options={categories}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              onChange={handleChangeCategories}
              value={selectedCategory || ''}
              invalid={!selectedCategory}
              errorMessage={errorCategory}
              placeholder="Pilih Kategori..."
              disabled={disableAll || (!selectedCar && !isECommerce)}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>Sub Kategori</Label>
            <InputField
              id="sub-category-edit"
              colWidth={9}
              disabled={true}
              value={!subCategoryProduct ? '-' : subCategoryProduct}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3} className="label-required">
              Item
            </Label>
            <Tooltip
              id={`info-edit-similarity`}
              place="right-center"
              offset={30}
              position-strategy="fixed"
              style={{ zIndex: '5' }}
            />
            <SelectAsyncField
              id="item-edit"
              colWidth={9}
              isDisabled={disableAll || loadingProduct}
              value={selectedProduct || ''}
              defaultOptions={products}
              getOptionLabel={(e) => (
                <span
                  name={selectedProduct?.product_id}
                  className={selectedProduct?.product_id}
                  id={selectedProduct?.product_id}
                  style={{ display: 'block', float: 'none' }}
                  data-tooltip-id={`info-edit-similarity`}
                  data-tooltip-content={e?._sim?.content ?? ''}
                >
                  {e?.attr?.is_fbo ? (
                    <span className="dropdown-tag">FBO</span>
                  ) : e?.attr?.is_wi ? (
                    <span className="dropdown-tag__price-origin">WI</span>
                  ) : (
                    <span className="dropdown-tag__price-origin no-wi">NO WI</span>
                  )}
                  {e.display_name}
                  {!e.is_compatible && <img id="warning-icon" src={WarningIcon} width={15} className="ml-1" />}
                  {!e?.warning_state?.is_sellable && (
                    <img id="availability" src={SoldOut} width={15} className="ml-1 mt-0" />
                  )}
                </span>
              )}
              getOptionValue={(e) => e?.id ?? e?.product_id}
              loadOptions={debounce(_loadSuggestions, 600)}
              onChange={handleChangeProducts}
              invalid={!selectedProduct}
              errorMessage={errorProduct}
              placeholder="Pilih Item..."
              cacheOptions={false}
              loading={loadingProduct}
              isOptionDisabled={(e) => !e?.warning_state?.is_sellable}
            />
          </FormGroup>

          {selectedUser?.isB2B && (
            <FormGroup row>
              <Label lg={3}>Keterangan</Label>
              <InputField
                id="remarks"
                colWidth={9}
                onChange={(e) => handleChangeRemarks(e.target.value)}
                disabled={!selectedProduct || disableAll}
                value={remarks}
                placeholder={'Cth: Keterangan Tahun, Tukar Tambah, dll'}
                invalid={!!errorRemarks}
                errorMessage={errorRemarks}
              />
            </FormGroup>
          )}

          <FormGroup row>
            <Label lg={3}>Jumlah</Label>
            <InputField
              inputType="number"
              id="item-qty-edit"
              colWidth={9}
              onChange={(e) => handleChangeQty(e.target.value)}
              value={qty.toString()}
              disabled={!selectedProduct || disableAll}
              invalid={!!errorQty}
              errorMessage={errorQty}
            />
          </FormGroup>

          <FormGroup row>
            <Label lg={3}>DPP Jual Cutsomer</Label>
            <Col lg={7} sm={11}>
              <Input
                id="sell-price-edit"
                className="input-field"
                disabled={!hasOverrideSellPrice || disableAll}
                onChange={(e) => {
                  if (hasOverrideSellPrice && !disableAll) {
                    handleChangeSellPrice(e.target.value);
                  }
                }}
                value={sellPrice?.isNoPriceAvailable ? 'IDR -' : sellPrice.formatMoneyString}
                invalid={!!errorSellPrice}
              />
              <FormFeedback>{errorSellPrice ?? ''}</FormFeedback>
            </Col>

            {true && (
              <Col lg={2} sm={1} className="d-flex justify-content-end">
                <Button
                  id="override-sell-price-edit"
                  color="primary"
                  size="lg"
                  className={`justify-self-right ${
                    hasOverrideSellPrice ? 'btn-override-price' : 'btn-no-override-price'
                  }`}
                  disabled={disableAll || !selectedProduct}
                  onClick={() => handleChangeOverrideSellPrice(!hasOverrideSellPrice)}
                >
                  <img src={OverridePriceIcon} height={15} className="override-icon" />
                </Button>
              </Col>
            )}
          </FormGroup>

          {selectedWorkshop?.is_pkp && hasOverrideNettPrice && (
            <>
              <Divider />
              <FormGroup row>
                <Tooltip id="info-split-tax" />
                <Label lg={3}>
                  <img
                    src={InfoIcon}
                    alt=""
                    className="mr-1 cursor-pointer"
                    width={16}
                    data-tooltip-id="info-split-tax"
                    data-tooltip-content="Jika diaktifkan, maka operator mengisi Harga Jual Bengkel termasuk PPN lalu sistem akan menghitung DPP"
                  />
                  Split Tax Pricing
                </Label>
                <Col lg={9} style={{ padding: '6px 12px', display: 'flex', alignItems: 'center' }}>
                  <Input
                    id="split-tax-check"
                    type="checkbox"
                    onChange={handleChangeSplitedTaxCheckBox}
                    checked={isSplitedTax}
                    disabled={disableAll || !!!selectedWorkshop?.is_pkp}
                  />
                </Col>
              </FormGroup>

              {isSplitedTax && (
                <FormGroup row>
                  <Label lg={3}>DPP Jual Bengkel</Label>
                  <Col lg={9} sm={12} className="d-flex align-items-center">
                    <span>{`IDR ${Helper.formatMoney(Helper.countDPP(nettPrice?.formatMoney))}`}</span>
                  </Col>
                </FormGroup>
              )}
            </>
          )}

          {!isEmpty(selectedWorkshop) && !nettPrice.isNoPriceAvailable ? (
            <FormGroup row>
              <Label lg={3}>{isSplitedTax ? 'DPP Jual Bengkel Termasuk PPN' : 'DPP Jual Bengkel'}</Label>
              <Col lg={!handleOverride() ? 9 : 7} sm={!handleOverride() ? 12 : 11}>
                <Input
                  id="nett-price-edit"
                  className="input-field"
                  disabled={!hasOverrideNettPrice || disableAll}
                  onChange={(e) => {
                    if (hasOverrideNettPrice && !disableAll) {
                      handleChangeNettPrice(e.target.value);
                    }
                  }}
                  value={nettPrice?.isNoPriceAvailable ? 'IDR -' : nettPrice.formatMoneyString}
                  invalid={!!errorNettPrice}
                />
                <FormFeedback>{errorNettPrice ?? ''}</FormFeedback>
              </Col>
              {handleOverride() && (
                <Col lg={2} sm={1} className="d-flex justify-content-end">
                  <Button
                    id="override-nett-price-edit"
                    color="primary"
                    size="lg"
                    className={`justify-self-right ${
                      hasOverrideNettPrice ? 'btn-override-price' : 'btn-no-override-price'
                    }`}
                    disabled={disableAll || !selectedProduct}
                    onClick={() => handleChangeOverrideNettPrice(!hasOverrideNettPrice)}
                  >
                    <img src={OverridePriceIcon} height={15} className="override-icon" />
                  </Button>
                </Col>
              )}
            </FormGroup>
          ) : null}
          {selectedUser?.isB2B && (
            <FormGroup row>
              <Label lg={3}>Kode Promo</Label>
              <InputField
                colWidth={6}
                placeholder="promo code"
                value={promoCode}
                onChange={(e) => handleChangePromoCode(e?.target?.value)}
                disabled={disableAll || !selectedProduct}
                valid={!!promostatus && promostatus?.color == 'success'}
                invalid={!!promostatus && promostatus?.color == 'danger'}
                errorMessage={promostatus?.color == 'danger' ? promostatus?.message : ''}
              />
              <Col lg={3}>
                <Button
                  color="purple"
                  onClick={checkPromoCode}
                  disabled={disableAll || !selectedProduct}
                  className="w-100"
                >
                  Pakai
                </Button>
              </Col>
              <Col lg={12} className="mt-2">
                {promoData?.campaign_id && (
                  <span>
                    Detail campaign <span className="promo-name">{promoData?.campaign_name ?? '-'}</span> dapat diakses
                    disini{' '}
                    <span>
                      <Link to={`/campaigns/campaign/detail/${promoData?.campaign_id ?? 411}`} target="_blank">
                        <img src={newTabIcon} height={18} className="icon-new-tab pb-1 ml-1" />
                      </Link>
                    </span>
                  </span>
                )}
              </Col>
            </FormGroup>
          )}
        </div>
        <div className="action mb-3 mt-3">
          <Button id="item-edit-cancel" className="button-cancel" onClick={cancel} disabled={disableAll}>
            Batal
          </Button>
          <Button id="item-edit-save" className="button-action" onClick={handleSubmit} disabled={disableAll}>
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BookingDetailItemEditForms;
