import React, { useEffect, useState } from 'react';
import { every, find } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import map from 'lodash/map';
import API from '@utils/API';
import APIS3 from '@utils/APIS3';
import TrashIcon from '@assets/icons/trash.svg';
import InputField from '@components/field/InputField';
import PenIcon from '@assets/icons/pen.svg';
import Helper from '@helpers/Helper';

function BookingDetailDetailsDocument(props) {
  const {
    id,
    detailData,
    editDetail,
    editCar,
    isFleetMode,
    disabledGLobal,
    onResultNotification,
    onEditModeDocument,
    onProcessEditDocument,
  } = props;
  const api = new API('v2');
  const apiS3 = new APIS3();
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [dataDocument, setDataDocument] = useState({});
  const [activeDocumentInvoice, setActiveDocumentInvoice] = useState(0);
  const [activeDocumentSPK, setActiveDocumentSPK] = useState(0);
  const [canViewSPK, setCanViewSPK] = useState(false);
  const [canViewInvoice, setCanViewInvoice] = useState(false);
  const [canUploadInvoice, setCanUploadInvoice] = useState(false);
  const [editSPKNumber, setEditSPKNumber] = useState(false);
  const [editSPKDoc, setEditSPKDoc] = useState(false);
  const [spkEditType, setSPKEditType] = useState('');
  const [editInvoice, setEditInvoice] = useState(false);
  const [isDragOverSPKDoc, setIsDragOverSPKDoc] = useState(false);
  const [isDragOverInvoice, setIsDragOverInvoice] = useState(false);
  const [errorUploadInvoice, setErrorUploadInvoice] = useState('');
  const [errorUploadSPKDoc, setErrorUploadSPKDoc] = useState('');

  const customerType = detailData?.customer?.customer_type;

  const { getRootProps } = useDropzone({
    multiple: false,
    disabled: disabled || disabledGLobal,
    onDrop: (files) => {
      handleDropFile(files[0]);
      handleDragOverDoc(false);
    },
    onDragOver: () => {
      removeErrorDoc();
      handleDragOverDoc(true);
    },
    onDragLeave: () => {
      handleDragOverDoc(false);
    },
    onFileDialogOpen: () => {
      removeErrorDoc();
    },
  });

  const handleDragOverDoc = (status) => {
    if (editSPKDoc) {
      setIsDragOverSPKDoc(status);
    }
    if (editInvoice) {
      setIsDragOverInvoice(status);
    }
  };

  const initDefaultDataDocuments = () => {
    const documentData = {
      spk_number: detailData?.spk_number ?? '',
      error_spk_number: '',
      spk: mappingDocuments('spk'),
      invoice: mappingDocuments('invoice'),
    };
    setDataDocument(documentData);
    setEditInvoice(false);
    setEditSPKDoc(false);
    setEditSPKNumber(false);
    setIsDragOverSPKDoc(false);
    setIsDragOverInvoice(false);
  };

  const mappingDocuments = (type) => {
    let documentSPK = [];
    let documentInvoice = [];

    if (detailData?.documents?.length) {
      map(detailData.documents, (item) => {
        let document = {
          id: item?.id ?? null,
          label: item?.label ?? '',
          url_file: item?.url ?? '',
          key: item?.key ?? '',
          name: item?.filename,
          deleted: false,
        };
        if (type == 'spk' && item?.document_type == 'spk') {
          documentSPK.push(document);
        }
        if (type == 'invoice' && item?.document_type == 'invoice') {
          documentInvoice.push(document);
        }
      });
    }

    if (type == 'spk') return documentSPK;
    if (type == 'invoice') return documentInvoice;
  };

  const handleCanViewSPK = () => {
    setCanViewSPK(customerType == 'b2b' ? true : false);
  };

  const handleCanViewInvoice = () => {
    setCanViewInvoice(customerType == 'b2b' || customerType == 'b2c' ? true : false);
  };

  const handleCanUploadInvoice = () => {
    // if (
    //   detailData?.booking_status?.slug == 'payment' ||
    //   detailData?.booking_status?.slug == 'waiting' ||
    //   detailData?.booking_status?.slug == 'confirm' ||
    //   detailData?.booking_status?.slug == 'on-process'
    // ) {
    //   setCanUploadInvoice(true);
    // } else {
    //   setCanUploadInvoice(false);
    // }
    setCanUploadInvoice(true);
  };

  const handleEditSPKNumber = () => {
    if (!disabled && !disabledGLobal) {
      initDefaultDataDocuments();
      setEditSPKNumber(!editSPKNumber);
      setEditSPKDoc(!editSPKDoc);
      validateSPKNumber(dataDocument?.spk_number);
      if (editSPKNumber) {
        setSPKEditType('');
      } else {
        setSPKEditType('spk number');
      }
    }
  };

  const handleEditSPKDoc = () => {
    if (!disabled && !disabledGLobal) {
      initDefaultDataDocuments();
      setEditSPKDoc(!editSPKDoc);
      setEditSPKNumber(!editSPKNumber);
      if (editSPKDoc) {
        setSPKEditType('');
      } else {
        setSPKEditType('spk doc');
      }
    }
  };

  const handleEditInvoice = () => {
    if (!disabled && !disabledGLobal) {
      initDefaultDataDocuments();
      setEditInvoice(!editInvoice);
    }
  };

  const setChangeValueForm = (key, value, errorKey, errorMsg) => {
    dataDocument[key] = value;
    if (errorKey) dataDocument[errorKey] = errorMsg;
    setDataDocument({ ...dataDocument });
  };

  const handleChangeSPKNumber = (e) => {
    if (!disabled && !disabledGLobal) {
      validateSPKNumber(e.target.value !== ' ' ? e.target.value.replace(/  +/g, ' ') : dataDocument?.spk_number);
    }
  };

  const validateSPKNumber = (value) => {
    let errorMsg = '';

    const hasIsDeletedSPK = every(dataDocument?.spk, (item) => item?.deleted == true);

    if (value?.length > 0 && value?.length > 100) {
      errorMsg = 'Format SPK Number maksimal 100 karakter';
    } else if (value?.length < 1 && !hasIsDeletedSPK) {
      errorMsg = 'Nomor SPK harus diisi';
    } else if (value?.length > 0 && hasIsDeletedSPK) {
      setErrorUploadSPKDoc('Dokumen SPK harus diisi');
    } else if (value?.length < 1 && hasIsDeletedSPK) {
      setErrorUploadSPKDoc('');
      errorMsg = '';
    }
    setChangeValueForm('spk_number', value, 'error_spk_number', errorMsg);
    return errorMsg ? false : true;
  };

  async function handleSubmit() {
    if (!disabled && !disabledGLobal) {
      const validSPKNumber = editSPKNumber ? !dataDocument?.error_spk_number || !errorUploadSPKDoc : true;
      const msgUpdate =
        editSPKNumber && spkEditType === 'spk number'
          ? 'SPK Number '
          : editSPKDoc && spkEditType === 'spk doc'
          ? 'SPK Document '
          : editInvoice
          ? 'Invoice Bengkel '
          : '';
      if ((dataDocument?.error_spk_number || errorUploadSPKDoc) && customerType === 'b2b' && !editInvoice) {
        handleResultNotification(true, 'fail', 'Gagal update, cek kembali isian form');
        return;
      }

      if (validSPKNumber) {
        setIsProcess(true);
        const payload = getPayload();
        api
          .put(`v2/intools/bookings/${id}/booking-revise/`, payload)
          .then((response) => {
            handleResultNotification(true, 'success', 'Berhasil Update ' + msgUpdate);
            setEditInvoice(false);
            setEditSPKDoc(false);
            setEditSPKNumber(false);
            setIsProcess(false);
          })
          .catch((e) => {
            const message = `API Error : ${e?.response?.data?.error?.message}`;
            setIsProcess(false);
            handleResultNotification(true, 'fail', 'Gagal Update ' + msgUpdate + message);
          });
      }
    }
  }

  const getPayload = () => {
    let payload = {};

    if (editSPKNumber || editSPKDoc) {
      payload = {
        spk_number: dataDocument?.spk_number ?? '',
        documents: mappingPayloadDocuments('spk'),
      };
    } else if (editInvoice) {
      payload = {
        spk_number: dataDocument?.spk_number ?? '',
        documents: mappingPayloadDocuments('invoice'),
      };
    }

    return payload;
  };

  const mappingPayloadDocuments = (type) => {
    let documents = [];
    if (dataDocument?.[type]?.length) {
      dataDocument[type].forEach((item) => {
        if (item?.url_file && item?.key && (item?.id || (!item?.id && !item?.deleted))) {
          documents.push({
            id: item?.id ?? null,
            document_type: type,
            filename: item?.name ?? '',
            label: item?.name ?? '',
            key: item?.key ?? '',
            is_deleted: item?.deleted ?? false,
          });
        }
      });
    }
    return documents;
  };

  const handleDropFile = (data) => {
    const file = data;
    const maxSize = 10240;
    const fileSize = file?.size ? Math.round(file?.size / 1024) : null;
    const allowTypes = ['jpeg', 'jpg', 'png', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'pdf'];
    const nameFileSplit = file?.name ? file?.name.split('.') : [];
    let failDrop = false;
    let failMsg = '';

    if (nameFileSplit.length == 0) {
      failDrop = true;
      failMsg = 'Format file tidak diketahui';
    } else {
      const selected = find(allowTypes, (item) => {
        return nameFileSplit[nameFileSplit.length - 1].toLowerCase() === item;
      });
      if (!selected) {
        failDrop = true;
        failMsg = 'File tidak sesuai dengan format';
      } else {
        if (fileSize == null) {
          failDrop = true;
          failMsg = 'Ukuran file tidak diketahui';
        } else if (fileSize > maxSize) {
          failDrop = true;
          failMsg = 'Ukuran file maksimal 10mb';
        } else {
          failDrop = false;
          failMsg = '';

          if (!dataDocument?.spk_number && file?.name) {
            setChangeValueForm('spk_number', dataDocument?.spk_number, 'error_spk_number', 'No. SPK harus diisi');
          } else if (dataDocument?.spk_number && !file?.name) {
            setChangeValueForm('spk_number', dataDocument?.spk_number, 'error_spk_number', 'Dokumen SPK harus diisi');
          }

          const fileUrl = URL.createObjectURL(file);
          handlePresignedUploadFile(file, fileUrl);
        }
      }
    }

    if (editSPKDoc || editSPKNumber) {
      setErrorUploadSPKDoc(failDrop ? failMsg : '');
    } else if (editInvoice) {
      setErrorUploadInvoice(failDrop ? failMsg : '');
    }
  };

  const handlePresignedUploadFile = (file, fileUrl) => {
    setIsProcess(true);
    const paramsPresignedUpload = {
      namespace: 'booking',
      identifier: detailData?.id,
      file_name: file?.name,
    };
    api
      .post(`v2/intools/presigned-upload/`, paramsPresignedUpload)
      .then((response) => {
        const urlUpload = response?.data?.data?.presigned_url ?? '';
        const keyFile = response?.data?.data?.key ?? '';
        if (urlUpload !== '') {
          handleUploadFile(file, fileUrl, urlUpload, keyFile);
        } else {
          handleResultNotification(true, 'fail', 'Fail get url upload file');
          setIsProcess(false);
        }
      })
      .catch((e) => {
        const message = `API Error : ${e?.response?.data?.error?.message}`;
        handleResultNotification(true, 'fail', message);
        setIsProcess(false);
      });
  };

  const handleUploadFile = (file, fileUrl, urlUpload, keyFile) => {
    apiS3
      .put(urlUpload, file)
      .then((response) => {
        setIsProcess(false);
        handleSuccessUploadFile(file, fileUrl, keyFile);
      })
      .catch((e) => {
        handleResultNotification(true, 'fail', 'Fail upload file');
        setIsProcess(false);
      });
  };

  const handleSuccessUploadFile = (file, fileUrl, keyFile) => {
    setErrorUploadSPKDoc('');
    setErrorUploadInvoice('');
    const keyObject = editInvoice ? 'invoice' : editSPKDoc ? 'spk' : '';
    let newDataDocument = { ...dataDocument };
    let itemDoc = {
      id: null,
      label: file?.name,
      url_file: fileUrl,
      key: keyFile,
      name: file?.name,
      deleted: false,
    };

    if (keyObject !== '') {
      newDataDocument[keyObject].push(itemDoc);
      setDataDocument({ ...dataDocument, newDataDocument });
    }
  };

  const handleResultNotification = (status = false, type = '', message = '') => {
    const notification = {
      status: status,
      type: type,
      message: message,
    };

    onResultNotification(notification);
  };

  const removeErrorDoc = () => {
    if (errorUploadSPKDoc !== '') {
      setErrorUploadSPKDoc('');
    }
    if (errorUploadInvoice !== '') {
      setErrorUploadInvoice('');
    }
  };

  const downloadFile = (file) => {
    if (!disabled && !disabledGLobal) {
      const link = document.createElement('a');
      link.setAttribute('href', file);
      link.setAttribute('download', '');
      link.click();
    }
  };

  const deleteFile = (index, type) => {
    if (!disabled && !disabledGLobal) {
      const changeObjValue = async () => {
        let newDataDocument = { ...dataDocument };
        if (newDataDocument?.[type][index]?.id) {
          newDataDocument[type][index].deleted = true;
        } else {
          newDataDocument[type].splice(index, 1);
        }

        return { dt: newDataDocument, type };
      };

      let errSpkNo = '';

      changeObjValue().then(({ dt, type }) => {
        if (type === 'spk') {
          const isDeletedAll = every(dt[type], (item) => item?.deleted == true);

          if (!isDeletedAll && dt?.spk_number?.length < 1) {
            errSpkNo = 'No. SPK harus diisi';
          } else if (isDeletedAll && dt?.spk_number?.length > 0) {
            setErrorUploadSPKDoc('Dokumen SPK harus diisi');
          } else if (isDeletedAll && dt?.spk_number?.length < 1) {
            errSpkNo = '';
            setErrorUploadSPKDoc('');
          }
        }

        setDataDocument({ ...dataDocument, ...dt, error_spk_number: errSpkNo });
      });
    }
  };

  const calculateActiveItems = () => {
    const documentActiveSPK = dataDocument?.spk && dataDocument?.spk.filter((obj) => !obj.deleted);
    const documentActiveInvoice = dataDocument?.invoice && dataDocument?.invoice.filter((obj) => !obj.deleted);

    setActiveDocumentSPK(documentActiveSPK?.length ?? 0);
    setActiveDocumentInvoice(documentActiveInvoice?.length ?? 0);
  };

  const setDefaultErrorDocument = () => {
    if (editSPKDoc || editSPKNumber) {
      setErrorUploadSPKDoc('');
    } else if (editInvoice) {
      setErrorUploadInvoice('');
    }
  };

  useEffect(() => {
    calculateActiveItems();
  }, [dataDocument]);

  useEffect(() => {
    setDisabled(isProcess);
    onProcessEditDocument(isProcess);
  }, [isProcess]);

  useEffect(() => {
    if (editSPKDoc || editSPKNumber || editInvoice) {
      onEditModeDocument(true);
    } else {
      onEditModeDocument(false);
    }
    setDefaultErrorDocument();
  }, [editSPKDoc, editSPKNumber, editInvoice]);

  useEffect(() => {
    if (editDetail || editCar) {
      initDefaultDataDocuments();
    }
  }, [editDetail, editCar]);

  useEffect(() => {
    initDefaultDataDocuments();
    handleCanViewSPK();
    handleCanViewInvoice();
    handleCanUploadInvoice();
  }, [detailData]);

  return (
    <React.Fragment>
      {canViewSPK && (
        <div>
          <div>
            <dl className="row align-items-center">
              <div className="col-sm-3 text-sm-right">
                <dt>No. SPK</dt>
              </div>
              {!editSPKNumber && (
                <>
                  <div className="col-sm-6 text-sm-left">
                    <dd className="mb-1">{!dataDocument?.spk_number ? '-' : dataDocument?.spk_number}</dd>
                  </div>
                  <div className="col-sm-3 text-sm-right">
                    <img className="button-pen" src={PenIcon} onClick={handleEditSPKNumber} />
                  </div>
                </>
              )}
              {editSPKNumber && (
                <>
                  <div className="col-sm-6 text-sm-left p-0">
                    <InputField
                      inputName="spk_number"
                      placeholder={'Masukkan spk number'}
                      value={dataDocument?.spk_number}
                      disabled={disabled || disabledGLobal}
                      invalid={dataDocument?.error_spk_number ? true : false}
                      errorMessage={dataDocument?.error_spk_number ?? ''}
                      onChange={(e) => handleChangeSPKNumber(e)}
                    />
                  </div>
                  {spkEditType === 'spk number' ? (
                    <div className="col-sm-3 text-sm-right section-action">
                      <Button
                        className="button-action no-width add"
                        disabled={disabled || disabledGLobal || dataDocument?.error_spk_number || errorUploadSPKDoc}
                        onClick={handleSubmit}
                      >
                        Simpan
                      </Button>
                      <Button
                        className="button-action no-width add"
                        disabled={disabled || disabledGLobal}
                        onClick={() => {
                          if (!disabled && !disabledGLobal) {
                            initDefaultDataDocuments();
                          }
                        }}
                      >
                        Batal
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
            </dl>
          </div>
          <div>
            <dl className="row align-items-start">
              <div className="col-sm-3 text-sm-right">
                <dt>SPK Document</dt>
              </div>
              <div className="col-sm-6 text-sm-left">
                <div className="document-area">
                  {map(dataDocument?.spk, (item, index) => {
                    const spkName = item?.label ? Helper.removeExtension(item?.label) : null;

                    return (
                      <>
                        {!item?.deleted && (
                          <Row className="m-0 mb-3">
                            <div className="document-box-preview">
                              <span
                                className="file"
                                onClick={() => {
                                  downloadFile(item?.url_file);
                                }}
                              >
                                {spkName ?? 'Download'}
                              </span>
                              {editSPKDoc && (
                                <img
                                  className="icon-trash-workshop"
                                  src={TrashIcon}
                                  onClick={() => {
                                    deleteFile(index, 'spk');
                                  }}
                                />
                              )}
                            </div>
                          </Row>
                        )}
                      </>
                    );
                  })}

                  {activeDocumentSPK == 0 && editSPKDoc && (
                    <Row className="m-0 mb-3">
                      {!isProcess && (
                        <>
                          {isDragOverSPKDoc && (
                            <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                              <span className="choose-file">Release File</span>
                            </div>
                          )}
                          {!isDragOverSPKDoc && (
                            <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                              <span className="choose-file">Drag and Drop or Choose File</span>
                            </div>
                          )}
                        </>
                      )}

                      {isProcess && (
                        <>
                          <div {...getRootProps({ className: 'document-box-workshop' })}>
                            <span className="choose-file">Please Wait...</span>
                          </div>
                        </>
                      )}

                      <div className="invalid-document">{errorUploadSPKDoc}</div>
                      <div className="document-guide">Max upload 10mb</div>
                      <div className="document-guide">jpeg, jpg, png, xls, xlsx, doc, docx, ppt, pptx, pdf</div>
                    </Row>
                  )}
                </div>
                {activeDocumentSPK == 0 && !editSPKDoc && <Row className="m-0 mb-3">-</Row>}
              </div>
              {!editSPKDoc && (
                <div className="col-sm-3 text-sm-right">
                  <img className="button-pen" src={PenIcon} onClick={handleEditSPKDoc} />
                </div>
              )}
              {editSPKDoc && spkEditType === 'spk doc' ? (
                <div className="col-sm-3 text-sm-right section-action">
                  <Button
                    className="button-action no-width add"
                    disabled={disabled || disabledGLobal}
                    onClick={handleSubmit}
                  >
                    Simpan
                  </Button>
                  <Button
                    className="button-action no-width add"
                    disabled={disabled || disabledGLobal}
                    onClick={() => {
                      if (!disabled && !disabledGLobal) {
                        initDefaultDataDocuments();
                      }
                    }}
                  >
                    Batal
                  </Button>
                </div>
              ) : null}
            </dl>
          </div>
        </div>
      )}
      {canViewInvoice && (
        <div>
          <dl className="row align-items-start">
            <div className="col-sm-3 text-sm-right">
              <dt>Invoice Bengkel</dt>
            </div>
            <div className="col-sm-6 text-sm-left">
              {activeDocumentInvoice == 0 && !editInvoice && <Row className="m-0 mb-3">-</Row>}
              <div className="document-area">
                {map(dataDocument?.invoice, (item, index) => {
                  const invoiceName = item?.name
                    ? item.name.length > 50
                      ? item.name.substr(0, 27) + '...' + item.name.substr(item.name.length - 20)
                      : item.name
                    : '';

                  return (
                    <>
                      {!item?.deleted && (
                        <Row className="m-0 mb-3">
                          <div className="document-box-preview">
                            <span
                              className="file"
                              onClick={() => {
                                downloadFile(item?.url_file);
                              }}
                            >
                              {invoiceName ?? 'Download'}
                            </span>
                            {editInvoice && (
                              <img
                                className="icon-trash-workshop"
                                src={TrashIcon}
                                onClick={() => {
                                  deleteFile(index, 'invoice');
                                }}
                              />
                            )}
                          </div>
                        </Row>
                      )}
                    </>
                  );
                })}
                {activeDocumentInvoice == 0 && editInvoice && (
                  <Row className="m-0 mb-3">
                    {!isProcess && (
                      <>
                        {isDragOverInvoice && (
                          <div {...getRootProps({ className: 'document-box-workshop drag-over cursor-pointer' })}>
                            <span className="choose-file">Release File</span>
                          </div>
                        )}
                        {!isDragOverInvoice && (
                          <div {...getRootProps({ className: 'document-box-workshop cursor-pointer' })}>
                            <span className="choose-file">Drag and Drop or Choose File</span>
                          </div>
                        )}
                      </>
                    )}

                    {isProcess && (
                      <>
                        <div {...getRootProps({ className: 'document-box-workshop' })}>
                          <span className="choose-file">Please Wait...</span>
                        </div>
                      </>
                    )}

                    <div className="invalid-document">{errorUploadInvoice}</div>
                    <div className="document-guide">Max upload 10mb</div>
                    <div className="document-guide">jpeg, jpg, png, xls, xlsx, doc, docx, ppt, pptx, pdf</div>
                  </Row>
                )}
              </div>
            </div>
            {canUploadInvoice && (
              <>
                {!editInvoice && (
                  <div className="col-sm-3 text-sm-right">
                    <img
                      className="button-pen"
                      src={PenIcon}
                      onClick={() => {
                        handleEditInvoice();
                      }}
                    />
                  </div>
                )}
                {editInvoice && (
                  <div className="col-sm-3 text-sm-right section-action">
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={handleSubmit}
                    >
                      Simpan
                    </Button>
                    <Button
                      className="button-action no-width add"
                      disabled={disabled || disabledGLobal}
                      onClick={() => {
                        if (!disabled && !disabledGLobal) {
                          initDefaultDataDocuments();
                        }
                      }}
                    >
                      Batal
                    </Button>
                  </div>
                )}
              </>
            )}
          </dl>
        </div>
      )}
    </React.Fragment>
  );
}

export default BookingDetailDetailsDocument;
