import React from 'react';
import { Card } from 'reactstrap';

const BubbleChat = (props) => {
  const { type, message, timeStamp, useTimeStamp = true, isTyping = false } = props;
  return (
    <>
      <Card className={type === 'ai' ? 'from-them' : 'from-me'}>
        {!isTyping ? (
          <div className="content-title" dangerouslySetInnerHTML={{ __html: message }}></div>
        ) : (
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
        {useTimeStamp && <span className="chat-hour">{timeStamp}</span>}
      </Card>
    </>
  );
};

export default BubbleChat;
